export const getTotal = ({ qty, harga }) => {
  const qtyFloat = parseFloat(qty ?? 0);
  const hargaFloat = parseFloat(harga ?? 0);

  return qtyFloat * hargaFloat;
};

export const calcTotalSparepart = (spareparts = [], buaso = 'upah') => {
  return spareparts?.reduce(
    (total, item) =>
      total +
      getTotal({
        qty: item?.[`qty_realisasi_sparepart_${buaso}`] ?? 0,
        harga: item?.[`harga_satuan_realisasi_sparepart_${buaso}`] ?? 0,
      }),
    0,
  );
};
