// React
import { useRef, useState } from 'react'

// Component
import { ButtonGroup } from 'react-bootstrap'
import { 
  ActionButton, DeleteModal, Table, Th, ThFixed, Td 
} from 'components'

// Package
import FileSaver from 'file-saver'
import { 
  IoDocumentOutline, IoDownloadOutline, 
  IoTrashOutline 
} from 'react-icons/io5'

// API
import { RegistrasiBarangJadiApi } from 'api'

const TabFile = ({type: TYPE, dataFile, setDataFile, handleUpdateFilesOnDrop, loading, setLoading, id_item_atribut}) => {
  const inputFileRef = useRef()
  const [modalDeleteConfig, setModalDeleteConfig] = useState({
    index: '',
    show: false,
    loading: false
  })

  // Trigger input file agar terclick
  const onClickFilesHandler = () => TYPE !== 'detail' && inputFileRef.current.click() 

  // Menangani saat file dipilih
  const onChangeFileHandler = (e) => {
    setLoading(true)

    const file = e.target.files[0]
    
    // Check apakah ada file yang dipilih
    if (!file) return null 
    
    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const generalFileType = file.name.split('.').pop();

    if (['pdf', 'xlsx', 'dwg', 'docx', 'skp', 'zip', 'rar'].includes(generalFileType)) {
      const formData = new FormData()

      formData.append("file_0", file)

      handleUpdateFilesOnDrop(formData)
    } else {
      window.alert('File tidak valid! harap masukan file berformat .pdf .xlsx .dwg .docx .skp .zip .rar')
    }
  }

  // Menangani hapus file
  const onDeleteHandler = () => {
    setModalDeleteConfig({...modalDeleteConfig, loading: true})
    const data = dataFile.find((val, index) => index === modalDeleteConfig.index)
    const filter = dataFile.filter((val, index) => index !== modalDeleteConfig.index)

    RegistrasiBarangJadiApi.deleteFile({ id_item_buaso_gambar_barang_jadi: [data.id_item_buaso_gambar_barang_jadi] })
      .finally(() => {
        RegistrasiBarangJadiApi.single({ id_item_atribut })
          .then(() => setDataFile(filter))
          .finally(() => {
            setModalDeleteConfig({
              index: '',
              show: false,
              loading: false
            })
          })
      })
  }

  return (
    <div>
      {/* Button Section */}
      <div className="mb-3 text-right">
        <input 
          ref={inputFileRef} 
          type="file" 
          style={{display: 'none'}} 
          onChange={onChangeFileHandler}
        />
        {TYPE !== 'detail' &&
          <ActionButton 
            text="Tambah File" 
            loading={loading}
            onClick={onClickFilesHandler} 
          />
        }
      </div>

      {/* Table */}
      <Table>
        <thead className="bg-light">
          <tr>
            <ThFixed>No</ThFixed>
            <Th>Nama File</Th>
            <Th width={60}>Aksi</Th>
          </tr>
        </thead>
        <tbody>
          {dataFile && dataFile.length > 0
            ? dataFile.map((val, index) => (
                <tr>
                  <Td textCenter>{index + 1}</Td>
                  <Td>{val.path_gambar.split("/").pop()}</Td>
                  <Td className="text-center">
                    <ButtonGroup className="m-1">
                      {/* Button Download */}
                      <ActionButton 
                        size="sm"
                        onClick={() => FileSaver.saveAs(val.path_gambar, val.path_gambar)}
                      >
                        <IoDownloadOutline />
                      </ActionButton>

                      {/* Button Delete */}
                      {TYPE !== 'detail' &&
                        <ActionButton 
                          size="sm"
                          variant="danger"
                          onClick={() => {
                            setModalDeleteConfig({
                              show: true,
                              loading: false,
                              index: index
                            })
                          }}
                        >
                          <IoTrashOutline />
                        </ActionButton>
                      }
                    </ButtonGroup>
                  </Td>
                </tr>
              ))
            : <tr 
                style={{cursor: 'pointer'}}
                onClick={onClickFilesHandler}
              >
                <Td colSpan={3}>
                  <div className="d-flex flex-column justify-content-center align-items-center py-5">
                    <IoDocumentOutline size={50}/>
                    <b style={{fontSize: 16}} className="mt-2">Tidak ada file</b>
                  </div>
                </Td>
              </tr>
          }
        </tbody>
      </Table>
      

      {/* Modal */}
      <DeleteModal
        show={modalDeleteConfig.show} 
        title="File"
        loading={modalDeleteConfig.loading}
        onConfirm={onDeleteHandler}
        onHide={() => setModalDeleteConfig({
          show: false,
          index: ''
        })}
      />
    </div>
  )
}

export default TabFile
