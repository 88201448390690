import { useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Col, Row, Tab, Nav } from 'react-bootstrap';
import { Alert, BackButton, DataStatus, InfoItemHorizontal } from 'components';
import { DateConvert } from 'utilities';
import {
  ModalFormBahanAlatOverhead,
  TableRealisasiBiaya,
  ModalFormUpahSubkon,
} from './components';
import {
  withPostingRealisasiProvider,
  PostingRealisasiContext,
} from './context/PostingRealisasiWOContext';
import { PostingRealisasiWorkOrderApi } from 'api';

export const TambahPostingRealisasiWorkOrder = withPostingRealisasiProvider(
  ({ setNavbarTitle }) => {
    const history = useHistory();
    const { id_realisasi_work_order } = useParams();
    const { setModalFormPosting, modalFormPosting, setDataHistory } =
      useContext(PostingRealisasiContext);
    const [data, setData] = useState({});
    const [fetchData, setFetchData] = useState({
      loading: true,
      success: false,
    });
    const [alerConfig, setAlertConfig] = useState({
      variant: 'danger',
      text: '',
    });

    const getDetailData = (fetchConfig) => {
      setFetchData(fetchConfig);

      PostingRealisasiWorkOrderApi.getSingleRealisasi({
        id_realisasi_work_order,
      })
        .then((res) => {
          const { history, ...data } = res?.data?.data ?? {};

          setData(data);
          setDataHistory(history);

          setFetchData({
            loading: false,
            success: true,
          });
        })
        .catch(() => {
          setFetchData({
            loading: false,
            success: false,
          });
        });
    };

    useEffect(() => {
      setNavbarTitle('Posting Realisasi Work Order');
      getDetailData({
        loading: true,
        success: false,
      });
    }, []);

    const onSubmitSuccess = () => {
      getDetailData({
        loading: false,
        success: true,
      });
      setAlertConfig({ variant: 'primary', text: 'Berhasil Posting Data!' });
    };

    const onSubmitFailed = () => {
      setAlertConfig({ variant: 'danger', text: 'Gagal Posting Data!' });
    };

    return (
      <>
        <Row className="mb-2">
          <Col className="d-flex justify-content-end align-items-center">
            <BackButton onClick={() => history.goBack()} />
          </Col>
        </Row>

        <Alert
          showCloseButton
          show={!!alerConfig.text.length}
          variant={alerConfig.variant}
          text={alerConfig.text}
          onClose={() => setAlertConfig({ variant: 'danger', text: '' })}
        />

        {fetchData.loading || !fetchData.success ? (
          <DataStatus
            loading={fetchData.loading}
            text={fetchData.loading ? 'Memuat Data' : 'Data gagal dimuat!'}
          />
        ) : (
          <>
            <div className="font-weight-bold " style={{ fontSize: '14px' }}>
              Detail Data Work Order
            </div>

            <Card className="mb-3">
              <Card.Body>
                <Row>
                  <Col md>
                    <InfoItemHorizontal
                      label="Tgl. Work Order"
                      text={
                        data.tgl_work_order
                          ? DateConvert(new Date(data.tgl_work_order)).detail
                          : '-'
                      }
                    />
                    <InfoItemHorizontal
                      label="No. Work Order"
                      text={data?.no_work_order ?? '-'}
                    />
                    <InfoItemHorizontal
                      label="Item Aset"
                      text={data?.nama_item_aset ?? '-'}
                    />
                    <InfoItemHorizontal
                      label="Petugas Maintenance"
                      text={data?.nama_karyawan ?? '-'}
                    />
                    <InfoItemHorizontal
                      label="Status Petugas"
                      text={
                        data?.is_mekanik_eksternal
                          ? 'Mekanik Eksternal'
                          : 'Mekanik Internal'
                      }
                    />
                    <InfoItemHorizontal
                      label="Vendor"
                      text={data?.nama_vendor ?? 'AJB'}
                    />
                  </Col>

                  <Col md>
                    <InfoItemHorizontal
                      label="Tgl. Maintenance Request"
                      text={
                        data.tgl_maintenance_request_master
                          ? DateConvert(
                              new Date(data.tgl_maintenance_request_master),
                            ).detail
                          : '-'
                      }
                    />
                    <InfoItemHorizontal
                      label="No. Maintenance Request"
                      text={data?.no_maintenance_request_master ?? '-'}
                    />
                    <InfoItemHorizontal
                      label="Catatan"
                      text={data?.catatan_maintenance ?? '-'}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <div className="font-weight-bold " style={{ fontSize: '14px' }}>
              Tambah Realisasi Work Order
            </div>

            <Card className="mb-3">
              <Card.Body>
                <Row>
                  <Col md>
                    <InfoItemHorizontal
                      label="Tgl. Realisasi Work Order"
                      text={
                        data.tgl_realisasi_work_order
                          ? DateConvert(new Date(data.tgl_realisasi_work_order))
                              .detail
                          : '-'
                      }
                    />
                    <InfoItemHorizontal
                      label="No. Realisasi Work Order"
                      text={data?.no_realisasi_work_order ?? '-'}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <div className="font-weight-bold " style={{ fontSize: '14px' }}>
              Realisasi Biaya
            </div>

            <Card className="border">
              <Tab.Container defaultActiveKey="bahan">
                <Card.Header className='className="d-flex justify-content-between align-items-center'>
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="bahan">Bahan</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="upah">Upah</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="alat_mesin">Alat Mesin</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="subkon">Subkon</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="overhead">Overhead</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>

                <Tab.Content>
                  <Tab.Pane eventKey="bahan">
                    <TableRealisasiBiaya
                      buaso="bahan"
                      data={data?.bahan ?? []}
                    />
                  </Tab.Pane>

                  <Tab.Pane eventKey="upah">
                    <TableRealisasiBiaya buaso="upah" data={data?.upah ?? []} />
                  </Tab.Pane>

                  <Tab.Pane eventKey="alat_mesin">
                    <TableRealisasiBiaya
                      buaso="alat_mesin"
                      data={data?.alat_mesin ?? []}
                    />
                  </Tab.Pane>

                  <Tab.Pane eventKey="subkon">
                    <TableRealisasiBiaya
                      buaso="subkon"
                      data={data?.subkon ?? []}
                    />
                  </Tab.Pane>

                  <Tab.Pane eventKey="overhead">
                    <TableRealisasiBiaya
                      buaso="overhead"
                      data={data?.overhead ?? []}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Card>

            {['upah', 'subkon'].includes(modalFormPosting.buaso) ? (
              <ModalFormUpahSubkon
                show={modalFormPosting.show}
                buaso={modalFormPosting.buaso}
                isCreate={modalFormPosting.isCreate}
                onSubmitSuccess={onSubmitSuccess}
                onSubmitFailed={onSubmitFailed}
                onHide={() =>
                  setModalFormPosting({
                    show: false,
                    data: null,
                    isCreate: 'false',
                    buaso: 'bahan',
                  })
                }
              />
            ) : (
              <ModalFormBahanAlatOverhead
                show={modalFormPosting.show}
                buaso={modalFormPosting.buaso}
                isCreate={modalFormPosting.isCreate}
                onSubmitSuccess={onSubmitSuccess}
                onSubmitFailed={onSubmitFailed}
                onHide={() =>
                  setModalFormPosting({
                    show: false,
                    data: null,
                    isCreate: 'false',
                    buaso: 'bahan',
                  })
                }
              />
            )}
          </>
        )}
      </>
    );
  },
);
