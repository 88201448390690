// React
import React, { useState, useEffect } from 'react'

// Form
import { Formik } from 'formik'

// Components
import { FilterModal, SelectSearch } from 'components'

// API
import { AnalisaBarangJadiApi } from 'api'

const ModalFilter = ({ show, setShow, data, setData, isJobmix }) => {
	// Variables
	const option = { label: 'Semua Data', value: undefined }
	const approval = [
		option,
		{
			label: "Pending",
			value: "PEN"
		},
		{
			label: "Verified",
			value: "VER"
		},
		{
			label: "Approved",
			value: "APP"
		},
		{
			label: "Revisi",
			value: "REV"
		},
		{
			label: "Reject",
			value: "REJ"
		},
	]

	// States
	const [dropdownFilter, setDropdownFilter] = useState({
		kelompok: [option],
		jenis: [option],
		approval: [option]
	  })
	const [loading, setLoading] = useState(true)

	const mappingData = (data, label) => {
		return data.data.data.map(val => {
			return {
				value: val[`id_${label}`],
				label: val[`nama_${label}`],
				...val
			}
		})
	}

	// REQUSET DATA SERVER
	const getDataDropdown = () => {
		AnalisaBarangJadiApi.dropdown({ tipe: 'jenis', id_kelompok: "7" }).then(res => {
			setDropdownFilter({
				...dropdownFilter,
				jenis: [option, ...mappingData(res, 'jenis')],
				approval
			})
		}).catch(() => {
			setDropdownFilter({
				...dropdownFilter,
				jenis: [option],
				approval
			})
		}).finally(() => setLoading(false))
	}

	// USE EFFECT ALL DROPDOWN
    useEffect(() => {
		getDataDropdown()

		// eslint-disable-next-line
    }, [])

	// FORM VALUES
	const formInitialValues = {
		kelompok_barang: data?.filter?.kelompok_barang,
		jenis_barang: data?.filter?.jenis_barang,
		status_approval: data?.filter?.status_approval
	}
	const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ 
              filter: {
                  ...values,
                  active: true
              },
              pagination: {
                  ...data.pagination,
                  page: 1
              }
           })
        } else { 
            setData({ 
                ...data,
                filter: {
                    ...values,
                    active: false
                }
             })
        }
    
        setShow(false)
    }

	// HANDLE CHANGE
	const onResetButtonClick = (values, setValues) => {
        setValues({
            jenis_barang: undefined,
            status_approval: undefined,
        })
    }

	return (
		<Formik
			enableReinitialize
			initialValues={formInitialValues}
			onSubmit={formSubmitHandler}
		>	
			{({values, handleSubmit, setFieldValue, setValues}) => (
				<FilterModal
					show={show}
					setShow={setShow}
					onResetButtonClick={() => onResetButtonClick(values, setValues)}
					onFilterButtonClick={handleSubmit}>

					<SelectSearch 
						key={values.jenis_barang}
						label="Jenis Barang"
						placeholder="Pilih Jenis Barang"
						defaultValue={dropdownFilter.jenis.find(val => val.value === values.jenis_barang)}
						option={dropdownFilter.jenis}
						onChange={val => setFieldValue('jenis_barang', val.value)}
						loading={loading}
					/>

					{!isJobmix && (
						<SelectSearch 
							key={values.status_approval}
							label="Status Approval"
							placeholder="Pilih Status Approval"
							defaultValue={dropdownFilter.approval.find(val => val.value === values.status_approval)}
							option={dropdownFilter.approval}
							onChange={val => setFieldValue('status_approval', val.value)}
							loading={loading}
						/>
					)}
				</FilterModal>
			)}
		</Formik>
	)
}
export default ModalFilter