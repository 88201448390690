import Services from "services";

class HargaSatuanRataRataOverheadApi {
  page(params) {
    return Services.get("/harga_satuan_overhead/page", { params });
  }

  getHSRData(params) {
    return Services.get("harga_satuan_overhead/status_posting", { params });
  }

  save(data) {
    return Services.post("/harga_satuan_overhead", data);
  }

  export(params) {
    return Services.get("/harga_satuan_overhead/export", { params });
  }
}

export default new HargaSatuanRataRataOverheadApi();
