import React from "react";
import {
  ActionButton,
  DataStatus,
  DatePicker,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from "components";
import { Card } from "react-bootstrap";
import { RupiahConvert } from "utilities";
import { useHistory } from "react-router-dom";

export const TableComp = ({ data, title, tgl }) => {
  const history = useHistory();

  return (
    <div className="my-3">
      <b className="text-capitalize">Indikatif {title.toLowerCase()}</b>
      <Card>
        <Card.Body>
          <Table>
            <THead>
              <Tr>
                <Th width="100">No. Akun</Th>
                <Th>Nama Akun</Th>
                <Th>Jumlah</Th>
              </Tr>
            </THead>
            <TBody>
              {data.length > 0 ? (
                data.map((val) => (
                  <Tr>
                    {!val?.is_total && (
                      <Td
                        className={`text-center text-nowrap ${
                          val.is_header && "font-weight-bold"
                        }`}
                      >
                        {val.nomor_akun}
                      </Td>
                    )}
                    <Td
                      colSpan={val.is_header || val.is_total ? 2 : ""}
                      className={`${
                        val.is_header || val.is_total ? "font-weight-bold" : ""
                      } ${
                        Boolean(
                          !val.is_header &&
                            val.id_jenis &&
                            val.id_unit_produksi >= 0
                        ) && "text-primary"
                      }`}
                    >
                      <span
                        onClick={(e) => {
                          if (
                            Boolean(
                              !val.is_header &&
                                val.id_jenis &&
                                val.id_unit_produksi >= 0
                            )
                          ) {
                            history.push(
                              `/laporan/proyeksi-laba-rugi/realisasi-produksi/${val.id_jenis}/${val.id_unit_produksi}`,
                              { tgl: tgl }
                            );
                          }
                        }}
                        style={{
                          cursor:
                            Boolean(
                              !val.is_header &&
                                val.id_jenis &&
                                val.id_unit_produksi
                            ) && "pointer",
                        }}
                      >
                        {val.nama_akun}
                      </span>
                    </Td>
                    {val.is_header === "1" ? (
                      ""
                    ) : (
                      <Td
                        textRight
                        className={`${
                          val.is_header || val.is_total
                            ? "font-weight-bold"
                            : ""
                        }`}
                      >
                        {val.is_header === "1"
                          ? ""
                          : val.grup === "Pendapatan"
                          ? RupiahConvert(Math.abs(val.nilai ?? 0).toString())
                              .getWithComa
                          : RupiahConvert(parseFloat(val.nilai ?? 0).toString())
                              .getWithComa}
                      </Td>
                    )}
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan="3">
                    <DataStatus text="Data Tidak Ada" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};
