import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  Checkbox,
  DatePicker,
  InfoItemHorizontal,
  Input,
  RadioButton,
  RadioButtonWrapper,
  SelectSearch,
  TextArea,
} from "components";
import { useFormikContext } from "formik";
import Axios from "axios";
import { PostingRealisasiPPAApi } from "api";
import { DateConvert } from "utilities";

const InfoSection = ({ data }) => {
  return (
    <>
      <Row>
        <Col lg="6">
          <InfoItemHorizontal
            label="Tgl. Realisasi PPA"
            text={
              data?.tglRealisasiPPA
                ? DateConvert(new Date(data?.tglRealisasiPPA)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Realisasi PPA"
            text={data?.noRealisasiPPA ?? "-"}
          />
        </Col>
      </Row>
    </>
  );
};

export const FormSection = ({ type }) => {
  const { values, setValues, errors, touched } = useFormikContext();
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const [dropdownPenanggungJawab, setDropdownPenanggungJawab] = useState([]);

  const getNomorHandler = (tgl) => {
    setIsNomorLoading(true);
    const tanggal = DateConvert(new Date(tgl)).default;

    PostingRealisasiPPAApi.getKode({ tanggal })
      .then(({ data }) => {
        const no = data?.data;
        setValues({
          ...values,
          realisasiPPA: {
            ...values.realisasiPPA,
            noRealisasiPPA: no,
            tglRealisasiPPA: tanggal,
          },
        });
      })
      .catch(() => {
        setValues({
          ...values,
          realisasiPPA: {
            ...values.realisasiPPA,
            noRealisasiPPA: "",
            tglRealisasiPPA: null,
          },
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  return type === "read" ? (
    <InfoSection data={values?.realisasiPPA} />
  ) : (
    <Row>
      <Col lg="6">
        <DatePicker
          label="Tgl. Realisasi PPA"
          onChange={(date) => {
            getNomorHandler(date);
          }}
          selected={
            values?.realisasiPPA?.tglRealisasiPPA
              ? new Date(values?.realisasiPPA?.tglRealisasiPPA)
              : null
          }
          error={
            Boolean(errors?.realisasiPPA?.tglRealisasiPPA) &&
            touched?.realisasiPPA?.tglRealisasiPPA
          }
          errorText={
            Boolean(errors?.realisasiPPA?.tglRealisasiPPA) &&
            touched?.realisasiPPA?.tglRealisasiPPA &&
            errors?.realisasiPPA?.tglRealisasiPPA
          }
        />
      </Col>
      <Col lg="6">
        <Input
          label="No. Realisasi PPA"
          disabled
          value={
            isNomorLoading
              ? "Memuat Data.."
              : values?.realisasiPPA?.noRealisasiPPA
          }
          placeholder="Pilih tanggal terlebih dahulu"
        />
      </Col>
    </Row>
  );
};
