import Services from "services";

class HPPApi {
  page(params) {
    return Services.get("/hpp/page", { params });
  }

  page_realisasi(params) {
    return Services.get("/hpp/page_realisasi", { params });
  }

  // get single HPP
  single(params) {
    return Services.get("/hpp/single", { params });
  }

  // get single produksi (preform) untuk post HPP baru
  single_produksi(params) {
    return Services.get("/hpp/single_realisasi", { params });
  }

  //  get single produksi (preform) untuk lihat detail produksi
  detail_produksi(params) {
    return Services.get("/hpp/detail_realisasi", { params });
  }

  dropdown(params) {
    return Services.get("/hpp/dropdown", { params });
  }

  no_baru(params) {
    return Services.get("/hpp/no_baru", { params });
  }

  create(value) {
    return Services.post("/hpp", value);
  }

  export(params) {
    return Services.get("/hpp_report/", { params });
  }
}

export default new HPPApi();
