import React, { useState, useEffect } from "react";
import { Card, Nav, Tab } from "react-bootstrap";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import { Alert, CRUDLayout } from "components";
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonUpdate,
  ButtonFilter,
  ButtonDetail,
  Button,
} from "components2";
import { ModalFilter, TableList } from "./__PermintaanProduksiAsetComps__";
import PermintaanProduksiAsetApi from "./__WIProduksiAsetApi__";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Formik } from "formik";

export const WIPProduksiAsetList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const checkAlert = () => {
    if (state) {
      if (state.alert) {
        setAlertConfig({
          show: state?.alert?.show,
          text: state?.alert?.text,
          variant: state?.alert?.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle("WIP Produksi Aset");
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection></CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ButtonCreate
            size="md"
            icon
            onClick={() =>
              history.push("wip-produksi-aset/realisasi-produksi-aset")
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        showCloseButton
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      <Card className="p-0 mt-3">
        <Tab.Container defaultActiveKey="bahan">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="bahan">Bahan</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="upah">Upah</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="alatMesin">Alat Mesin</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="subkon">Subkon</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="overhead">Overhead</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>
          <Tab.Content>
            <Tab.Pane eventKey="bahan" className="p-2">
              <TableList type="bahan" />
            </Tab.Pane>
            <Tab.Pane eventKey="upah" className="p-2">
              <TableList type="upah" />
            </Tab.Pane>
            <Tab.Pane eventKey="alatMesin" className="p-2">
              <TableList type="alatMesin" />
            </Tab.Pane>
            <Tab.Pane eventKey="subkon" className="p-2">
              <TableList type="subkon" />
            </Tab.Pane>
            <Tab.Pane eventKey="overhead" className="p-2">
              <TableList type="overhead" />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Card>
    </CRUDLayout>
  );
};
