import Services from "services"

class MappingCOAApi {
  data() {
    return Services.get("/mapping_coa_kelompok_item")
  }

  page(value) {
    return Services.get("/mapping_coa_kelompok_item/page", { params: {...value} })
  }

  dropdown(value) {
    return Services.get("/mapping_coa_kelompok_item/dropdown", { params: {...value} })
  }

  create(value) {
    return Services.post("/mapping_coa_kelompok_item", value)
  }

  // single(value) {
  //   return Services.get("/hpp/single", { params: {...value} })
  // }

  // no_baru(value) {
  //   return Services.get("/hpp/no_baru", { params: {...value} })
  // }

  // delete(value) {
  //   return Services.post("/hpp/delete", value)
  // }
}

export default new MappingCOAApi()
