import Services from "services";

class NilaiPerolehanProduksiAsetApi {
  async getList(params) {
    const fetch = await Services.get("/laporan_rekapitulasi_pendapatan/page", {
      params,
    });
    return fetch.data;
  }

  async getDrodpownUnitProduksi(params) {
    const fetch = await Services.get("/dropdown/unit_produksi", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val?.nama_unit_produksi,
      value: val?.id_unit_produksi,
    }));
  }

  async getDropdownJenis(params) {
    const fetch = await Services.get("/dropdown/jenis_barang_jadi", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val?.nama_jenis,
      value: val?.id_jenis,
    }));
  }

  export(params) {
    return Services.get("/laporan_rekapitulasi_pendapatan/export", { params });
  }
}

export default new NilaiPerolehanProduksiAsetApi();
