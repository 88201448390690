// Components
import { DateConvert, DecimalConvert } from "utilities";

// Form
import { useFormikContext } from "formik";

// View Components
import List from "../List";

const InfoSection = ({ data }) => {
  // Variables
  const { values } = useFormikContext();

  return (
    <table>
      <tbody>
        <List label="Tgl. Permintaan Produksi">
          {data.tgl_permintaan_produksi_overhead
            ? DateConvert(new Date(data.tgl_permintaan_produksi_overhead))
                .detail
            : "-"}
        </List>
        <List label="No. Permintaan Produksi">
          {data.no_permintaan_produksi_overhead ?? "-"}
        </List>
        <List label="Proyek">{data.nama_proyek ?? "-"}</List>
        <List label="Item Overhead">{data.nama_item ?? "-"}</List>
        <List label="Satuan">{data.kode_satuan ?? "-"}</List>
        <List label="Qty. Permintaan Produksi">
          {DecimalConvert(data.qty_permintaan_produksi ?? 0).getWithComa}
        </List>
        <List label="Total Qty. Telah Ditransfer">
          {DecimalConvert(data.qty_telah_ditransfer ?? 0).getWithComa}
        </List>
      </tbody>
    </table>
  );
};

export default InfoSection;
