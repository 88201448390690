export const formSubmitValueMapper = (data = {}, tab, checkTab) => ({
  [`tgl_wip_${checkTab}`]: data?.tgl_wip,
  detail: data?.realisasi?.[tab]?.map((val) => ({
    [`id_realisasi_produksi_aset_${checkTab}`]: val?.id_realisasi,
    [`harga_satuan_${checkTab}`]: val?.harga_satuan,
    keterangan_jurnal: data?.keterangan_jurnal,
    nomor_akun_debet: val?.nomor_akun_debet,
    nomor_akun_kredit: val?.nomor_akun_kredit,
  })),
});
