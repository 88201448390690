import { useFormik } from "formik";
import { DatePicker, SelectSearch, FilterModal } from "components";
import { DateConvert } from "utilities";

const ModalFilter = ({
  dropdown,
  modalConfig,
  setModalConfig,
  data,
  setData,
}) => {
  const formInitialValues = {
    tgl_sales_order_mulai: data?.tgl_sales_order_mulai,
    tgl_sales_order_selesai: data?.tgl_sales_order_selesai,
    tgl_delivery_order_mulai: data?.tgl_delivery_order_mulai,
    tgl_delivery_order_selesai: data?.tgl_delivery_order_selesai,
    tgl_surat_jalan_mulai: data?.tgl_surat_jalan_mulai,
    tgl_surat_jalan_selesai: data?.tgl_surat_jalan_selesai,
    tgl_jobmix_mulai: data?.tgl_jobmix_mulai,
    tgl_jobmix_selesai: data?.tgl_jobmix_selesai,
    id_customer: data?.id_customer,
    id_proyek: data?.id_proyek,
    id_barang: data?.id_barang,
  };

  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    console.log(checkActive);

    if (checkActive) {
      setData((prev) => ({
        ...prev,
        ...values,
        active: true,
      }));
    } else {
      setData((prev) => ({ ...prev, active: false }));
    }
    setModalConfig(false);
  };

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: formSubmitHandler,
  });

  const { values, setValues, setFieldValue, handleSubmit } = formik;

  const onTanggalChange = (dates) => {
    setValues({
      ...values,
      ...dates,
    });
  };

  const onResetButtonClick = () => {
    setData((prev) => ({
      ...prev,
      tgl_sales_order_mulai: undefined,
      tgl_sales_order_selesai: undefined,
      tgl_delivery_order_mulai: undefined,
      tgl_delivery_order_selesai: undefined,
      tgl_surat_jalan_mulai: undefined,
      tgl_surat_jalan_selesai: undefined,
      tgl_jobmix_mulai: undefined,
      tgl_jobmix_selesai: undefined,
      id_customer: undefined,
      id_proyek: undefined,
      id_barang: undefined,
      active: false
    }));

    setModalConfig(false);
  };

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}>
      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Sales Order"
        placeholderText="Pilih tanggal sales order"
        dateFormat="dd/MM/yyyy"
        startDate={
          values?.tgl_sales_order_mulai
            ? new Date(values.tgl_sales_order_mulai)
            : null
        }
        endDate={
          values?.tgl_sales_order_selesai
            ? new Date(values.tgl_sales_order_selesai)
            : null
        }
        onChange={(selectedDate) => {
          const [start, end] = selectedDate;
          const startDate = start ? DateConvert(start).default : null;
          const endDate = end ? DateConvert(end).default : null;

          const finalValues = {
            tgl_sales_order_mulai: startDate,
            tgl_sales_order_selesai: endDate,
          };

          onTanggalChange(finalValues);
        }}
      />

      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Delivery Order"
        placeholderText="Pilih tanggal delivery order"
        dateFormat="dd/MM/yyyy"
        startDate={
          values?.tgl_delivery_order_mulai
            ? new Date(values.tgl_delivery_order_mulai)
            : null
        }
        endDate={
          values?.tgl_delivery_order_selesai
            ? new Date(values.tgl_delivery_order_selesai)
            : null
        }
        onChange={(selectedDate) => {
          const [start, end] = selectedDate;
          const startDate = start ? DateConvert(start).default : null;
          const endDate = end ? DateConvert(end).default : null;
          const finalValues = {
            tgl_delivery_order_mulai: startDate,
            tgl_delivery_order_selesai: endDate,
          };
          onTanggalChange(finalValues);
        }}
      />

      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Surat Jalan"
        placeholderText="Pilih tanggal surat jalan"
        dateFormat="dd/MM/yyyy"
        startDate={
          values?.tgl_surat_jalan_mulai
            ? new Date(values.tgl_surat_jalan_mulai)
            : null
        }
        endDate={
          values?.tgl_surat_jalan_selesai
            ? new Date(values.tgl_surat_jalan_selesai)
            : null
        }
        onChange={(selectedDate) => {
          const [start, end] = selectedDate;
          const startDate = start ? DateConvert(start).default : null;
          const endDate = end ? DateConvert(end).default : null;
          const finalValues = {
            tgl_surat_jalan_mulai: startDate,
            tgl_surat_jalan_selesai: endDate,
          };
          onTanggalChange(finalValues);
        }}
      />

      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Produksi"
        placeholderText="Pilih tanggal produksi"
        dateFormat="dd/MM/yyyy"
        startDate={
          values?.tgl_jobmix_mulai ? new Date(values.tgl_jobmix_mulai) : null
        }
        endDate={
          values?.tgl_jobmix_selesai
            ? new Date(values.tgl_jobmix_selesai)
            : null
        }
        onChange={(selectedDate) => {
          const [start, end] = selectedDate;
          const startDate = start ? DateConvert(start).default : null;
          const endDate = end ? DateConvert(end).default : null;
          const finalValues = {
            tgl_jobmix_mulai: startDate,
            tgl_jobmix_selesai: endDate,
          };
          onTanggalChange(finalValues);
        }}
      />

      <SelectSearch
        label="Customer"
        placeholder="Pilih customer"
        defaultValue={dropdown?.customer?.find(
          (val) => val.value === values.id_customer
        )}
        option={dropdown.customer ?? []}
        onChange={(val) => setFieldValue("id_customer", val.value)}
      />

      <SelectSearch
        label="Proyek"
        placeholder="Pilih proyek"
        defaultValue={dropdown?.proyek?.find(
          (val) => val.value === values.id_proyek
        )}
        option={dropdown.proyek ?? []}
        onChange={(val) => setFieldValue("id_proyek", val.value)}
      />

      <SelectSearch
        label="Barang Jadi"
        placeholder="Pilih barang jadi"
        defaultValue={dropdown?.barang?.find(
          (val) => val.value === values.id_barang
        )}
        option={dropdown.barang ?? []}
        onChange={(val) => setFieldValue("id_barang", val.value)}
      />
    </FilterModal>
  );
};

export default ModalFilter;
