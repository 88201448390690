// React
import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

// Component
import { Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  BackButton,
  Th,
  Td,
  ReadButton,
  ThFixed,
  TdFixed,
} from "components";
import { DateConvert, RupiahConvert, TableNumber } from "utilities";

// API
import { LaporanProyeksiLabaRugi } from "api";

export const RealisasiProduksiList = ({ setNavbarTitle }) => {
  const title = "Laba Rugi Indikatif";
  const history = useHistory();
  const location = useLocation();

  const { id_jenis, id_unit_produksi } = useParams();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);

  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    LaporanProyeksiLabaRugi.getRealisasiProduksi({
      ...location?.state?.tgl,
      id_jenis: id_jenis,
      id_unit_produksi: id_unit_produksi,
    })
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
      })
      .catch(() => {})
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PageContent = () => {
    const totalProyeksi = data?.reduce(
      (acc, curr) => acc + parseFloat(curr?.jumlah),
      0
    );

    const DataTable = () => (
      <Table>
        <thead>
          <tr>
            <Th style={{ width: 50 }}>No</Th>
            <Th>Informasi Realisasi Produksi</Th>
            <Th>Informasi Produksi</Th>
            <Th>Item Produksi</Th>
            <Th>Proyeksi HPP</Th>
            <ThFixed style={{ width: 50 }}>Aksi</ThFixed>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 &&
            data.map((val, index) => (
              <tr key={index}>
                <Td>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </Td>
                <TdFixed>
                  <div>
                    {val?.tgl_realisasi_produksi
                      ? DateConvert(new Date(val?.tgl_realisasi_produksi))
                          .defaultDMY
                      : "-"}
                  </div>
                  <div>{val?.no_realisasi_produksi ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {val?.tgl_jobmix
                      ? DateConvert(new Date(val?.tgl_jobmix)).defaultDMY
                      : "-"}
                  </div>
                  <div>{val?.no_jobmix ?? "-"}</div>
                </TdFixed>
                <Td>{val?.nama_item ?? "-"}</Td>
                <Td className="text-right">
                  {
                    RupiahConvert(parseFloat(val?.jumlah ?? 0).toString())
                      .getWithComa
                  }
                </Td>
                <Td className="text-center text-nowrap">
                  <div className="d-flex justify-content-center">
                    <ReadButton
                      size="sm"
                      className="mr-1"
                      onClick={() =>
                        history.push(
                          `/laporan/proyeksi-laba-rugi/detail/${val?.id_realisasi_produksi}`,
                          { ...location?.state }
                        )
                      }
                    />
                  </div>
                </Td>
              </tr>
            ))}
          <tr>
            <Td colSpan="4" textRight>
              <b>TOTAL PROYEKSI HPP</b>
            </Td>
            <Td textRight>
              <b>
                {
                  RupiahConvert(parseFloat(totalProyeksi ?? 0).toString())
                    .getWithComa
                }
              </b>
            </Td>
          </tr>
        </tbody>
      </Table>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection></CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} className="mb-2" />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <>
          <b>List Data Realisasi Produksi</b>
          <PageContent />
        </>
      )}
    </CRUDLayout>
  );
};
