import {
  Table,
  THead,
  TBody,
  Tr,
  Td,
  TdFixed,
  ThFixed,
  Th,
  DataStatus,
  SelectSearch,
} from "components";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";
import { ButtonDetail } from "@bhawanadevteam/react-core";
import { ModalDetail } from "./ModalDetail";

export const TableHistoryWIP = ({ section, title, action, tab }) => {
  const [modalDetailConfig, setModalDetailConfig] = useState({
    show: false,
    data: {},
  });
  const { values } = useFormikContext();

  return (
    <div>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed className="text-capitalize">Informasi WIP {title}</ThFixed>
            <Th className="text-capitalize">Item {title}</Th>
            <Th>Jumlah</Th>
            <Th>Akun COA</Th>
            <Th>Keterangan Jurnal</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {values?.[section]?.[tab]?.length > 0 ? (
            values?.[section]?.[tab]?.map((val, ind) => (
              <Tr key={ind}>
                <TdFixed>{ind + 1}</TdFixed>
                <TdFixed>
                  <div>
                    {val.tgl_wip
                      ? DateConvert(new Date(val.tgl_wip)).defaultDMY
                      : "-"}
                  </div>
                  <div>{val?.no_wip ?? "-"}</div>
                </TdFixed>
                <Td>{val?.nama_item ?? "-"}</Td>
                <Td>
                  {
                    RupiahConvert(
                      String(
                        parseFloat(val?.qty_realisasi ?? 0) *
                          parseFloat(val?.harga_satuan ?? 0)
                      )
                    ).getWithComa
                  }
                </Td>
                <Td>
                  <div>
                    D : {val?.nomor_akun_debet} - {val?.nama_akun_debet}
                  </div>
                  <div>
                    K : {val?.nomor_akun_kredit} - {val?.nama_akun_kredit}
                  </div>
                </Td>
                <Td>{val?.keterangan_jurnal ?? "-"}</Td>
                <TdFixed>
                  <ButtonDetail
                    icon
                    variant="primary"
                    onClick={() =>
                      setModalDetailConfig({ show: true, data: val })
                    }
                  />
                </TdFixed>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="8">
                <DataStatus text="Tidak Ada Data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
      {modalDetailConfig.show && (
        <ModalDetail
          setModalConfig={setModalDetailConfig}
          modalConfig={modalDetailConfig}
          tab={tab}
        />
      )}
    </div>
  );
};
