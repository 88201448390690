// React
import React from "react";

// Form
import { useFormikContext } from "formik";

// Components
import { DatePicker, FilterModal, SelectSearch } from "components";

import { DateConvert } from "utilities";
import { useQuery } from "react-query";
import WIPProduksiAsetApi from "../__WIProduksiAsetApi__";

export const ModalFilter = ({
  toggle,
  isPreForm,
  checkTab,
  type,
  checkTabTitle,
}) => {
  const { values, handleSubmit, setValues } = useFormikContext();

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        [`tgl_wip_${checkTab}_mulai`]: undefined,
        [`tgl_wip_${checkTab}_selesai`]: undefined,
        tgl_realisasi_produksi_aset_mulai: undefined,
        tgl_realisasi_produksi_aset_selesai: undefined,
        tgl_produksi_aset_mulai: undefined,
        tgl_produksi_aset_selesai: undefined,
        [`id_item_${checkTab}`]: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  const { data: dropdownItem, isFetching: loadingDropdownItem } = useQuery(
    ["dropdownItem", "dropdown"],
    () =>
      WIPProduksiAsetApi.getDropdownItem({
        id_buaso:
          type === "bahan"
            ? 1
            : type === "upah"
            ? 2
            : type === "alatMesin"
            ? 3
            : type === "subkon"
            ? 4
            : 5,
      })
  );

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      {!isPreForm && (
        <DatePicker
          selectsRange
          label="Tgl. WIP Produksi Aset"
          placeholderText="Pilih tgl. WIP produksi aset"
          startDate={
            values?.[`tgl_wip_${checkTab}_mulai`]
              ? new Date(values?.[`tgl_wip_${checkTab}_mulai`])
              : ""
          }
          endDate={
            values?.[`tgl_wip_${checkTab}_selesai`]
              ? new Date(values?.[`tgl_wip_${checkTab}_selesai`])
              : ""
          }
          onChange={(dates) =>
            onTanggalChange(
              dates,
              values,
              setValues,
              `tgl_wip_${checkTab}_mulai`,
              `tgl_wip_${checkTab}_selesai`
            )
          }
          monthsShown={2}
        />
      )}

      <DatePicker
        selectsRange
        label="Tgl. Realisasi Produksi Aset"
        placeholderText="Pilih tgl. realisasi produksi aset"
        startDate={
          values?.tgl_realisasi_produksi_aset_mulai
            ? new Date(values?.tgl_realisasi_produksi_aset_mulai)
            : ""
        }
        endDate={
          values?.tgl_realisasi_produksi_aset_selesai
            ? new Date(values?.tgl_realisasi_produksi_aset_selesai)
            : ""
        }
        onChange={(dates) =>
          onTanggalChange(
            dates,
            values,
            setValues,
            "tgl_realisasi_produksi_aset_mulai",
            "tgl_realisasi_produksi_aset_selesai"
          )
        }
        monthsShown={2}
      />

      {isPreForm && (
        <DatePicker
          selectsRange
          label="Tgl. Produksi Aset"
          placeholderText="Pilih tgl. produksi aset"
          startDate={
            values?.tgl_produksi_aset_mulai
              ? new Date(values?.tgl_produksi_aset_mulai)
              : ""
          }
          endDate={
            values?.tgl_produksi_aset_selesai
              ? new Date(values?.tgl_produksi_aset_selesai)
              : ""
          }
          onChange={(dates) =>
            onTanggalChange(
              dates,
              values,
              setValues,
              "tgl_produksi_aset_mulai",
              "tgl_produksi_aset_selesai"
            )
          }
          monthsShown={2}
        />
      )}
      {!isPreForm && (
        <SelectSearch
          label={`Item ${checkTabTitle}`}
          option={dropdownItem}
          loading={loadingDropdownItem}
          placeholder="Pilih pilih item"
          defaultValue={
            values?.[`id_item_${checkTab}`]
              ? dropdownItem?.find(
                  (val) => val?.value === values?.[`id_item_${checkTab}`]
                )
              : undefined
          }
          onChange={(val) => {
            setValues({ ...values, [`id_item_${checkTab}`]: val.value });
          }}
        />
      )}
    </FilterModal>
  );
};
