import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { FormProduksiAsetContent } from "./__PermintaanProduksiAsetComps__";
import { useMutation, useQuery } from "react-query";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from "./__PermintaanProduksiAsetUtils__";
import { Alert, DataStatus } from "components";
import WIPProduksiAsetApi from "./__WIProduksiAsetApi__";

export const WIPProduksiAsetCreate = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();

  const [currentTab, setCurrentTab] = useState("bahan");
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const checkCurrentTab =
    currentTab === "alatMesin" ? "alat_mesin" : currentTab;

  const {
    data: detailRealisasiProduksiAset,
    isFetching: loadingDetailRealisasiProduksiAset,
    refetch,
  } = useQuery(
    ["permintaanAset", "single", id],
    () =>
      WIPProduksiAsetApi.getSingleRealisasiProduksiAsetAvailable({
        id_realisasi_produksi_aset: id,
      }),
    { enabled: !!id }
  );

  const produksiAset = useMutation((data) =>
    WIPProduksiAsetApi.create(data, checkCurrentTab)
  );
  const formSubmitHandler = (value) => {
    produksiAset
      .mutateAsync(formSubmitValueMapper(value, currentTab, checkCurrentTab))
      .then(() =>
        setAlertConfig({
          variant: "primary",
          show: true,
          text: "Data Berhasil disimpan!!!",
        })
      )
      .catch((err) =>
        setAlertConfig({
          variant: "danger",
          show: true,
          text: "Data gagal disimpan!!!",
        })
      )
      .finally(() => {
        refetch();
      });
  };

  useEffect(() => {
    setNavbarTitle("WIP Produksi Aset");
    return () => {};
  }, []);

  return (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      {loadingDetailRealisasiProduksiAset ? (
        <DataStatus text="Memuat Data..." loading />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(
            detailRealisasiProduksiAset?.data ?? {}
          )}
          onSubmit={formSubmitHandler}
          validationSchema={formValidationSchema}
        >
          <FormProduksiAsetContent
            action="create"
            setCurrentTab={setCurrentTab}
          />
        </Formik>
      )}
    </>
  );
};
