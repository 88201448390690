import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import {
  FormSection,
  InfoKegiatanSection,
  InfoPPASection,
  InfoProgramSection,
  InfoSubKegiatanSection,
} from "./Section";
import {
  formInitialValues,
  formSubmmitMapper,
  formValidationSchema,
} from "./Utils";
import { Formik } from "formik";
import { PostingRealisasiPPAApi } from "api";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom";
import { ActionButton, Alert, BackButton, DataStatus } from "components";
import { TabItemSumberDaya } from "./Comps";

export const TambahPostingRealisasiPPA = () => {
  const { id } = useParams();
  const history = useHistory();
  const [dataProgram, setDataProgram] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownCoa, setDropdownCoa] = useState([]);

  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    show: false,
  });

  const getDataSingle = () => {
    setIsLoading(true);
    PostingRealisasiPPAApi.single({ id_realisasi_ppa: id })
      .then(({ data }) => {
        setDataProgram(data?.data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          text: "Data gagal dimuat !!",
          variant: "danger",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getDropdown = () => {
    PostingRealisasiPPAApi.dropdownCOA()
      .then(({ data }) => {
        const mappingCOA = data?.data.map((val) => ({
          value: val.id_coa,
          label: `${val.nomor_akun} - ${val.nama_akun}`,
        }));
        setDropdownCoa(mappingCOA ?? []);
      })
      .catch(() => {
        window.alert("COA gagal dimuat");
      })
      .finally(() => {});
  };

  useEffect(() => {
    getDataSingle();
    getDropdown();

    return () => {
      setIsLoading(false);
    };
  }, [alertConfig.show]);

  return (
    <>
      <Formik enableReinitialize initialValues={formInitialValues(dataProgram)}>
        {({ isSubmitting, handleSubmit }) => (
          <>
            {isLoading ? (
              <DataStatus text="Memuat Data..." loading />
            ) : (
              <>
                <Alert
                  show={alertConfig.show}
                  showCloseButton
                  text={alertConfig.text}
                  variant={alertConfig.variant}
                />
                <div className="d-flex justify-content-between mb-2">
                  <span style={{ fontSize: "14px" }}>
                    <b>Detail Data Program</b>
                  </span>
                  <BackButton size="sm" onClick={() => history.goBack()} />
                </div>
                <Card className="mb-3">
                  <Card.Body>
                    <InfoProgramSection />
                  </Card.Body>
                </Card>
                <span style={{ fontSize: "14px" }}>
                  <b>Detail Data Kegiatan</b>
                </span>
                <Card className="mb-3">
                  <Card.Body>
                    <InfoKegiatanSection />
                  </Card.Body>
                </Card>
                <span style={{ fontSize: "14px" }}>
                  <b>Detail Data Sub Kegiatan</b>
                </span>
                <Card className="mb-3">
                  <Card.Body>
                    <InfoSubKegiatanSection />
                  </Card.Body>
                </Card>
                <span style={{ fontSize: "14px" }}>
                  <b>Detail Data PPA</b>
                </span>
                <Card className="mb-3">
                  <Card.Body>
                    <InfoPPASection />
                  </Card.Body>
                </Card>
                <span style={{ fontSize: "14px" }}>
                  <b>Tambah Data Realisasi PPA</b>
                </span>
                <form onSubmit={handleSubmit}>
                  <Card className="mb-3">
                    <Card.Body>
                      <FormSection type="read" />
                    </Card.Body>
                  </Card>
                  <span style={{ fontSize: "14px" }}>
                    <b>List Item Sumber Daya</b>
                  </span>
                  <Card>
                    <TabItemSumberDaya
                      type="read"
                      coa={dropdownCoa}
                      setAlertConfig={setAlertConfig}
                      alertConfig={alertConfig}
                    />
                  </Card>
                  <div className="d-flex justify-content-end  my-3">
                    {/* <ActionButton
                      size="sm"
                      text="Simpan"
                      type="submit"
                      loading={isSubmitting}
                    /> */}
                  </div>
                </form>
              </>
            )}
          </>
        )}
      </Formik>
    </>
  );
};
