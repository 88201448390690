import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { InfoItemHorizontal, InfoItemVertical } from "components";
import { DateConvert, RupiahConvert } from "utilities";

const InfoSection = ({ data, type }) => {
  return (
    <Card className="mb-4">
      <Card.Body>
        <Row>
          <Col lg>
            <table>
              <tbody>
                <InfoItemHorizontal
                  label="Tgl. Produksi"
                  text={
                    data?.tgl_jobmix
                      ? DateConvert(new Date(data?.tgl_jobmix)).detail
                      : "-"
                  }
                />
                <InfoItemHorizontal
                  label="No. Produksi"
                  text={data?.no_jobmix ?? "-"}
                />
                <InfoItemHorizontal
                  label="Tgl. Realisasi Produksi"
                  text={
                    data?.tgl_realisasi_produksi
                      ? DateConvert(new Date(data?.tgl_realisasi_produksi))
                          .detail
                      : "-"
                  }
                />
                <InfoItemHorizontal
                  label="No. Realisasi Produksi"
                  text={data?.no_realisasi_produksi ?? "-"}
                />
                <InfoItemHorizontal
                  label="Item Produksi"
                  text={data?.item_produksi ?? "-"}
                />
                <InfoItemHorizontal
                  label="Qty. Produksi"
                  text={
                    data.qty_jobmix
                      ? `${parseFloat(data.qty_jobmix ?? 0)} ${
                          data.kode_satuan ?? ""
                        }`
                      : "-"
                  }
                />
                <InfoItemHorizontal
                  label="Qty. Realisasi Produksi"
                  text={
                    data.qty_realisasi_produksi
                      ? `${parseFloat(data.qty_realisasi_produksi ?? 0)} ${
                          data.kode_satuan ?? ""
                        }`
                      : "-"
                  }
                />
                <InfoItemHorizontal
                  label="Unit Produksi"
                  text={data?.nama_unit_produksi ?? "-"}
                />
              </tbody>
            </table>
          </Col>

          <Col lg>
            <table>
              <tbody>
                <InfoItemHorizontal
                  label="Tgl. Sales Order"
                  text={
                    data?.tgl_sales_order
                      ? DateConvert(new Date(data?.tgl_sales_order)).detail
                      : "-"
                  }
                />
                <InfoItemHorizontal
                  label="No. Sales Order"
                  text={data?.no_sales_order ?? "-"}
                />
                <InfoItemHorizontal
                  label="Customer"
                  text={data?.nama_customer ?? "-"}
                />
              </tbody>
            </table>
          </Col>
        </Row>

        {/* Only show in Detail HPP */}
        {type === "DETAIL" && (
          <>
            <hr />
            <Row>
              <Col>
                <Row>
                  <Col>
                    <InfoItemVertical
                      label="Tgl. HPP"
                      text={
                        data.tgl_hpp
                          ? DateConvert(new Date(data.tgl_hpp)).detail
                          : "-"
                      }
                    />
                  </Col>
                  <Col>
                    <InfoItemVertical
                      label="No. HPP"
                      text={data.no_hpp ?? "-"}
                    />
                  </Col>
                </Row>
                <InfoItemVertical
                  label="COA Debet"
                  text={`${data.nomor_akun_debet} - ${data.nama_akun_debet}`}
                />
                <InfoItemVertical
                  label="COA Kredit"
                  text={`${data.nomor_akun_kredit} - ${data.nama_akun_kredit}`}
                />
              </Col>

              <Col>
                <InfoItemVertical
                  label="Jumlah"
                  text={
                    RupiahConvert(String(parseFloat(data.jumlah))).getWithComa
                  }
                />
                <InfoItemVertical
                  label="Keterangan Jurnal"
                  text={data.keterangan_jurnal}
                />
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default InfoSection;
