import Services from "services";

class HargaSatuanRataRataPenyusutanApi {
  page(value) {
    return Services.get("/harga_satuan_penyusutan/page", {
      params: { ...value },
    });
  }

  hsr(value) {
    return Services.get("/harga_satuan_penyusutan/hsr", {
      params: { ...value },
    });
  }

  create(value) {
    return Services.post("/harga_satuan_penyusutan", value);
  }
  export(params) {
    return Services.get("/harga_satuan_penyusutan/export", { params });
  }
}

export default new HargaSatuanRataRataPenyusutanApi();
