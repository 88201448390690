import {
  createContext,
  useState
} from 'react'

export const RAEContext = createContext()

export const RAEContextProvider = ({ children }) => {
  const [indexAnalisa, setIndexAnalisa] = useState('')
  const [type, setType] = useState('')
  const [actionBOK, setActionBOK] = useState('CREATE')
  const [dataAnalisaBarangJadi, setDataAnalisaBarangJadi] = useState([])

  return (
    <RAEContext.Provider value={{
      indexAnalisa,
      type,
      dataAnalisaBarangJadi,
      actionBOK,
      setIndexAnalisa,
      setType,
      setDataAnalisaBarangJadi,
      setActionBOK
    }}>
      {children}
    </RAEContext.Provider>
  )
}
