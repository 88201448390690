import { useState } from "react";
import {
  IoAddOutline,
  IoCheckmark,
  IoCheckmarkOutline,
  IoEyeOutline,
} from "react-icons/io5";
import {
  Table,
  THead,
  TBody,
  Th,
  Td,
  ThFixed,
  DataStatus,
  Input,
  ActionButton,
} from "components";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";
import { ModalBDPProduksi } from "./ModalBDPProduksi";
import { generateNameHandler, getTotal } from "../Utils";
import ModalPostingBDP from "./ModalPostingBDP";

export const TabItem = ({
  type,
  action,
  dataRealisasi = [],
  dataHistory = [],
  setDataRealisasi,
  alertSuccessTrigger,
  alertErrorTrigger,
  refreshHandler,
}) => {
  const generateName = generateNameHandler(type);
  const [modal, setModal] = useState({
    show: false,
    data: {},
    action: "detail",
  });
  const [modalPostingBDP, setModalPostingBDP] = useState({
    type: "",
    data: [],
    show: false,
  });

  const filterDataPosting = dataRealisasi
    .filter(
      (val) =>
        dataHistory?.find((fil) => fil?.id_realisasi === val?.id)
          ?.id_realisasi !== val?.id
    )
    .map((val) => ({
      ...val,
      nomor_akun:
        type === "upah"
          ? "2010102"
          : type === "alat_mesin"
          ? "60902"
          : type === "subkon"
          ? "2010102"
          : type === "overhead"
          ? "2010399"
          : val.nomor_akun,

      nama_akun:
        type === "upah"
          ? "Hutang Subkon/Mandor"
          : type === "alat_mesin"
          ? "Penyusutan Mesin dan Peralatan"
          : type === "subkon"
          ? "Hutang Subkon/Mandor"
          : type === "overhead"
          ? "BYMH Lainnya"
          : val.nama_akun,
    }));

  return (
    <div className="bg-white p-3 border-top-0 border">
      {/* List Realisasi Item */}
      <div className="d-flex justify-content-between p-2">
        <b>List Realisasi Item {generateName}</b>
        <ActionButton
          size="sm"
          disable={filterDataPosting <= 0}
          text="POSTING"
          onClick={() =>
            setModalPostingBDP({
              show: true,
              type: type,
              data: filterDataPosting ?? [],
            })
          }
        />
      </div>
      <Table>
        <THead>
          <tr>
            <ThFixed>No.</ThFixed>
            <Th style={{ minWidth: 150 }}>Informasi Realisasi Produksi</Th>
            <Th style={{ minWidth: 200 }}>Item {generateName}</Th>
            {type === "upah" || type === "subkon" ? (
              <Th style={{ minWidth: 200 }}>Vendor</Th>
            ) : (
              <></>
            )}
            <Th style={{ width: 150 }}>Satuan</Th>
            <Th style={{ width: 150 }}>Qty. Realisasi {generateName}</Th>
            <Th style={{ width: 150 }}>Harga Satuan</Th>
            <Th style={{ width: 150 }}>Jumlah</Th>
            {/* <Th style={{ width: 40 }}>Aksi</Th> */}
          </tr>
        </THead>
        <TBody>
          {dataRealisasi && dataRealisasi?.length > 0 ? (
            dataRealisasi.map((val, index) => (
              <tr key={index}>
                <Td className="text-center">{index + 1}</Td>
                <Td>
                  <span className="d-block">
                    {val?.tgl_realisasi
                      ? DateConvert(new Date(val.tgl_realisasi)).defaultDMY
                      : "-"}
                  </span>
                  <span className="d-block">{val?.no_realisasi ?? "-"}</span>
                </Td>
                <Td>{val?.nama_item ?? "-"}</Td>
                {type === "upah" || type === "subkon" ? (
                  <Td>{val?.nama_vendor ?? "-"}</Td>
                ) : (
                  <></>
                )}
                <Td>{val?.satuan ?? "-"}</Td>
                <Td textRight>
                  {val?.qty_realisasi
                    ? DecimalConvert(val.qty_realisasi).getWithComa
                    : "-"}
                </Td>
                <Td textRight>
                  {val?.harga_satuan
                    ? RupiahConvert(parseFloat(val.harga_satuan).toString())
                        .getWithComa
                    : "-"}
                </Td>
                <Td textRight>
                  {RupiahConvert(getTotal(val).toString()).getWithComa}
                </Td>
                {/* <Td className="text-center">
                  {dataHistory?.find((fil) => fil?.id_realisasi === val?.id) ? (
                    <ActionButton
                      size="sm"
                      variant="success"
                      text={<IoCheckmarkOutline />}
                    />
                  ) : (
                    <ActionButton
                      size="sm"
                      text={<IoAddOutline />}
                      onClick={() =>
                        setModal({ show: true, action: "form", data: val })
                      }
                    />
                  )}
                </Td> */}
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
        </TBody>
      </Table>

      {/* History Realisasi */}
      <div className="p-2 mt-4">
        <b>History BDP {generateName}</b>
      </div>
      <Table>
        <THead>
          <tr>
            <ThFixed>No.</ThFixed>
            <Th style={{ width: 150 }}>Informasi BDP {generateName}</Th>
            <Th style={{ minWidth: 200 }}>Item {generateName}</Th>
            {type === "upah" || type === "subkon" ? (
              <Th style={{ minWidth: 200 }}>Vendor</Th>
            ) : (
              <></>
            )}
            <Th style={{ width: 150 }}>Jumlah</Th>
            <Th style={{ minWidth: 200 }}>Akun COA</Th>
            <Th style={{ width: 150 }}>Keterangan Jurnal</Th>
            <Th style={{ width: 40 }}>Aksi</Th>
          </tr>
        </THead>
        <TBody>
          {dataHistory && dataHistory?.length > 0 ? (
            dataHistory.map((val, index) => (
              <tr key={index}>
                <Td className="text-center">{index + 1}</Td>
                <Td>
                  <span className="d-block">
                    {val?.tgl_bdp
                      ? DateConvert(new Date(val.tgl_bdp)).defaultDMY
                      : "-"}
                  </span>
                  <span className="d-block">{val?.no_bdp ?? "-"}</span>
                </Td>
                <Td>{val?.nama_item ?? "-"}</Td>
                {type === "upah" || type === "subkon" ? (
                  <Td>{val?.nama_vendor ?? "-"}</Td>
                ) : (
                  <></>
                )}
                <Td textRight>
                  {val?.harga_satuan && val?.qty_realisasi
                    ? RupiahConvert(
                        parseFloat(
                          val?.harga_satuan * val?.qty_realisasi
                        ).toString()
                      ).getWithComa
                    : "-"}
                </Td>
                <Td>
                  <span className="d-block">
                    D: {val?.nomor_akun_debet ?? ""} -{" "}
                    {val?.nama_akun_debet ?? ""}
                  </span>
                  <div className="mt-2" />
                  <span className="d-block">
                    K: {val?.nomor_akun_kredit ?? ""} -{" "}
                    {val?.nama_akun_kredit ?? ""}
                  </span>
                </Td>
                <Td>{val?.keterangan ?? "-"}</Td>
                <Td className="text-center">
                  <ActionButton
                    size="sm"
                    text={<IoEyeOutline />}
                    onClick={() =>
                      setModal({ show: true, action: "detail", data: val })
                    }
                  />
                </Td>
              </tr>
            ))
          ) : (
            <tr>
              <Td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
        </TBody>
      </Table>

      <ModalBDPProduksi
        show={modal.show}
        action={modal.action}
        type={type}
        data={modal.data}
        refreshHandler={refreshHandler}
        onHide={() =>
          setModal({
            show: false,
            action: "",
            data: {},
          })
        }
        alertSuccessTrigger={alertSuccessTrigger}
        alertErrorTrigger={alertErrorTrigger}
      />
      {modalPostingBDP.show && (
        <ModalPostingBDP
          setModalConfig={setModalPostingBDP}
          modalConfig={modalPostingBDP}
          alertSuccessTrigger={alertSuccessTrigger}
          alertErrorTrigger={alertErrorTrigger}
          refreshHandler={refreshHandler}
        />
      )}
    </div>
  );
};
