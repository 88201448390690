import {
  useState
} from 'react'
import {
  Editor
} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const TextEditor = ({
  label, 
  placeholder, 
  error, 
  errorText, 
  editorState, 
  onEditorStateChange,
  className,
  wrapperClassName,
  style,
  wrapperStyle,
  noMargin,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false)

  return (  
    <div className={`${noMargin ? 'm-0' : 'mb-2'} ${wrapperClassName}`} style={wrapperStyle}>
      <label>
        <small>
          {label}
        </small>
      </label>
      <Editor
        {...rest}
        placeholder={placeholder}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        wrapperClassName={`text-editor ${isFocused && 'focused'} ${className} ${error && 'is-invalid'}`}
        editorClassName="px-3"
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        wrapperStyle={style}
      />
      <small className="mt-1 text-danger">
        {errorText}
      </small>
    </div>
  )
}

export default TextEditor