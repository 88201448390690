// React
import { useRef, useMemo, useState, useCallback } from 'react'

// Component
import { Container, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ActionButton, DeleteModal } from 'components'

// Icon
import { 
  IoDownloadOutline, IoImageOutline, IoTrashOutline
} from 'react-icons/io5'

// Package
import ImageViever from 'react-simple-image-viewer'
import FileSaver from 'file-saver'

// API
import { RegistrasiBarangJadiApi } from 'api'

const TabGambar = ({type: TYPE, dataGambar, setDataGambar, handleUpdateFilesOnDrop, loading, setLoading, id_item_atribut}) => {
  const inputFileRef = useRef()
  const [modalDeleteConfig, setModalDeleteConfig] = useState({
    index: '',
    show: false,
    loading: false
  })
  const [viewImageConfig, setViewImageConfig] = useState({
    show: false,
    index: ''
  })

   // Filter data gambar agar array hanya terisi link gambar
   const filterImageHandler = useMemo(() => {
    return dataGambar.map(val => val.path_gambar)
  }, [dataGambar])

  // Menampilkan ImageViewer
  const setIndexImageHandler = useCallback((index) => {
    setViewImageConfig({
      show: true,
      index: index
    })
  }, [])

  // Trigger input file agar terclick
  const onClickFilesHandler = () => TYPE !== 'detail' && inputFileRef.current.click()

  // Menangani saat file dipilih
  const onChangeFileHandler = (e) => {
    setLoading(true)

    const file = e.target.files[0]
    
    // Check apakah ada file yang dipilih
    if (!file) return null 
  
    // Ambil file type lalu split dengan '/' dan ambil index ke 1 (cth: image/png menjadi png)
    const getFileType = file.type.split('/')[1] 

    // Check jika type file sudah benar
    if (['jpg', 'png', 'gif', 'jpeg'].includes(getFileType)) {
      const formData = new FormData()

      formData.append("file_0", file)

      handleUpdateFilesOnDrop(formData)
    } else {
      window.alert('File tidak valid! harap masukan gambar (.jpg, .png, .gif, .jpeg)')
    }
  }

  // Menangani hapus file
  const onDeleteHandler = () => {
    setModalDeleteConfig({...modalDeleteConfig, loading: true})
    const data = dataGambar.find((val, index) => index === modalDeleteConfig.index)
    const filter = dataGambar.filter((val, index) => index !== modalDeleteConfig.index)

    RegistrasiBarangJadiApi.deleteFile({ id_item_buaso_gambar_barang_jadi: [data.id_item_buaso_gambar_barang_jadi] })
      .finally(() => {
        RegistrasiBarangJadiApi.single({ id_item_atribut })
          .then(() => setDataGambar(filter))
          .finally(() => {
            setModalDeleteConfig({
              index: '',
              show: false,
              loading: false
            })
          })
      })
  }

  // Menampilkan komponen foto
  const Image = ({imageText, imageUrl, onView, onDownload, onDelete}) => {
    const [isHover, setIsHover] = useState(false)

    return (
      <OverlayTrigger
        placement="bottom"
        flip={true}
        overlay={<Tooltip style={{zIndex: 1}}>{imageText ?? 'Foto Barang Jadi'}</Tooltip>}
      >
        <Col 
          sm={12} 
          md={6} 
          lg={4} 
          xl={3}
          className="my-3"
          style={{cursor: 'zoom-in', objectFit: 'cover'}}
          onClick={onView}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {/* Download Button */}
          <ActionButton
            size="sm"
            variant="primary"
            style={{position: 'absolute', zIndex: 1, right: 20}}
            className="m-2 shadow"
            onClick={onDownload}
          >
            <IoDownloadOutline />
          </ActionButton>

          {/* Delete Button */}
          {TYPE !== 'detail' && 
            <ActionButton
              size="sm"
              variant="danger"
              style={{position: 'absolute', zIndex: 1, top: 35, right: 20}}
              className="m-2 shadow"
              onClick={onDelete}
            >
              <IoTrashOutline />
            </ActionButton>
          }
          <img 
            className={`img-fluid rounded border ${isHover ? 'shadow' : 'shadow-sm'}`}
            style={{height: 200, width: '100%', objectFit: 'cover', transform: isHover ? 'scale(1.03)' : 'none', transition: 'all 0.1s ease-in-out'}}
            src={imageUrl} 
            alt=""
          />
        </Col>
      </OverlayTrigger>
    )
  }

  return (
    <div>
      {/* Button Section */}
      <div className="mb-3 text-right">
        <input 
          ref={inputFileRef} 
          type="file" 
          accept="image/png, image/gif, image/jpeg, image/jpg"
          style={{display: 'none'}} 
          onChange={onChangeFileHandler}
        />
        {TYPE !== 'detail' && 
          <ActionButton 
            text="Tambah Gambar"
            loading={loading}
            onClick={onClickFilesHandler} 
          />
        }
      </div>

      {/* Image Section */}
      <Container
        fluid
        className="bg-light rounded overflow-auto border"
        onClick={onClickFilesHandler}
        style={{cursor: 'pointer', maxHeight: '60vh'}}
      >
        {dataGambar && dataGambar.length > 0
          ? <Row>
              {dataGambar.map((val, index) => (
                <Image 
                  key={index}
                  imageText={val.path_gambar.split("/").pop()}
                  imageUrl={val.path_gambar}
                  onView={e => {
                    e.stopPropagation()
                    setIndexImageHandler(index)
                  }}
                  onDownload={e => {
                    e.stopPropagation()
                    FileSaver.saveAs(val.path_gambar, val.path_gambar.split("/").pop())
                  }}
                  onDelete={e => {
                    e.stopPropagation()
                    setModalDeleteConfig({
                      show: true,
                      loading: false,
                      index: index
                    })
                  }}
                />
              ))}
            </Row>
          : <div className="d-flex flex-column justify-content-center align-items-center py-5">
              <IoImageOutline size={60}/>
              <b className="mt-2">Tidak ada gambar</b>
            </div>
        }
      </Container>

      {/* Modal */}
      <DeleteModal
        show={modalDeleteConfig.show} 
        title="Gambar"
        loading={modalDeleteConfig.loading}
        onConfirm={() => onDeleteHandler()}
        onHide={() => setModalDeleteConfig({
          show: false,
          index: ''
        })}
      />
          
      {/*Image Viewer  */}
      {viewImageConfig.show &&
        <div style={{zIndex: 1000}}>
          <ImageViever 
            closeOnClickOutside
            disableScroll
            src={filterImageHandler}
            currentIndex={viewImageConfig.index}
            onClose={() => setViewImageConfig({show: false, index: 0})}
            backgroundStyle={{zIndex: 1000}}
          />
        </div>
      }
    </div>
  )
}

export default TabGambar
