import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { FormProduksiAsetContent } from "./__NilaiPerolehanProduksiAsetComps__";
import { useMutation, useQuery } from "react-query";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import ProduksiAsetApi from "./__NilaiPerolehanProduksiAsetApi__";
import {
  formInitialValues,
  formSubmitValueMapper,
  formValidationSchema,
} from "./__NilaiPerolehanProduksiAsetUtils__";
import { Alert, DataStatus } from "components";

export const NilaiPerolehanProduksiAsetCreate = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const {
    data: detailPermintaanAset,
    isFetching: loadingDetailPermintaanAset,
  } = useQuery(
    ["permintaanAset", "single", id],
    () =>
      ProduksiAsetApi.getSinglePermintaanAvailable({
        id_produksi_aset: id,
      }),
    { enabled: !!id }
  );

  const produksiAset = useMutation((data) => ProduksiAsetApi.create(data));
  const formSubmitHandler = (value) => {
    produksiAset
      .mutateAsync(formSubmitValueMapper(value))
      .then(() =>
        history.push("/transaksi/nilai-perolehan-produksi-aset", {
          alert: {
            variant: "primary",
            show: true,
            text: "Data berhasil disimpan!!!",
          },
        })
      )
      .catch((err) =>
        setAlertConfig({
          variant: "danger",
          show: true,
          text: "Data gagal disimpan!!!",
        })
      );
  };

  useEffect(() => {
    setNavbarTitle("Nilai Perolehan Produksi Aset");
    return () => {};
  }, []);

  return (
    <>
      <Alert
        show={alertConfig?.show}
        text={alertConfig?.text}
        variant={alertConfig?.variant}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
        showCloseButton
      />
      {loadingDetailPermintaanAset ? (
        <DataStatus text="Memuat Data..." loading />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(detailPermintaanAset?.data ?? {})}
          onSubmit={formSubmitHandler}
          validationSchema={formValidationSchema}
        >
          <FormProduksiAsetContent action="create" />
        </Formik>
      )}
    </>
  );
};
