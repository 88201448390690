export const formSubmitMapper = (values) => ({
  tgl_posting_stock_opname: values.tglPostingStockOpname,
  qty_adjustment: values.qtyAdjustment,
  jumlah: parseFloat(
    values.jumlah
      .toString()
      .split("")
      .filter((val) => val !== "-")
      .join("")
  ),
  keterangan_jurnal: values.keteranganJurnal,
  nomor_akun_debet: values.nomorAkunDebet,
  nomor_akun_kredit: values.nomorAkunKredit,
  id_stock_opname_master: values.idStockOpnameMaster,
  id_stock_opname_detail: values.idStockOpnameDetail,
});
