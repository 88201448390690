import { Fragment, useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { Formik } from "formik";
import { ActionButton, CRUDLayout, TextArea } from "components";
import { ButtonBack, ButtonCreate, DataStatus } from "components2";
import { useModalConfirm } from "hooks2";
import PostingPettyCashApi from "./__PostingPettyCashApi__";
import {
  ModalCatatanRevisi,
  PostingPettyCashContent,
  TableSection,
} from "./__PostingPettyCashComps__";
import {
  formInitialValues,
  formSubmitValueMapper,
} from "./__PostingPettyCashUtils__";

export const PostingPettyCashTambah = ({ setNavbarTitle }) => {
  const history = useHistory();
  const modalConfirm = useModalConfirm();

  const [showModalrevise, setShowModalRevise] = useState(false);

  const { id: id_realisasi_petty_cash } = useParams();
  const { REACT_APP_SHOW_FILE_FORM_HRDU_BASE_URL } = process.env;
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      "single",
      "posting_realisasi_petty_cash",
      id_realisasi_petty_cash,
    ],
    queryFn: () =>
      PostingPettyCashApi.getSingleRealisasi({ id_realisasi_petty_cash }).then(
        (res) => {
          const mapFiles = res?.data?.detail?.map((item) => ({
            ...item,
            files: item?.media?.map((e) => ({
              nama: e?.path_media?.split("/").pop(),
              link: `${REACT_APP_SHOW_FILE_FORM_HRDU_BASE_URL}/${e?.path_media}`,
              path_media: e?.path_media,
            })),
          }));

          return { ...res, data: { ...res.data, detail: mapFiles ?? [] } };
        }
      ),
  });

  const onSubmitForm = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const IS_REVISE = !!values?.catatan_revisi?.length;

    const modalProps = {
      type: IS_REVISE ? "update" : "create",
      customButtonText: IS_REVISE ? "Revisi" : "Tambah",
      title: IS_REVISE
        ? "merevisi data Realisasi Petty Cash"
        : "menambah data Posting Petty Cash",
      customTextHeader: IS_REVISE ? "merevisi" : "menambah",
    };

    await modalConfirm.trigger({
      size: "xl",
      type: modalProps.type,
      customButtonText: modalProps.customButtonText,
      title: `Apakah Anda yakin akan ${modalProps.title} berikut?`,
      customTextHeader: `Apakah anda yakin akan ${modalProps.customTextHeader} data ini?`,
      component: (
        <Formik initialValues={values}>
          <Fragment>
            <PostingPettyCashContent action="DETAIL" />
            <hr />
            <TableSection action="CONFIRM" data={values?.detail ?? []} />
          </Fragment>
        </Formik>
      ),

      onSubmit: async () => {
        modalConfirm.loading();

        const finalValue = formSubmitValueMapper(
          values,
          IS_REVISE ? "REVISE" : "CREATE"
        );

        if (IS_REVISE) {
          return PostingPettyCashApi.revise(finalValue)
            .then(() =>
              modalConfirm.infoSuccess({
                title: "Data Realisasi Petty Cash Berhasil Direvisi ",
                customTextInfoDetail: "Data telah tersimpan ke database",
                size: "md",
                onHide: () => {
                  modalConfirm.close();
                  history.replace("/transaksi/posting-petty-cash");
                },
              })
            )
            .catch(({ response }) => {
              modalConfirm.infoError({
                title: "Data Posting Petty Cash Gagal Ditambah",
                customTextInfoTitle: "Penyebab Kegagalan:",
                customTextInfoDetail: response?.data?.message,
              });
            });
        }

        PostingPettyCashApi.create(finalValue)
          .then(() =>
            modalConfirm.infoSuccess({
              title: "Data Posting Petty Cash Berhasil Ditambah",
              customTextInfoDetail: "Data telah tersimpan ke database",
              size: "md",
              onHide: () => {
                modalConfirm.close();
                history.replace("/transaksi/posting-petty-cash");
              },
            })
          )
          .catch(({ response }) => {
            modalConfirm.infoError({
              title: "Data Posting Petty Cash Gagal Ditambah",
              customTextInfoTitle: "Penyebab Kegagalan:",
              customTextInfoDetail: response?.data?.message,
            });
          });
      },
      onHide: () => modalConfirm.close(),
    });
  };

  useEffect(() => setNavbarTitle("Posting Petty Cash"), []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadButtonSection>
          <ActionButton
            size="md"
            variant="warning"
            className="text-white px-4 mr-2"
            text="Revisi"
            onClick={() => setShowModalRevise(true)}
          />
          <ButtonBack size="md" onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {isLoading || isError ? (
        <DataStatus
          loading={isLoading}
          text={isLoading ? "Memuat . . ." : "Data gagal dimuat"}
        />
      ) : (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(data?.data, "CREATE")}
          onSubmit={onSubmitForm}
        >
          {({ values, handleChange, handleSubmit }) => {
            const isPostEveryItem = values?.detail?.every(
              ({ isPosting }) => !!isPosting
            );

            return (
              <Card className="p-4 mt-4">
                <PostingPettyCashContent action="CREATE" />
                <hr />

                <TableSection data={values?.detail ?? []} />

                <Row className="mt-4 px-3 d-flex justify-content-end align-items-center">
                  <ButtonCreate
                    disabled={
                      !!values?.catatan_revisi?.length || !isPostEveryItem
                    }
                    size="md"
                    onClick={() => handleSubmit()}
                  />
                </Row>
                {showModalrevise && (
                  <ModalCatatanRevisi
                    setShow={setShowModalRevise}
                    show={showModalrevise}
                  />
                )}
              </Card>
            );
          }}
        </Formik>
      )}
    </CRUDLayout>
  );
};
