import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, Nav, Tab } from "react-bootstrap";
import { BackButton, DataStatus, Alert } from "components";
import { HPPApi } from "api";
import { InfoSection, TabItem, SummaryBDP } from "./components";
import { mapBDP, grandTotalBDP } from "./helpers";

const DetailHPP = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id: id_hpp } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataInfo, setDataInfo] = useState({});
  const [dataBDP, setDataBDP] = useState({
    bahan: [],
    upah: [],
    alat_mesin: [],
    subcont: [],
    overhead: [],
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    HPPApi.single({ id_hpp })
      .then((res) => {
        const data = res.data.data ?? {};

        setDataInfo({
          tgl_hpp: data.tgl_hpp,
          no_hpp: data.no_hpp,
          tgl_sales_order: data.tgl_sales_order,
          no_sales_order: data.no_sales_order,
          item_produksi: data.item_produksi,
          nama_customer: data.nama_customer,
          tgl_jobmix: data.tgl_jobmix,
          no_jobmix: data.no_jobmix,
          qty_jobmix: data.qty_jobmix,
          qty_jobmix_realisasi: data.qty_jobmix_realisasi,
          kode_satuan: data.kode_satuan,
          nomor_akun_debet: data.nomor_akun_debet,
          nama_akun_debet: data.nama_akun_debet,
          nomor_akun_kredit: data.nomor_akun_kredit,
          nama_akun_kredit: data.nama_akun_kredit,
          keterangan_jurnal: data.keterangan_jurnal,
          jumlah: data.jumlah,
          no_realisasi_produksi: data.no_realisasi_produksi,
          tgl_realisasi_produksi: data.tgl_realisasi_produksi,
          qty_realisasi_produksi: data.qty_realisasi_produksi,
          id_realisasi_produksi: data.id_realisasi_produksi,
          no_realisasi_produksi: data.no_realisasi_produksi,
          nama_unit_produksi: data.nama_unit_produksi,
        });

        setDataBDP({
          bahan: mapBDP(data.bdp_bahan),
          upah: mapBDP(data.bdp_upah),
          alat_mesin: mapBDP(data.bdp_alat_mesin),
          subcont: mapBDP(data.bdp_subkon),
          overhead: mapBDP(data.bdp_overhead),
        });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Tidak dapat memuat data!",
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const calculateBDP = grandTotalBDP(dataBDP);

  useEffect(() => {
    setNavbarTitle("Harga Pokok Produksi");
    getInitialData();

    return () => {};
  }, []);

  if (isPageLoading) {
    return <DataStatus loading={isPageLoading} text="Memuat Data . . ." />;
  }

  return (
    <>
      <div className="text-right mb-2">
        <BackButton onClick={() => history.goBack()} />
      </div>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
      />

      {/*  Info Section */}
      <div className="font-weight-bold mb-1">
        Detail Data Harga Pokok Produksi
      </div>
      <InfoSection type="DETAIL" data={dataInfo} />

      {/* Tabs */}
      <div className="mt-3">
        <div className="font-weight-bold mb-1">List Data BDP Produksi</div>
        <Card>
          <Tab.Container defaultActiveKey="bahan">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="bahan">BDP Bahan</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="upah">BDP Upah</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="alat_mesin">BDP Alat & Mesin</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="subkon">BDP Subkon</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="overhead">BDP Overhead</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>

            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="bahan">
                  <TabItem type="bahan" data={dataBDP.bahan} />
                </Tab.Pane>
                <Tab.Pane eventKey="upah">
                  <TabItem type="upah" data={dataBDP.upah} />
                </Tab.Pane>
                <Tab.Pane eventKey="alat_mesin">
                  <TabItem type="alat_mesin" data={dataBDP.alat_mesin} />
                </Tab.Pane>
                <Tab.Pane eventKey="subkon">
                  <TabItem type="subkon" data={dataBDP.subcont} />
                </Tab.Pane>
                <Tab.Pane eventKey="overhead">
                  <TabItem type="overhead" data={dataBDP.overhead} />
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>
      </div>

      {/* Summary BDP */}
      <div className="mt-3">
        <div className="font-weight-bold mb-1">Summary BDP</div>
        <SummaryBDP calculateBDP={calculateBDP} />
      </div>
    </>
  );
};

export default DetailHPP;
