import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { FilterModal, DatePicker, SelectSearch } from "components";
import { DateConvert } from "utilities";
import Axios from "axios";
import { PostingStockOpnameApi } from "api";

const ModalFilter = ({ show, setShow, data, setData, dropdown }) => {
  // STATE DATA
  const [dataItemBarang, setDataUnitProduksi] = useState([
    { label: "Semua", value: undefined },
  ]);

  // FORM VALUES
  const formInitialValues = {
    tgl_sales_order_mulai: data?.filter?.tgl_sales_order_mulai,
    tgl_sales_order_selesai: data?.filter?.tgl_sales_order_selesai,
    id_customer: data?.filter?.id_customer,
    id_proyek: data?.filter?.id_proyek,
    id_karyawan: data?.filter?.id_karyawan,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setData({
        filter: {
          ...values,
          active: true,
        },
        pagination: {
          ...data.pagination,
          page: 1,
        },
      });
    } else {
      setData({
        ...data,
        filter: {
          ...values,
          active: false,
        },
      });
    }

    setShow(false);
  };
  // HANDLE CHANGE
  const onTglStockOpnameChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      id_customer: startDate,
      id_proyek: endDate,
    });
  };

  const onTglPostingStockOpnameChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_sales_order_mulai: startDate,
      tgl_sales_order_selesai: endDate,
    });
  };

  const onResetButtonClick = (values, setValues) => {
    setValues({
      tgl_sales_order_mulai: undefined,
      tgl_sales_order_selesai: undefined,
      id_customer: undefined,
      id_proyek: undefined,
      id_karyawan: undefined,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Sales Order"
            placeholderText="Pilih tanggal sales order"
            startDate={
              values.tgl_sales_order_mulai
                ? new Date(values.tgl_sales_order_mulai)
                : ""
            }
            endDate={
              values.tgl_sales_order_selesai
                ? new Date(values.tgl_sales_order_selesai)
                : ""
            }
            onChange={(dates) =>
              onTglPostingStockOpnameChange(dates, values, setValues)
            }
            monthsShown={2}
          />

          <SelectSearch
            key={values.id_customer}
            label="Customer"
            placeholder="Pilih customer"
            defaultValue={dropdown?.customer?.find(
              (val) => val.value === values.id_customer
            )}
            option={dropdown?.customer}
            onChange={(val) => {
              setFieldValue("id_customer", val.value);
            }}
          />

          <SelectSearch
            key={values.id_proyek}
            label="Proyek"
            placeholder="Pilih proyek"
            defaultValue={dropdown?.proyek?.find(
              (val) => val.value === values.id_proyek
            )}
            option={dropdown?.proyek}
            onChange={(val) => {
              setFieldValue("id_proyek", val.value);
            }}
          />

          <SelectSearch
            key={values.id_karyawan}
            label="Karyawan"
            placeholder="Pilih karyawan"
            defaultValue={dropdown?.sales?.find(
              (val) => val.value === values.id_karyawan
            )}
            option={dropdown?.sales}
            onChange={(val) => {
              setFieldValue("id_karyawan", val.value);
            }}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilter;
