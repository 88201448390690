import {
  Table,
  THead,
  TBody,
  Tr,
  Td,
  TdFixed,
  ThFixed,
  Th,
  DataStatus,
  SelectSearch,
} from "components";
import { useFormikContext } from "formik";
import React from "react";
import { useQuery } from "react-query";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";
import WIPProduksiAsetApi from "../__WIProduksiAsetApi__";

export const TableListRealisasi = ({ section, title, action, tab }) => {
  const { values, setValues } = useFormikContext();

  const satuanShow = () => {
    if (tab === "bahan" || tab === "alatMesin" || tab === "overhead")
      return true;

    return false;
  };

  const { data: dropdownCOAKredit, isFetching: loadingDropdownCOAKredit } =
    useQuery(["dropdownCOAKredit", "dropdown"], () =>
      WIPProduksiAsetApi.getDropdownCOA({ normal_pos: "K" })
    );

  const { data: dropdownCOADebet, isFetching: loadingDropdownCOADebet } =
    useQuery(["dropdownCOADebet", "dropdown"], () =>
      WIPProduksiAsetApi.getDropdownCOA({ normal_pos: "D" })
    );

  return (
    <div>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Informasi Realisasi Produksi Aset</ThFixed>
            <Th className="text-capitalize">Item {title}</Th>
            {satuanShow() && <Th>Satuan</Th>}
            {!satuanShow() && <Th>Vendor</Th>}
            <Th>Qty</Th>
            <Th>Harga Satuan</Th>
            <Th>Jumlah</Th>
            {action === "create" && (
              <>
                <Th>COA Debet</Th>
                <Th>COA Kredit</Th>
              </>
            )}
          </Tr>
        </THead>
        <TBody>
          {values?.[section]?.[tab]?.length > 0 ? (
            values?.[section]?.[tab]?.map((val, ind) => (
              <Tr key={ind}>
                <TdFixed>{ind + 1}</TdFixed>
                <TdFixed>
                  <div>
                    {val.tgl_realisasi_produksi_aset
                      ? DateConvert(new Date(val.tgl_realisasi_produksi_aset))
                          .defaultDMY
                      : "-"}
                  </div>
                  <div>{val?.no_realisasi_produksi_aset ?? "-"}</div>
                </TdFixed>
                <Td>{val?.nama_item ?? "-"}</Td>
                {satuanShow() && <Td>{val?.nama_satuan}</Td>}
                {!satuanShow() && <Td>{val?.nama_vendor}</Td>}
                <Td>
                  {
                    DecimalConvert(parseFloat(val?.qty_realisasi ?? 0))
                      .getWithComa
                  }
                </Td>
                <Td>
                  {
                    RupiahConvert(parseFloat(val?.harga_satuan ?? 0).toString())
                      .getWithComa
                  }
                </Td>
                <Td>
                  {
                    RupiahConvert(
                      String(
                        parseFloat(val?.qty_realisasi ?? 0) *
                          parseFloat(val?.harga_satuan ?? 0)
                      )
                    ).getWithComa
                  }
                </Td>
                {action === "create" && (
                  <>
                    <Td>
                      <SelectSearch
                        option={dropdownCOADebet}
                        loading={loadingDropdownCOADebet}
                        placeholder="Pilih COA Debet"
                        defaultValue={
                          values?.nomor_akun_debet
                            ? {
                                value: values?.nomor_akun_debet,
                                label: values?.nama_akun_debet,
                              }
                            : undefined
                        }
                        onChange={(val) =>
                          setValues({
                            ...values,
                            realisasi: {
                              ...values?.realisasi,
                              [tab]: values?.realisasi?.[tab]?.map((res, i) =>
                                ind === i
                                  ? {
                                      ...res,
                                      nomor_akun_debet: val.value,
                                      nama_akun_debet: val.label,
                                    }
                                  : res
                              ),
                            },
                          })
                        }
                        // error={errors.id_item && touched.id_item && true}
                        // errorText={
                        //   errors.id_item && touched.id_item && errors.id_item
                        // }
                      />
                    </Td>
                    <Td>
                      <SelectSearch
                        option={dropdownCOAKredit}
                        loading={loadingDropdownCOAKredit}
                        placeholder="Pilih COA Kredit"
                        defaultValue={
                          values?.nomor_akun_kredit
                            ? {
                                value: values?.nomor_akun_kredit,
                                label: values?.nama_akun_kredit,
                              }
                            : undefined
                        }
                        onChange={(val) =>
                          setValues({
                            ...values,
                            realisasi: {
                              ...values?.realisasi,
                              [tab]: values?.realisasi?.[tab]?.map((res, i) =>
                                ind === i
                                  ? {
                                      ...res,
                                      nomor_akun_kredit: val.value,
                                      nama_akun_kredit: val.label,
                                    }
                                  : res
                              ),
                            },
                          })
                        }
                        // error={errors.id_item && touched.id_item && true}
                        // errorText={
                        //   errors.id_item && touched.id_item && errors.id_item
                        // }
                      />
                    </Td>
                  </>
                )}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="8">
                <DataStatus text="Tidak Ada Data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};
