import Services from "../../services";

class AnalisaBarangJadiApiRAE {
  getAnalisa(params) {
    // Get Analisa Peluang
    return Services.get('/peluang/analisa', { params })
  }

  getAnalisaRAE(params) {
    // Get Analisa RAE
    return Services.get('/rae/analisa_single', { params })
  }

  getAnalisaRAB(params) {
    return Services.get('/rab/analisa_single', { params })
  }

  getDropdown() {
    return Services.get('/dropdown/d_item_buaso')
  }
}

export default new AnalisaBarangJadiApiRAE();