import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import {
    FilterModal,
    DatePicker,
    SelectSearch
} from 'components'
import { DateConvert } from 'utilities'
import Axios from "axios"
import { RAEApi } from 'api'

const ModalFilter = ({ show, setShow, data, setData }) => {
// STATE DATA
    const [dataCustomer, setDataCustomer] = useState([{label: 'Semua', value: undefined}])
    const [dataUnitProduksi, setDataUnitProduksi] = useState([{label: 'Semua', value: undefined}])
    const [dataBaseline, setDataBaseline] = useState([
        {
            label: 'Semua',
            value: undefined
        },
        {
            label: '1',
            value: '1'
        },
        {
            label: '2',
            value: '2'
        },
        {
            label: '3',
            value: '3'
        },
        {
            label: '4',
            value: '4'
        },
        {
            label: '5',
            value: '5'
        },
        {
            label: '6',
            value: '6'
        },
        {
            label: '7',
            value: '7'
        },
        {
            label: '8',
            value: '8'
        },
        {
            label: '9',
            value: '9'
        },
        {
            label: '10',
            value: '10'
        },
    ])
    const [dataStatusApproval, setDataStatusApproval] = useState([
    {
        label: 'Semua',
        value: undefined
    },
    {
        label: 'APPROVED',
        value: 'APP'
    },
    {
        label: 'VERIFIED',
        value: 'VER'
    },
    {
        label: 'REVISI',
        value: 'REV'
    },
    {
        label: 'REJECT',
        value: 'REJ'
    },
    {
        label: 'PENDING',
        value: 'PEN'
    }
    ])
    const [loading, setLoading] = useState(true)
// GET DROPDOWN DATA
    const getDataDropdown = () => {
        Axios.all([
        RAEApi.getDropdownCustomer(),
        RAEApi.getDropdownUnitProduksi()
        ])
        .then(Axios.spread((customer, unit) => {
            const mapDataCustomer = customer?.data?.data ? customer.data.data.map(val => ({label: val?.nama_customer, value: val?.id_customer})) : []
            const mapDataProyek = unit?.data?.data ? unit.data.data.map(val => ({label: val?.nama_unit_produksi, value: val?.id_unit_produksi})) : []

            setDataCustomer([...dataCustomer, ...mapDataCustomer])
            setDataUnitProduksi([{label: 'Semua', value: undefined}, ...mapDataProyek])
        }))
        .finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        getDataDropdown()
    }, [])
// FORM VALIDATION
    const formInitialValues = {
        tgl_rae_mulai: data?.filter?.tgl_rae_mulai,
        tgl_rae_selesai: data?.filter?.tgl_rae_selesai,
        tgl_peluang_mulai: data?.filter?.tgl_peluang_mulai,
        tgl_peluang_selesai: data?.filter?.tgl_peluang_selesai,
        customer: data?.filter?.customer,
        unit_produksi: data?.filter?.unit_produksi,
        baseline: data?.filter?.baseline,
        status_approval: data?.filter?.status_approval,
    }
	const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ 
              filter: {
                  ...values,
                  active: true
              },
              pagination: {
                  ...data.pagination,
                  page: 1
              }
           })
        } else { 
            setData({ 
                ...data,
                filter: {
                    ...values,
                    active: false
                }
             })
        }
    
        setShow(false)
    }
// HANDLE CLICK
    const onTanggalRAEChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_rae_mulai: startDate,
            tgl_rae_selesai: endDate
        })
    }
    const onTanggalPeluangChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_peluang_mulai: startDate,
            tgl_peluang_selesai: endDate
        })
    }
    const onResetButtonClick = (values, setValues) => {
        setValues({
            tgl_spk_mulai: undefined,
            tgl_spk_selesai: undefined,
            tgl_penawaran_mulai: undefined,
            tgl_penawaran_selesai: undefined,
            customer: undefined,
            unit_produksi: undefined,
            baseline: undefined,
            status_approval: undefined,
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={formSubmitHandler}>
            
            {({values, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                    
                    <DatePicker 
                        selectsRange
                        label="Tgl. Unit Cost"
                        placeholderText="Pilih tanggal unit cost"
                        startDate={values.tgl_rae_mulai ? new Date(values.tgl_rae_mulai) : ''}
                        endDate={values.tgl_rae_selesai ? new Date(values.tgl_rae_selesai) : ''}
                        onChange={dates => onTanggalRAEChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <DatePicker 
                        selectsRange
                        label="Tgl. Peluang"
                        placeholderText="Pilih tanggal peluang"
                        startDate={values.tgl_peluang_mulai ? new Date(values.tgl_peluang_mulai) : ''}
                        endDate={values.tgl_peluang_selesai ? new Date(values.tgl_peluang_selesai) : ''}
                        onChange={dates => onTanggalPeluangChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <SelectSearch 
                        key={values.customer}
                        label="Customer"
                        placeholder="Pilih customer"
                        defaultValue={dataCustomer.find(val => val.value === values.customer)}
                        option={dataCustomer}
                        onChange={val => setFieldValue('customer', val.value)}
                        loading={loading}
                    />
                    <SelectSearch 
                        key={values.unit_produksi}
                        label="Unit Produksi"
                        placeholder="Pilih Unit Produksi"
                        defaultValue={dataUnitProduksi.find(val => val.value === values.unit_produksi)}
                        option={dataUnitProduksi}
                        onChange={val => setFieldValue('unit_produksi', val.value)}
                        loading={loading}
                    />
                    <SelectSearch 
                        key={values.baseline}
                        label="Baseline"
                        placeholder="Pilih baseline"
                        defaultValue={dataBaseline.find(val => val.value === values.baseline)}
                        option={dataBaseline}
                        onChange={val => setFieldValue('baseline', val.value)}
                        loading={loading}
                    />
                    <SelectSearch
                        key={values.status_approval}
                        label="Status Approval"
                        placeholder="Pilih Status Approval"
                        defaultValue={dataStatusApproval.find(val => val.value === values.status_approval)}
                        option={dataStatusApproval}
                        onChange={val => setFieldValue('status_approval', val.value)}
                        // loading={loading.proyek}
                    />
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;