import React, { useState, useEffect } from "react";
import { InfoComp, TabComp, TableSummary } from "./Comps";
import { Card } from "react-bootstrap";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { LaporanProyeksiLabaRugi } from "api";
import { BackButton, DataStatus } from "components";

export const DetailRealisasiProduksi = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [dataInfoRealisasi, setDataInfoRealisasi] = useState({});
  const [dataTable, setDataTable] = useState({
    bahan: [],
    upah: [],
    alatMesin: [],
    subkon: [],
    overhead: [],
  });

  const mappingDataInfo = (data) => ({
    tglProduksi: data?.tgl_jobmix ?? null,
    noProduksi: data?.no_jobmix ?? "-",
    tglRealisasiProduksi: data?.tgl_realisasi_produksi ?? null,
    noRealisasiProduksi: data?.no_realisasi_produksi ?? "-",
    kodeItem: data?.kode_item ?? "-",
    namaItem: data?.nama_item ?? "-",
    qtyProduksi: data?.qty_jobmix ?? 0,
    namaProyek: data?.nama_proyek ?? "-",
    namaSatuan: data?.nama_satuan ?? "-",
  });

  const mappingDataTable = (data, type) =>
    data?.map((val) => {
      const keyValue = {
        qty: `qty_realisasi_${type}`,
        harga: `harga_satuan_${type}`,
      };

      return {
        namaItem: val?.nama_item ?? "-",
        namaSatuan: val?.nama_satuan ?? "-",
        vendor: val?.nama_vendor ?? "-",
        qtyRealisasi: parseFloat(val[keyValue?.qty] ?? 0),
        hargaSatuan: parseFloat(val[keyValue?.harga] ?? 0),
      };
    });

  const getInitialData = () => {
    setIsLoading(true);

    LaporanProyeksiLabaRugi.getDetailRealisasiProduksi({
      id_realisasi_produksi: id,
      ...state?.tgl,
    })
      .then(({ data }) => {
        setDataInfoRealisasi(mappingDataInfo(data.data));
        setDataTable({
          ...dataTable,
          bahan: mappingDataTable(data?.data?.bdp_bahan, "bahan"),
          upah: mappingDataTable(data?.data?.bdp_upah, "upah"),
          alatMesin: mappingDataTable(data?.data?.bdp_alat_mesin, "alat_mesin"),
          subkon: mappingDataTable(data?.data?.bdp_subkon, "subkon"),
          overhead: mappingDataTable(data?.data?.bdp_overhead, "overhead"),
        });
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getInitialData();

    return () => {
      setIsLoading(false);
      setDataInfoRealisasi({});
      setDataTable({
        bahan: [],
        upah: [],
        alatMesin: [],
        subkon: [],
        overhead: [],
      });
    };
  }, []);

  return (
    <Card>
      <Card.Header>Detail Proyeksi Realisasi</Card.Header>
      <Card.Body>
        {isLoading ? (
          <DataStatus text="Memuat..." loading />
        ) : (
          <>
            <InfoComp data={dataInfoRealisasi} />
            <hr />
            <b>Detail Proyeksi BDP</b>
            <TabComp data={dataTable} />
            <TableSummary data={dataTable} />
            <div className="d-flex justify-content-end mt-3">
              <BackButton onClick={() => history.goBack()} />
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};
