import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { IoAdd, IoAddOutline } from "react-icons/io5";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  BackButton,
  FilterButton,
} from "components";
import ModalDetailPeluang from "./ModalDetailPeluang";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { RegistrasiPeluangApi, RAEApi } from "api";
import { ModalFilter } from "./Section";

const PeluangRAE = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.peluang?.filter?.active,
      tgl_peluang_mulai: location?.state?.peluang?.filter?.tgl_peluang_mulai,
      tgl_peluang_selesai: location?.state?.peluang?.filter?.tgl_peluang_selesai,
      peringkat_peluang: location?.state?.peluang?.filter?.peringkat_peluang,
      customer: location?.state?.peluang?.filter?.customer,
      proyek: location?.state?.peluang?.filter?.proyek,
      unit_produksi: location?.state?.peluang?.filter?.unit_produksi,
    },
    pagination: {
      page: location?.state?.peluang?.filter?.page ?? "1",
      dataLength: location?.state?.peluang?.filter?.dataLength ?? "10",
      totalPage: location?.state?.peluang?.filter?.totalPage ?? "1",
      dataCount: location?.state?.peluang?.filter?.dataCount ?? "0",
    }
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({ show: false, data: {} });

  const getInitialData = () => {
    setIsPageLoading(true);

    RAEApi.getPageListPeluang({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_peluang_mulai: dataFilter?.filter?.tgl_peluang_mulai,
      tgl_peluang_selesai: dataFilter?.filter?.tgl_peluang_selesai,
      peringkat_peluang: dataFilter?.filter?.peringkat_peluang,
      customer: dataFilter?.filter?.customer,
      proyek: dataFilter?.filter?.proyek,
      unit_produksi: dataFilter?.filter?.unit_produksi,
    })
      .then(({ data }) => {
        setData(data.data);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data_count,
            totalPage: data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      },
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle("Unit Cost");
    // setNavbarTitle("Peluang Yang Siap Didaftarkan Menjadi RAE");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_peluang_mulai,
    dataFilter?.filter?.tgl_peluang_selesai,
    dataFilter?.filter?.peringkat_peluang,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.proyek,
    dataFilter?.filter?.unit_produksi,
  ]);

  const PageContent = () => {
    const BarangJadiCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {brg.nama_item}
                  </li>
                );
              } else {
                return (
                  index < 2 && (
                    <li key={index} index={index}>
                      {brg.nama_item}
                    </li>
                  )
                );
              }
            })}
          </ul>
          {data.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setisReadMoreClick((prev) => !prev)}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      );
    };
    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>Peluang yang siap dijadikan Unit Cost</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Peluang</ThFixed>
              <ThFixed>Peringkat Peluang</ThFixed>
              <Th>Customer</Th>
              <Th>Barang Jadi</Th>
              <Th>Unit Produksi</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                <TdFixed>
                  <div>{DateConvert(new Date(val.tgl_peluang)).defaultDMY}</div>
                  <div>{val.no_peluang}</div>
                </TdFixed>
                <Td className="text-nowrap">
                  {val.nama_peringkat_peluang ?? "-"}
                </Td>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>{val.barang_jadi && val.barang_jadi?.length > 0 ? <BarangJadiCollapse data={val.barang_jadi} /> : "-"}</Td>
                <Td>{val.nama_unit_produksi ?? "-"}</Td>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <ReadButton
                      className="my-1"
                      size="sm"
                      onClick={() => setModalConfig({ show: true, data: val })}
                    />
                    <ActionButton
                      size="sm"
                      variant="success"
                      className="my-1"
                      onClick={() =>
                        history.push(
                          `/transaksi/unit-cost/tambah/${val.id_peluang}`, { ...location?.state, peluang: dataFilter }
                        )
                      }
                    >
                      <IoAddOutline />
                    </ActionButton>
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          {/* <Row className="mb-2">
            <Col md={8}> */}
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
          {/* </Col>
          </Row> */}
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push("/transaksi/unit-cost", { ...location?.state, peluang: dataFilter })} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Modal */}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <PageContent />
      )}
      {modalConfig.show && (
        <ModalDetailPeluang
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      )}
    </CRUDLayout>
  );
};

export default PeluangRAE;
