import React, { useState, useEffect } from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import {
  CRUDLayout,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  Pagination,
  Alert,
  ApprovalStatusButton,
} from "components";
import {
  InputSearch,
  DataStatus,
  ButtonCreate,
  ButtonUpdate,
  ButtonFilter,
  ButtonDetail,
  Button,
} from "components2";
import { tableNumber } from "utilities2";
import {
  ModalFilter,
  ExportButton,
} from "./__LaporanRekapitulasiPendapatanComps__";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Formik } from "formik";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";
import LaporanRekapitulasiPendapatanApi from "./__LaporanRekapitulasiPendapatanApi__";
import FileSaver from "file-saver";

export const LaporanRekapitulasiPendapatanList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    text: "",
    variant: "primary",
  });

  const [filter, setFilter] = useState({
    q: "",
    page: 1,
    id_jenis: undefined,
    id_unit_produksi: undefined,
    bulan: "01",
    tahun: "2024",
    active: false,
  });

  const [modalFilter, setModalFilter] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const getProduksiAset = useQuery(["produksi_aset", "list", filter], () =>
    LaporanRekapitulasiPendapatanApi.getList(filter)
  );

  const searchHandler = (e) => {
    const searchKey = e.target.value ? e.target.value : undefined;

    setFilter({
      ...filter,
      q: searchKey,
    });
    setAlertConfig({
      show: searchKey ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + searchKey,
    });
  };

  const onFilterButtonClickkHandler = () => setModalFilter(!modalFilter);

  const checkAlert = () => {
    if (state) {
      if (state.alert) {
        setAlertConfig({
          show: state?.alert?.show,
          text: state?.alert?.text,
          variant: state?.alert?.variant,
        });
      }
    }
  };

  // FORM VALUES FILTER
  const formInitialValuesFilter = {
    id_jenis: filter?.id_jenis,
    id_unit_produksi: filter?.id_unit_produksi,
    bulan: filter?.bulan,
    tahun: filter?.tahun,
  };

  const formSubmitHandlerFilter = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setFilter({
      ...filter,
      ...values,
      active: checkActive,
      page: 1,
    });
    onFilterButtonClickkHandler();
  };

  const exportData = (tipe) => {
    setLoadingExport(true);

    LaporanRekapitulasiPendapatanApi.export({ ...filter, tipe })
      .then((res) => {
        const data = res.data.data;
        // const urlFile = process.env.REACT_APP_EXPORT_BASE_URL + data;
        FileSaver.saveAs(data, data);
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Export Data gagal",
        });
      })
      .finally(() => setLoadingExport(false));
  };

  const getTotal = () => {
    const data = getProduksiAset?.data?.data ?? [];
    console.log(data);

    const sumVolumeTotal = data?.reduce(
      (acc, curr) => acc + parseFloat(curr?.qty),
      0
    );

    const sumPersentaseTotal = data?.reduce(
      (acc, curr) => acc + parseFloat(curr?.persentase),
      0
    );
    const sumHargaTotal = data?.reduce(
      (acc, curr) => acc + parseFloat(curr?.harga),
      0
    );
    const sumTotal = data?.reduce((acc, curr) => {
      const sum = parseFloat(curr?.harga ?? 0) * parseFloat(curr?.qty ?? 0);
      return acc + parseFloat(sum);
    }, 0);

    return {
      volumeTotal: sumVolumeTotal ?? 0,
      persentaseTotal: sumPersentaseTotal ?? 0,
      hargaTotal: sumHargaTotal ?? 0,
      total: sumTotal ?? 0,
    };
  };

  useEffect(() => {
    setNavbarTitle("Laporan Rekapitulasi Pendapatan");
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={debounce(searchHandler, 700)}
              className="mr-2"
            />
            <ButtonFilter
              size="sm"
              className="text-nowrap mr-2"
              onClick={onFilterButtonClickkHandler}
              active={filter?.active}
            />
            <ExportButton loading={loadingExport}>
              <Dropdown.Item onClick={() => exportData("excel")}>
                Excel (.xlsx)
              </Dropdown.Item>
            </ExportButton>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection></CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {getProduksiAset.isFetching || getProduksiAset.isError ? (
        <DataStatus
          loading={getProduksiAset.isFetching}
          text={
            getProduksiAset.isFetching ? "Memuat . . ." : "Data gagal dimuat"
          }
        />
      ) : (
        <>
          <Alert
            show={alertConfig?.show}
            text={alertConfig?.text}
            variant={alertConfig?.variant}
            showCloseButton
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          <div className="mt-2">
            <small className="font-weight-bold">
              List Data Rekapitulasi Pendapatan (
              {DateConvert(new Date(filter?.bulan)).detailMonth} {filter?.tahun}
              )
            </small>
          </div>
          <Table>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <ThFixed>Kode Barang Jadi</ThFixed>
                <Th>Barang Jadi</Th>
                <Th>Jenis Barang Jadi</Th>
                <Th>Satuan</Th>
                <Th>Unit Produksi</Th>
                <Th>Volume</Th>
                <Th>Persentase</Th>
                <Th>Harga</Th>
                <Th>Total</Th>
              </Tr>
            </THead>
            <TBody>
              {getProduksiAset?.data?.data?.length > 0 ? (
                <>
                  {getProduksiAset?.data?.data?.map((e, index) => (
                    <>
                      <Tr key={index}>
                        <TdFixed>
                          {tableNumber({
                            pageCurrent: filter.page,
                            dataLength: getProduksiAset?.data?.data?.length,
                            index,
                          })}
                        </TdFixed>
                        <TdFixed>{e?.kode_item ?? "-"}</TdFixed>
                        <Td>{e?.nama_item ?? "-"}</Td>
                        <Td>{e?.nama_jenis ?? "-"}</Td>
                        <Td>{e?.satuan_pakai ?? "-"}</Td>
                        <Td>{e?.nama_unit_produksi ?? "-"}</Td>
                        <Td textRight>
                          {DecimalConvert(parseFloat(e?.qty ?? 0)).getWithComa}
                        </Td>
                        <Td textRight>
                          {
                            DecimalConvert(parseFloat(e?.persentase ?? 0))
                              .getWithComa
                          }
                          %
                        </Td>
                        <Td textRight>
                          {
                            RupiahConvert(parseFloat(e?.harga ?? 0).toString())
                              .getWithComa
                          }
                        </Td>
                        <Td textRight>
                          {
                            RupiahConvert(
                              String(
                                parseFloat(e?.harga ?? 0) *
                                  parseFloat(e?.qty ?? 0)
                              )
                            ).getWithComa
                          }
                        </Td>
                      </Tr>
                    </>
                  ))}
                  <Tr>
                    <Td
                      colSpan="6"
                      className="text-right font-weight-bold py-2"
                    >
                      TOTAL
                    </Td>
                    <Td className="text-right font-weight-bold py-2">
                      {DecimalConvert(getTotal()?.volumeTotal).getWithComa}
                    </Td>
                    <Td className="text-right font-weight-bold py-2">
                      {DecimalConvert(getTotal()?.persentaseTotal).getWithComa}%
                    </Td>
                    <Td className="text-right font-weight-bold py-2">
                      {
                        RupiahConvert(String(getTotal()?.hargaTotal))
                          .getWithComa
                      }
                    </Td>
                    <Td className="text-right font-weight-bold py-2">
                      {RupiahConvert(String(getTotal()?.total)).getWithComa}
                    </Td>
                  </Tr>
                </>
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
        </>
      )}

      {modalFilter && (
        <Formik
          enableReinitialize
          initialValues={formInitialValuesFilter}
          onSubmit={formSubmitHandlerFilter}
        >
          <ModalFilter toggle={onFilterButtonClickkHandler} />
        </Formik>
      )}
    </CRUDLayout>
  );
};
