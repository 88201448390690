// React
import React, { useState, useEffect } from "react";

// Form
import { Formik } from "formik";

// Components
import { DatePicker, FilterModal, SelectSearch } from "components";
import { DateConvert } from "utilities";

// API
import Axios from "axios";
import { TransferOverheadJobMixApi } from "api";

// View Functions
import { mappingDataItem } from "../../functions";

const ModalFilterProduksi = ({ toggle, data, setData, buaso }) => {
  // Variables
  const option = { label: "Semua Data", value: undefined };

  // States
  const [dropdownFilter, setDropdownFilter] = useState({
    [buaso]: [option],
    produksi: [option],
  });
  const [loading, setLoading] = useState(true);

  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    Axios.all([
      TransferOverheadJobMixApi.getDropdownItem(),
      TransferOverheadJobMixApi.getDropdownProduksi(),
    ])
      .then(
        Axios.spread((buasoData, produksi) => {
          // eslint-disable-next-line
          setDropdownFilter({
            [buaso]: [option, ...mappingDataItem(buasoData, "item_buaso")],
            produksi: [option, ...mappingDataItem(produksi, "item_buaso")],
          });
        })
      )
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line
  }, []);

  // FORM VALUES
  const formInitialValues = {
    tgl_jobmix_awal: data.tgl_jobmix_awal,
    tgl_jobmix_akhir: data.tgl_jobmix_akhir,
    id_item_jobmix: data.id_item_jobmix,
    id_item_diminta: data.id_item_diminta,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setData({
      ...data,
      ...values,
      active: checkActive,
      page: 1,
    });

    toggle();
  };

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    setLoading(true);

    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_jobmix_awal: undefined,
        tgl_jobmix_akhir: undefined,
        id_item_jobmix: undefined,
        id_item_diminta: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => setLoading(false));
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}>
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          isToggle
          toggleModal={toggle}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}>
          <DatePicker
            selectsRange
            label="Tgl. Produksi"
            placeholderText="Pilih Tgl. Produksi"
            startDate={
              values[`tgl_jobmix_awal`]
                ? new Date(values[`tgl_jobmix_awal`])
                : ""
            }
            endDate={
              values[`tgl_jobmix_akhir`]
                ? new Date(values[`tgl_jobmix_akhir`])
                : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                `tgl_jobmix_awal`,
                `tgl_jobmix_akhir`
              )
            }
            monthsShown={2}
          />

          <SelectSearch
            key="1"
            label="Item Produksi"
            placeholder="Pilih Item Produksi"
            defaultValue={dropdownFilter.produksi.find(
              (val) => val.value === values.id_item_jobmix
            )}
            option={dropdownFilter.produksi}
            onChange={(val) => setFieldValue("id_item_jobmix", val.value)}
            loading={loading}
          />

          <SelectSearch
            key="2"
            label={
              <div className="text-capitalize">Item {buaso} Yang Diminta</div>
            }
            placeholder={`Pilih Item ${buaso} Yang Diminta`}
            defaultValue={dropdownFilter[buaso].find(
              (val) => val.value === values.id_item_diminta
            )}
            option={dropdownFilter[buaso]}
            onChange={(val) => setFieldValue("id_item_diminta", val.value)}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
export default ModalFilterProduksi;
