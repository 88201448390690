import Services from "services";

class PostingrealisasiPPAApi {
  page(item, params) {
    return Services.get(`/posting_realisasi_ppa_${item}`, { params });
  }
  pageRealisasiPPA(params) {
    return Services.get("/posting_realisasi_ppa/realisasi_ppa", { params });
  }
  single(params) {
    return Services.get("/posting_realisasi_ppa/realisasi_ppa_single", {
      params,
    });
  }
  singlePosting(item, params) {
    return Services.get(`posting_realisasi_ppa_${item}/single`, { params });
  }
  dropdownCOA(params) {
    return Services.get("/posting_stock_opname/coa", { params });
  }
  dropdownItemMaterial() {
    return Services.get("/posting_realisasi_ppa_material/item");
  }
  dropdownItemManusia() {
    return Services.get("/posting_realisasi_ppa_manusia/item");
  }
  dropdownItemPeralatan() {
    return Services.get("/posting_realisasi_ppa_peralatan/item");
  }
  dropdownItemOverhead() {
    return Services.get("/posting_realisasi_ppa_overhead/item");
  }
  dropdownItemLainnya() {
    return Services.get("/posting_realisasi_ppa_lainnya/item");
  }
  create(item, value) {
    return Services.post(`/posting_realisasi_ppa_${item}`, value);
  }
  getKode(item, params) {
    return Services.get(`/posting_realisasi_ppa_${item}/nomor`, { params });
  }
  export(params, type) {
    return Services.get(`/posting_realisasi_ppa_${type}/export`, { params });
  }
}

export default new PostingrealisasiPPAApi();
