import Services from "services";

class HargaSatuanRataRataPakaiApi {
  page(params) {
    return Services.get("/harga_satuan_pakai/page", { params });
  }

  getHSRData(params) {
    return Services.get("harga_satuan_pakai/hsr", { params });
  }

  save(data) {
    return Services.post("/harga_satuan_pakai", data);
  }
}

export default new HargaSatuanRataRataPakaiApi();
