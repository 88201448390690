import React, { Fragment } from "react";
import { useQuery } from "react-query";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import { useFormikContext } from "formik";
import {
  Input,
  InfoItemHorizontal,
  ButtonBack,
  ButtonCreate,
} from "components2";
import {
  DatePicker,
  InfoItemVertical,
  TBody,
  THead,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  Tr,
} from "components";
import { TabKomponenProduksiAset } from "./TabKomponenProduksiAset";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DateConvert } from "utilities";

export const FormProduksiAsetContent = ({ action, setCurrentTab }) => {
  const { values } = useFormikContext();
  const history = useHistory();

  return (
    <Fragment>
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">
          {action === "create"
            ? "Tambah Data WIP Produksi Aset"
            : action === "update"
            ? "Ubah Data WIP Produksi Aset"
            : "Detail Data WIP Produksi Aset"}
        </small>

        <ButtonBack
          size="sm"
          onClick={() => history.push("/transaksi/wip-produksi-aset")}
        />
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col lg="6">
              <InfoItemHorizontal
                label="Tgl. Permintaan Aset"
                text={
                  values?.tgl_permintaan_aset
                    ? DateConvert(new Date(values?.tgl_permintaan_aset)).detail
                    : "-"
                }
              />
              <InfoItemHorizontal
                label="No. Permintaan Aset"
                text={values?.no_permintaan_aset ?? "-"}
              />
              <InfoItemHorizontal
                label="Tgl. Produksi Aset"
                text={
                  values?.tgl_produksi_aset
                    ? DateConvert(new Date(values?.tgl_produksi_aset)).detail
                    : "-"
                }
              />
              <InfoItemHorizontal
                label="No. Produksi Aset"
                text={values?.no_produksi_aset ?? "-"}
              />
            </Col>
            <Col lg="6">
              <InfoItemHorizontal
                label="Grup Aset"
                text={values?.nama_grup_aset ?? "-"}
              />
              <InfoItemHorizontal
                label="Kategori Aset"
                text={values?.nama_kategori_aset ?? "-"}
              />
              <InfoItemHorizontal
                label="Jenis Aset"
                text={values?.nama_jenis_aset ?? "-"}
              />
              <InfoItemHorizontal
                label="Item Aset Yang Diminta"
                text={values?.nama_aset ?? "-"}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <InfoItemVertical
                label="Tgl. Realisasi Produksi Aset"
                text={
                  values?.tgl_realisasi_produksi_aset
                    ? DateConvert(new Date(values?.tgl_realisasi_produksi_aset))
                        .detail
                    : "-"
                }
              />
            </Col>
            <Col>
              <InfoItemVertical
                label="No. Realisasi Produksi Aset"
                text={values?.no_realisasi_produksi_aset ?? "-"}
              />
            </Col>
          </Row>
          <hr />
          <TabKomponenProduksiAset
            action={action}
            section="realisasi"
            setCurrentTab={setCurrentTab}
          />
        </Card.Body>
      </Card>
    </Fragment>
  );
};
