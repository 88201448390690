import { Table, TBody, THead, Td, TdFixed, Th, ThFixed, Tr } from "components";
import React from "react";
import { RupiahConvert } from "utilities";

export const TableSummary = ({ data }) => {
  const getTotal = (sum) =>
    sum.reduce((acc, curr) => acc + curr.hargaSatuan * curr.qtyRealisasi, 0);

  const dataSummary = [
    { listBdp: "BDP Bahan", jumlah: getTotal(data?.bahan) },
    { listBdp: "BDP Upah", jumlah: getTotal(data?.upah) },
    { listBdp: "BDP Alat Mesin", jumlah: getTotal(data?.alatMesin) },
    { listBdp: "BDP Subkon", jumlah: getTotal(data?.subkon) },
    { listBdp: "BDP Overhead", jumlah: getTotal(data?.overhead) },
  ];

  const totalSummery = dataSummary.reduce((acc, curr) => acc + curr.jumlah, 0);

  return (
    <div className="mt-3">
      <b>Summary Proyeksi HPP</b>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>List BDP</Th>
            <Th>Jumlah</Th>
          </Tr>
        </THead>
        <TBody>
          {dataSummary.map((val, ind) => (
            <Tr>
              <TdFixed>{ind + 1}</TdFixed>
              <Td>{val?.listBdp}</Td>
              <Td className="text-right w-25">
                {RupiahConvert(parseFloat(val?.jumlah).toString()).getWithComa}
              </Td>
            </Tr>
          ))}
          <Tr>
            <Td colSpan="2" textRight>
              <b>Total Proyeksi HPP</b>
            </Td>
            <Td textRight>
              <b>
                {RupiahConvert(parseFloat(totalSummery).toString()).getWithComa}
              </b>
            </Td>
          </Tr>
        </TBody>
      </Table>
    </div>
  );
};
