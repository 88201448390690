import React from "react";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap"
import ReactSwitch from "react-switch";

const Switch = ({ 
  id, 
  checked, 
  onChange,
  className,
  wrapperClassName,
  ...rest
}) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip>
          {checked ? 'Aktif' : 'Tidak aktif'}
        </Tooltip>
      }
    >
      <div className={`d-flex align-items-center ${wrapperClassName}`}>
        <ReactSwitch
          {...rest}
          id={id}
          checked={checked}
          onChange={onChange}
          draggable={false}
          onColor="#3B82F6"
          className={className}
        />
      </div>
    </OverlayTrigger>
  );
};

export default Switch;
