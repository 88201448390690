import { InfoItemHorizontal } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

export const InfoKegiatanSection = () => {
  const { values } = useFormikContext();

  return (
    <>
      <Row>
        <Col lg="4">
          <InfoItemHorizontal
            label="Tgl. Kegiatan"
            text={
              values?.kegiatan?.tglKegiatan
                ? DateConvert(new Date(values?.kegiatan?.tglKegiatan)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Kegiatan"
            text={values?.kegiatan?.noKegiatan ?? "-"}
          />
          <InfoItemHorizontal
            label="Nama Kegiatan"
            text={values?.kegiatan?.namaKegiatan ?? "-"}
          />
          <InfoItemHorizontal
            label="Penanggung Jawab"
            text={values?.kegiatan?.namaPenanggungJawabKegiatan ?? "-"}
          />
        </Col>
        <Col lg="4">
          <InfoItemHorizontal
            label="Periode Mulai"
            text={
              values?.kegiatan?.periodeMulaiKegiatan
                ? DateConvert(new Date(values?.kegiatan?.periodeMulaiKegiatan))
                    .detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Periode Selesai"
            text={
              values?.kegiatan?.periodeSelesaiKegiatan
                ? DateConvert(
                    new Date(values?.kegiatan?.periodeSelesaiKegiatan)
                  ).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Lama Periode"
            text={`${values?.kegiatan?.lamaPeriodeKegiatan ?? "-"} Hari`}
          />
          <InfoItemHorizontal
            label="Deskripsi"
            text={values?.kegiatan?.deskripsiKegiatan ?? "-"}
          />
        </Col>
        <Col lg="4">
          <InfoItemHorizontal
            label="Jenis Anggaran"
            text={values?.kegiatan?.namaJenisAnggaran ?? "-"}
          />
          <InfoItemHorizontal
            label="Kelompok Anggaran"
            text={values?.kegiatan?.namaKelompokAnggaran ?? "-"}
          />
          <InfoItemHorizontal
            label="Sub Kelompok Anggaran"
            text={values?.kegiatan?.namaSubKelompokAnggaran ?? "-"}
          />
        </Col>
      </Row>
    </>
  );
};
