import { Fragment, useState } from "react";
import { ButtonGroup } from "react-bootstrap";
import { Formik, useFormikContext } from "formik";
import { DataStatus, Table, Td, Th, ThFixed } from "components";
import { ButtonCreate, ButtonUpdate } from "components2";
import { rupiahConvert } from "utilities2";
import * as Yup from "yup";
import { ModalFormRincian } from "./ModalFormRincian";
import { filterPosting } from "../__PostingPettyCashUtils__";

export const TableSection = ({ action = "", data = [] }) => {
  const { values, setValues } = useFormikContext();
  const [modal, setModal] = useState({
    show: false,
    type: "UPDATE", // CREATE |  UPDATE
    data: { index: null },
  });

  const isActionConfirm = action === "CONFIRM";

  const isCreate = modal.type === "CREATE";

  const petty_cash = values.nominal_penerimaan_petty_cash;
  let saldo_petty_cash = petty_cash;

  const initialValuesRincian = {
    id_realisasi_petty_cash_detail: modal.data.id_realisasi_petty_cash_detail,
    tgl_posting_petty_cash: isCreate ? "" : modal.data.tgl_posting_petty_cash,
    no_posting_petty_cash: isCreate ? "" : modal.data.no_posting_petty_cash,
    nomor_akun_debet: isCreate ? "" : modal.data.nomor_akun_debet,
    nama_akun_debet: isCreate ? "" : modal.data.nama_akun_debet,
    nomor_akun_kredit: isCreate ? "" : modal.data.nomor_akun_kredit,
    nama_akun_kredit: isCreate ? "" : modal.data.nama_akun_kredit,

    nama_item: modal.data.nama_item,
    qty: modal.data.qty,
    harga_satuan: modal.data.harga_satuan,
    id_satuan: modal.data.id_satuan,
    nama_satuan: modal.data.nama_satuan,
    keterangan: modal.data.keterangan ?? "",
    files: modal.data?.files ?? [],
  };

  const validationSchemaRincian = Yup.object().shape({
    tgl_posting_petty_cash: Yup.string().required("Pilih Tgl. Posting"),
    nomor_akun_debet: Yup.string().required("Pilih COA Debet"),
    nomor_akun_kredit: Yup.string().required("Pilih COA Kredit"),
    keterangan: Yup.string().required("Masukkan Keterangan"),
  });

  const onSubmitDetail = (val, { resetForm }) => {
    const finalValue = { ...val, isPosting: true };
    const updatedDetail = [...values.detail];
    updatedDetail[modal.data.index] = finalValue;

    setValues((prev) => ({ ...prev, detail: updatedDetail }));
    setModal({ show: false, type: "CREATE", data: {} });
    resetForm();
  };

  const calcJumlah = () =>
    data?.reduce(
      (result, { qty, harga_satuan }) => {
        const itemJumlah = Math.round(qty ?? 0) * parseFloat(harga_satuan ?? 0);

        return {
          harga_satuan:
            parseFloat(result.harga_satuan) + parseFloat(harga_satuan),
          jumlah: result.jumlah + itemJumlah,
          saldo_petty_cash: result.saldo_petty_cash - itemJumlah,
        };
      },
      {
        harga_satuan: 0,
        jumlah: 0,
        saldo_petty_cash: petty_cash,
      }
    );

  return (
    <Fragment>
      {!isActionConfirm && (
        <Fragment>
          <div className="font-weight-bold mt-2 mb-1">
            List Data Rincian Petty Cash
          </div>

          <Table>
            <thead>
              <tr>
                <ThFixed>No.</ThFixed>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Item
                </Th>
                <Th style={{ minWidth: 10 }} className="p-1">
                  Qty.
                </Th>
                <Th style={{ minWidth: 100 }} className="p-1">
                  Satuan
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Keterangan
                </Th>
                <Th style={{ width: 90 }} className="p-1">
                  Harga
                </Th>
                <Th style={{ width: 90 }} className="p-1">
                  Jumlah
                </Th>
                {/* <Th style={{ width: 90 }} className="p-1">
                  Saldo Petty Cash
                </Th> */}

                <ThFixed rowSpan={2}>Aksi</ThFixed>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <Td textRight colSpan={6} className="font-weight-bold">
                  Saldo Petty Cash
                </Td>
                <Td textRight className="font-weight-bold">
                  {rupiahConvert().getWithComa(String(saldo_petty_cash))}
                </Td>
                <Td>-</Td>
                <Td></Td>
              </tr> */}

              {data?.map((e, index) => {
                const isPosting = e.isPosting;
                const jumlah =
                  Math.round(e.qty ?? 0) * Math.round(e.harga_satuan);
                const item_saldo = saldo_petty_cash - jumlah;
                saldo_petty_cash = item_saldo;

                return (
                  <tr key={e.id_realisasi_petty_cash_detail}>
                    <Td>{index + 1}</Td>
                    <Td>{e.nama_item}</Td>
                    <Td textRight>{e.qty}</Td>
                    <Td>{e.nama_satuan}</Td>
                    <Td>{e.keterangan}</Td>
                    <Td textRight>
                      {rupiahConvert().getWithComa(String(e.harga_satuan))}
                    </Td>
                    <Td textRight>
                      {rupiahConvert().getWithComa(String(jumlah))}
                    </Td>
                    {/* <Td textRight>
                      {rupiahConvert().getWithComa(String(item_saldo))}
                    </Td> */}

                    <Td>
                      <ButtonGroup>
                        {!isPosting && (
                          <ButtonCreate
                            icon
                            noText
                            variant="primary"
                            onClick={() =>
                              setModal({
                                show: true,
                                type: "CREATE",
                                data: { ...e, index },
                              })
                            }
                          />
                        )}
                      </ButtonGroup>
                    </Td>
                  </tr>
                );
              })}

              {/* <tr>
                <Td colSpan={4} className="font-weight-bold text-right">
                  Jumlah
                </Td>
                <Td textRight className="font-weight-bold">
                  {rupiahConvert().getWithComa(
                    String(calcJumlah().harga_satuan)
                  )}
                </Td>
                <Td textRight className="font-weight-bold">
                  {rupiahConvert().getWithComa(String(calcJumlah().jumlah))}
                </Td>
                <Td textRight className="font-weight-bold">
                  {rupiahConvert().getWithComa(
                    String(calcJumlah().saldo_petty_cash)
                  )}
                </Td>
                <Td colSpan={2}></Td>
              </tr> */}

              <tr>
                <Td colSpan={6} className="font-weight-bold text-right">
                  Saldo Petty Cash
                </Td>
                <Td className="font-weight-bold text-right">
                  {rupiahConvert().getWithComa(String(saldo_petty_cash))}
                </Td>
              </tr>
              <tr>
                <Td colSpan={6} className="font-weight-bold text-right">
                  Akumulasi Rincian Petty Cash
                </Td>
                <Td className="font-weight-bold text-right">
                  {rupiahConvert().getWithComa(String(calcJumlah().jumlah))}
                </Td>
              </tr>
              <tr>
                <Td colSpan={6} className="font-weight-bold text-right">
                  Sisa Saldo Petty Cash
                </Td>
                <Td className="font-weight-bold text-right">
                  {rupiahConvert().getWithComa(
                    String(calcJumlah().saldo_petty_cash)
                  )}
                </Td>
              </tr>
            </tbody>
          </Table>
        </Fragment>
      )}

      <div className="font-weight-bold mt-2 mb-1">
        List Data Posting Petty Cash
      </div>
      <Table>
        <thead>
          <tr>
            <ThFixed>No.</ThFixed>
            <Th style={{ width: 280 }} className="p-1">
              Item
            </Th>
            <Th style={{ width: 90 }} className="p-1">
              Jumlah
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              COA Debet
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              COA Kredit
            </Th>
            <Th style={{ width: 150 }} className="p-1">
              Keterangan
            </Th>
            {!isActionConfirm && <ThFixed rowSpan={2}>Aksi</ThFixed>}
          </tr>
        </thead>
        <tbody>
          {filterPosting({ data: data ?? [] })?.length > 0 ? (
            filterPosting({ data: data ?? [] })?.map((e, index) => {
              const jumlah =
                Math.round(e.qty ?? 0) * Math.round(e.harga_satuan);

              return (
                <tr key={e.id_realisasi_petty_cash_detail}>
                  <Td>{index + 1}</Td>
                  <Td>{e.nama_item}</Td>
                  <Td textRight>
                    {rupiahConvert().getWithComa(String(jumlah))}
                  </Td>
                  <Td>{e.nama_akun_debet}</Td>
                  <Td>{e.nama_akun_kredit}</Td>
                  <Td>{e.keterangan}</Td>
                  {!isActionConfirm && (
                    <Td>
                      <ButtonGroup>
                        <ButtonUpdate
                          icon
                          noText
                          variant="success"
                          onClick={() =>
                            setModal({
                              show: true,
                              type: "UPDATE",
                              data: { ...e, index },
                            })
                          }
                        />
                      </ButtonGroup>
                    </Td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={7}>
                <DataStatus text="Tidak ada data" />
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Formik
        enableReinitialize
        initialValues={initialValuesRincian}
        validationSchema={validationSchemaRincian}
        onSubmit={onSubmitDetail}
      >
        <ModalFormRincian
          show={modal.show}
          type={modal.type}
          onHide={() => setModal({ show: false, type: "CREATE", data: {} })}
          tableData={data}
        />
      </Formik>
    </Fragment>
  );
};
