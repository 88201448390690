import Services from "services";

class PostingStockOpnameApi {
  page(params) {
    return Services.get("/posting_stock_opname/page", { params });
  }
  pageStockOpname(params) {
    return Services.get("/posting_stock_opname/list_stock_opname", { params });
  }
  single(params) {
    return Services.get("/posting_stock_opname/single", { params });
  }
  singleStockOpname(params) {
    return Services.get("/posting_stock_opname/list_stock_opname_single", {
      params,
    });
  }
  dropdownCOA(params) {
    return Services.get("/posting_stock_opname/coa", { params });
  }
  dropdownItemBarang() {
    return Services.get("/posting_stock_opname/bahan");
  }
  dropdownUnitProduksi() {
    return Services.get("/posting_stock_opname/unit_produksi");
  }
  dropdownGudang(params) {
    return Services.get("/posting_stock_opname/gudang", { params });
  }
  getNomor(params) {
    return Services.get("/posting_stock_opname/no_baru", { params });
  }
  create(value) {
    return Services.post("/posting_stock_opname", value);
  }
  export(params) {
    return Services.get("/posting_stock_opname/export/", { params });
  }
}

export default new PostingStockOpnameApi();
