// React
import { useEffect, useState } from "react";

// API
import axios from "axios";
import { TransferOverheadJobMixApi } from "api";

// Components
import {
  ActionButton,
  Alert,
  DataStatus,
  DatePicker,
  Input,
  TextArea,
} from "components";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

// Form
import { Formik } from "formik";
import * as yup from "yup";

// View Components
import { FormSection, InfoSection } from "../sections";

// View Functions
import { mappingData } from "../../functions";
import { useHistory, useLocation } from "react-router-dom";
import TableSection from "../sections/TableSection";
import { setWith } from "lodash";

const TransferModal = ({
  data,
  dataPermintaan,
  toggle,
  isCreate,
  title,
  toggleSuccess,
}) => {
  const location = useLocation();
  const history = useHistory();

  // States
  const [modal, setModal] = useState({
    loading: false,
    status: true,
  });
  const [dataForm, setDataForm] = useState({});
  const [dataSaving, setdataSaving] = useState({
    tgl_transfer_produksi_overhead: "",
    keterangan_transfer: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  useEffect(() => {
    if (isCreate) {
      // Variables
      const tanggal = DateConvert(new Date()).default;

      setModal({
        loading: true,
        status: false,
      });

      axios
        .all([TransferOverheadJobMixApi.no_baru(tanggal)])
        .then(
          axios.spread((no) => {
            setDataForm({
              no: no.data.data,
              tanggal,
            });

            setModal({
              loading: false,
              status: true,
            });
          })
        )
        .catch(() => {
          setModal({
            loading: false,
            status: false,
          });
        });
    }

    // eslint-disable-next-line
  }, []);

  const handleRetry = async (ind) => {
    const finalValues = {
      ...dataSaving,
      ...data[ind],
      qty_transfer: data[ind]?.qty_permintaan_produksi,
    };

    await createPost(finalValues, ind);
  };

  const createPost = async (post, ind) => {
    try {
      // code to create the post using post data
      await TransferOverheadJobMixApi.save(post).then((val) => {});
    } catch (err) {
      handleRetry(ind);
    }
  };

  const handleSubmit = async (value) => {
    Promise.all(
      data.map((val, ind) => {
        const finalvalues = {
          ...value,
          ...val,
          qty_transfer: val.qty_permintaan_produksi,
        };

        createPost(finalvalues, ind);
      })
    ).then(() =>
      history.push(
        "/transaksi/transfer-overhead-job-mix/list-permintaan-overhead",
        location.state
      )
    );
  };

  const filterDataPermintaan =
    isCreate &&
    dataPermintaan.filter(
      (val) =>
        parseFloat(val?.qty_transfer ?? 0) <
        parseFloat(val?.qty_permintaan_produksi ?? 0)
    );

  return (
    <Modal show={true} onHide={toggle} scrollable>
      <Modal.Header closeButton>
        <Modal.Title>
          <h6 className="m-0">
            {isCreate ? "Tambah" : "Detail"} Data {title}
          </h6>
        </Modal.Title>
      </Modal.Header>

      {modal.loading || !modal.status ? (
        <Modal.Body>
          <DataStatus
            loading={modal.loading}
            text={
              modal.loading
                ? "Memuat data..."
                : "Data gagal dimuat, tidak dapat menampilkan data"
            }
          />
        </Modal.Body>
      ) : (
        <Formik
          initialValues={{
            tgl_transfer_produksi_overhead: isCreate
              ? undefined
              : data.tgl_transfer_produksi_overhead,
            keterangan_transfer: isCreate ? "" : data.keterangan_transfer,
          }}
          validationSchema={yup.object().shape({
            tgl_transfer_produksi_overhead: yup
              .date()
              .typeError("Masukkan Tanggal")
              .required("Masukkan Tanggal"),
            // qty_transfer: yup
            //   .number()
            //   .max(
            //     parseFloat(data.qty_permintaan_produksi ?? 0) -
            //       parseFloat(data.qty_transfer ?? 0),
            //     `Qty. Transfer wajib diisi maksimal ${
            //       parseFloat(data.qty_permintaan_produksi ?? 0) -
            //       parseFloat(data.qty_transfer ?? 0)
            //     }`
            //   )
            //   .required("Qty. Transfer wajib diisi"),
          })}
          onSubmit={handleSubmit}
        >
          {({
            handleSubmit,
            isSubmitting,
            values,
            setFieldValue,
            errors,
            touched,
            handleChange,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body
                style={{
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
              >
                <Alert
                  show={alertConfig.show}
                  variant={alertConfig.variant}
                  text={alertConfig.text}
                  showCloseButton={true}
                  onClose={() => {
                    setAlertConfig({
                      ...alertConfig,
                      show: false,
                    });
                  }}
                />

                {isCreate && (
                  <>
                    <DatePicker
                      label="Tgl. Transfer Overhead Produksi"
                      selected={
                        values.tgl_transfer_produksi_overhead
                          ? new Date(values.tgl_transfer_produksi_overhead)
                          : ""
                      }
                      placeholderText="Pilih Tgl. Transfer Overhead Produksi"
                      onChange={(date) => {
                        const tanggal = DateConvert(new Date(date)).default;

                        setFieldValue(
                          "tgl_transfer_produksi_overhead",
                          tanggal
                        );
                        setdataSaving({
                          ...dataSaving,
                          tgl_transfer_produksi_overhead: tanggal,
                        });
                        // getDataNomor(tanggal);
                      }}
                      error={
                        Boolean(errors.tgl_transfer_produksi_overhead) &&
                        touched.tgl_transfer_produksi_overhead
                      }
                      errorText={
                        Boolean(errors.tgl_transfer_produksi_overhead) &&
                        touched.tgl_transfer_produksi_overhead &&
                        errors.tgl_transfer_produksi_overhead
                      }
                    />
                    <TextArea
                      label="Keterangan Transfer"
                      name="keterangan_transfer"
                      rows={4}
                      placeholder="Keterangan Transfer"
                      value={values.keterangan_transfer}
                      onChange={(e) => {
                        setFieldValue("keterangan_transfer", e.target.value);
                        setdataSaving({
                          ...dataSaving,
                          keterangan_transfer: e.target.value,
                        });
                      }}
                      error={
                        Boolean(errors.keterangan_transfer) &&
                        touched.keterangan_transfer
                      }
                      errorText={
                        Boolean(errors.keterangan_transfer) &&
                        touched.keterangan_transfer &&
                        errors.keterangan_transfer
                      }
                    />
                    <TableSection data={data} />
                  </>
                )}
                {!isCreate && (
                  <>
                    <InfoSection data={data} />
                    <hr />
                    <FormSection
                      buaso="overhead"
                      readOnly={!isCreate}
                      dropdown={{}}
                      data={data}
                    />
                  </>
                )}
              </Modal.Body>

              {isCreate && (
                <Modal.Footer>
                  <ActionButton
                    type="submit"
                    text="Simpan"
                    loading={isSubmitting}
                  />
                </Modal.Footer>
              )}
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default TransferModal;
