import Services from 'services'

class AnalisaBarangJadiApi {
    page(value) {
        return Services.get(`/analisa_barang_jadi/page`, { params: { ...value } });
    }
    page_jobmix(value) {
        return Services.get(`/analisa_barang_jadi/list_jobmix_design`, { params: { ...value } });
    }
    dropdown(value) {
        return Services.get(`/analisa_barang_jadi/dropdown`, { params: { ...value } });
    }
    dropdown_bok() {
        return Services.get(`/analisa_barang_jadi/dropdown/item_bok`);
    }
    single(value) {
        return Services.get(`/analisa_barang_jadi/single`, { params: { ...value } });
    }
    getAnalisa(params) {
        return Services.get(`analisa_barang_jadi/salin_analisa`, { params });
    }
    no_baru() {
        return Services.get(`/analisa_barang_jadi/no_baru`);
    }
    create(value) {
        return Services.post(`/analisa_barang_jadi`, value);
    }
    update(value) {
        return Services.put(`/analisa_barang_jadi`, value);
    }
    delete(value) {
        return Services.post(`/analisa_barang_jadi/delete`, value);
    }
    show(value) {
        return Services.put(`/analisa_barang_jadi/show`, value);
    }
    hide(value) {
        return Services.put(`/analisa_barang_jadi/hide`, value);
    }
    create_analisa(value) {
        return Services.post(`/analisa_barang_jadi/analisa`, value);
    }
    update_analisa(value) {
        return Services.put(`/analisa_barang_jadi/analisa`, value);
    }
    analisa_delete(value) {
        return Services.post(`/analisa_barang_jadi/analisa_delete`, value);
    }
    single_analisa(value) {
        return Services.get(`/analisa_barang_jadi/single_analisa`, { params: { ...value } });
    }
    upload(value) {
        return Services.post(`/upload/barang_jadi_multiple`, value);
    }
    cek_nama(value) {
        return Services.get(`/analisa_barang_jadi/cek_nama`, { params: { ...value } });
    }

    singleJobMix(params) {
        return Services.get("/analisa_barang_jadi/single_list_jobmix_design", { params })
    }

    no_baru(params) { 
        return Services.get("/jobmix_design/no_baru", { params })
    }
}

export default new AnalisaBarangJadiApi();