import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IoAddOutline, IoEyeOutline } from "react-icons/io5";
import { debounce } from "lodash";
import {
  CRUDLayout,
  InputSearch,
  Alert,
  Table,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  DataStatus,
  Pagination,
  ActionButton,
  BackButton,
  FilterButton,
} from "components";
import {
  DateConvert,
  DecimalConvert,
  RupiahConvert,
  TableNumber,
} from "utilities";
import { BDPProduksiApi } from "api";
import { ModalFilter, RealisasiProduksiDetail } from "./Comps";
import { Modal } from "react-bootstrap";

export const BDPProduksiRealisasiList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paging = location?.state?.pagination ?? {};
  console.log(paging);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState(undefined);
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    error: true,
  });
  const [alert, setAlert] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [pagination, setPagination] = useState({
    page: paging?.post?.page ?? 1,
    dataLength: paging?.post?.dataLength ?? 10,
    totalPage: paging?.post?.totalPage ?? 1,
    dataCount: paging?.post?.dataCount ?? 0,
  });
  const [modal, setModal] = useState({
    show: false,
    id: null,
  });

  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    active: false,
    tgl_realisasi_produksi_mulai:
      paging?.post?.tgl_realisasi_produksi_mulai ?? undefined,
    tgl_realisasi_produksi_selesai:
      paging?.post?.tgl_realisasi_produksi_selesai ?? undefined,
    tgl_jobmix_mulai: paging?.post?.tgl_jobmix_mulai ?? undefined,
    tgl_jobmix_selesai: paging?.post?.tgl_jobmix_selesai ?? undefined,
    id_item_buaso: paging?.post?.id_item_buaso ?? undefined,
  });

  const getInitialData = () => {
    setData([]);
    setFetchingStatus({
      loading: true,
      error: true,
    });

    BDPProduksiApi.getRealisasi({
      page: pagination.page,
      per_page: pagination.dataLength,
      tgl_realisasi_produksi_mulai: dataFilter.tgl_realisasi_produksi_mulai,
      tgl_realisasi_produksi_selesai: dataFilter.tgl_realisasi_produksi_selesai,
      tgl_jobmix_mulai: dataFilter.tgl_jobmix_mulai,
      tgl_jobmix_selesai: dataFilter.tgl_jobmix_selesai,
      id_item_buaso: dataFilter.id_item_buaso,
      q: search,
    })
      .then((res) => {
        setData(res?.data?.data ?? []);
        setPagination({
          ...pagination,
          dataCount: res?.data?.data_count,
          totalPage: res?.data?.total_page,
        });
        setFetchingStatus({
          loading: false,
          error: false,
        });
      })
      .catch(() => {
        setData([]);
        setFetchingStatus({
          loading: false,
          error: true,
        });
      });
  };

  const searchHandler = (e) => {
    setSearch(e.target.value);
  };

  const toggleModalFilter = () => setModalFilter(!modalFilter);

  useEffect(() => {
    setNavbarTitle("BDP Produksi");
    setAlert({
      show: !!history?.location?.state?.alert?.show,
      variant: history?.location?.state?.alert?.variant,
      text: history?.location?.state?.alert?.text,
    });
  }, []);

  useEffect(() => {
    getInitialData();
  }, [
    search,
    pagination.page,
    pagination.dataLength,
    dataFilter.tgl_jobmix_mulai,
    dataFilter.tgl_jobmix_selesai,
    dataFilter.tgl_realisasi_produksi_mulai,
    dataFilter.tgl_realisasi_produksi_selesai,
  ]);

  return (
    <CRUDLayout>
      {/* Head */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-0">
            <InputSearch onChange={debounce(searchHandler, 800)} />
            <FilterButton
              active={dataFilter?.active}
              onClick={toggleModalFilter}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton
            onClick={() =>
              history.push("/transaksi/bdp", {
                pagination: {
                  ...paging,
                  post: pagination,
                },
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert show={alert.show} variant={alert.variant} text={alert.text} />

      {/* Content */}
      {fetchingStatus.loading || fetchingStatus.error ? (
        <DataStatus
          loading={fetchingStatus.loading}
          text={fetchingStatus.loading ? "Memuat . . ." : "Data gagal dimuat"}
        />
      ) : (
        <>
          <div classNameundefined="p-1">
            <b>List Data Realisasi Produksi</b>
          </div>
          <Table responsive>
            <THead>
              <Tr>
                <ThFixed>No.</ThFixed>
                <Th style={{ width: 150 }} className="p-1">
                  Informasi Realisasi Produksi
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Informasi Produksi
                </Th>
                <Th style={{ minWidth: 300 }} className="p-1">
                  Customer
                </Th>
                <Th style={{ minWidth: 300 }} className="p-1">
                  Item Produksi
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Qty. Produksi
                </Th>
                <Th style={{ width: 150 }} className="p-1">
                  Qty. Realisasi Produksi
                </Th>
                <Th style={{ width: 50 }} className="p-1">
                  Aksi
                </Th>
              </Tr>
            </THead>
            <TBody>
              {data && data?.length > 0 ? (
                data.map((e, i) => (
                  <Tr key={i}>
                    <TdFixed textCenter>
                      {TableNumber(pagination.page, pagination.dataLength, i)}
                    </TdFixed>
                    <Td>
                      <span className="d-block">
                        {e?.tgl_realisasi_produksi
                          ? DateConvert(new Date(e.tgl_realisasi_produksi))
                              .defaultDMY
                          : "-"}
                      </span>
                      <span className="d-block">
                        {e?.no_realisasi_produksi ?? "-"}
                      </span>
                    </Td>
                    <Td>
                      <span className="d-block">
                        {e?.tgl_jobmix
                          ? DateConvert(new Date(e.tgl_jobmix)).defaultDMY
                          : "-"}
                      </span>
                      <span className="d-block">{e?.no_jobmix ?? "-"}</span>
                    </Td>
                    <Td>{e?.nama_customer ?? "-"}</Td>
                    <Td>{e?.nama_item ?? "-"}</Td>
                    <Td>
                      {e?.qty_jobmix
                        ? DecimalConvert(e.qty_jobmix).getWithComa
                        : "-"}{" "}
                      {e?.nama_satuan ?? ""}
                    </Td>
                    <Td>
                      {e?.qty_realisasi_produksi
                        ? DecimalConvert(e.qty_realisasi_produksi).getWithComa
                        : 0}{" "}
                      {e?.nama_satuan ?? ""}
                    </Td>
                    <Td className="text-center">
                      <div className="d-flex">
                        <ActionButton
                          size="sm"
                          text={<IoEyeOutline />}
                          className="m-1"
                          onClick={() =>
                            setModal({
                              show: true,
                              id: e?.id_realisasi_produksi,
                            })
                          }
                        />
                        <ActionButton
                          size="sm"
                          text={<IoAddOutline />}
                          className="m-1"
                          onClick={() =>
                            history.push(
                              "/transaksi/bdp/tambah/" +
                                e?.id_realisasi_produksi,
                              {
                                pagination: {
                                  ...paging,
                                  post: { ...pagination, ...dataFilter },
                                },
                              }
                            )
                          }
                        />
                      </div>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={11}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </Tr>
              )}
            </TBody>
          </Table>
          <Pagination
            dataLength={pagination?.dataLength}
            dataNumber={
              pagination?.page * pagination?.dataLength -
              pagination?.dataLength +
              1
            }
            dataPage={
              pagination?.dataCount < pagination?.dataLength
                ? pagination?.dataCount
                : pagination?.page * pagination?.dataLength
            }
            dataCount={pagination?.dataCount}
            currentPage={pagination?.page}
            totalPage={pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setPagination({
                ...pagination,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPagination({
                ...pagination,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />

          {modalFilter && (
            <ModalFilter
              toggle={toggleModalFilter}
              data={dataFilter}
              setData={setDataFilter}
              buaso="subkon"
            />
          )}

          {/* Modal Detail Realisasi */}
          <Modal
            show={modal.show}
            size="xl"
            onHide={() => setModal({ show: false, id: "" })}
          >
            <Modal.Header closeButton>
              <b>Detail Realisasi Produksi</b>
            </Modal.Header>
            <Modal.Body>
              <RealisasiProduksiDetail id={modal.id} />
            </Modal.Body>
          </Modal>
        </>
      )}
    </CRUDLayout>
  );
};
