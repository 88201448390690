import { InfoItemHorizontal } from "components";
import { useFormikContext } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { DateConvert } from "utilities";

export const InfoSubKegiatanSection = () => {
  const { values } = useFormikContext();

  return (
    <>
      <Row>
        <Col lg="6">
          <InfoItemHorizontal
            label="Tgl. Sub Kegiatan"
            text={
              values?.subKegiatan?.tglSubKegiatan
                ? DateConvert(new Date(values?.subKegiatan?.tglSubKegiatan))
                    .detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Sub Kegiatan"
            text={values?.subKegiatan?.noSubKegiatan ?? "-"}
          />
          <InfoItemHorizontal
            label="Nama Kegiatan"
            text={values?.subKegiatan?.namaSubKegiatan ?? "-"}
          />
          <InfoItemHorizontal
            label="Penanggung Jawab"
            text={values?.subKegiatan?.namaPenanggungJawabSubKegiatan ?? "-"}
          />
        </Col>
        <Col lg="6">
          <InfoItemHorizontal
            label="Periode Mulai"
            text={
              values?.subKegiatan?.periodeMulaiSubKegiatan
                ? DateConvert(
                    new Date(values?.subKegiatan?.periodeMulaiSubKegiatan)
                  ).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Periode Selesai"
            text={
              values?.subKegiatan?.periodeSelesaiSubKegiatan
                ? DateConvert(
                    new Date(values?.subKegiatan?.periodeSelesaiSubKegiatan)
                  ).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="Lama Periode"
            text={`${values?.subKegiatan?.lamaPeriodeSubKegiatan ?? "-"} Hari`}
          />
          <InfoItemHorizontal
            label="Deskripsi"
            text={values?.subKegiatan?.deskripsiSubKegiatan ?? "-"}
          />
        </Col>
      </Row>
    </>
  );
};
