import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Row, Col, Card } from "react-bootstrap";
import { useFormikContext } from "formik";
import {
  Input,
  InfoItemHorizontal,
  ButtonBack,
  ButtonCreate,
} from "components2";
import {
  InfoItemVertical,
  TBody,
  THead,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  Tr,
} from "components";
import { TabKomponenProduksiAset } from "./TabKomponenProduksiAset";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DateConvert, RupiahConvert } from "utilities";
import { ModalForm } from "./ModalForm";

const SummeryBuaso = () => {
  const { values } = useFormikContext();

  const mappingDataSummary = [
    { grup_buaso: "Bahan", sub_total: values?.summary?.bahan },
    { grup_buaso: "Upah", sub_total: values?.summary?.upah },
    { grup_buaso: "Alat Mesin", sub_total: values?.summary?.alat_mesin },
    { grup_buaso: "Subkon", sub_total: values?.summary?.subkon },
    { grup_buaso: "Overhead", sub_total: values?.summary?.overhead },
  ];

  const getTotal = mappingDataSummary?.reduce((acc, curr) => {
    return acc + parseFloat(curr.sub_total ?? 0);
  }, 0);

  return (
    <Table>
      <THead>
        <Tr>
          <ThFixed>No.</ThFixed>
          <Th>Grup WIP</Th>
          <Th>Total</Th>
        </Tr>
      </THead>
      <TBody>
        {mappingDataSummary?.map((val, ind) => (
          <Tr>
            <TdFixed>{ind + 1}</TdFixed>
            <Td>{val?.grup_buaso ?? "-"}</Td>
            <Td className="text-right">
              {
                RupiahConvert(parseFloat(val?.sub_total ?? 0).toString())
                  .getWithComa
              }
            </Td>
          </Tr>
        ))}
        <Tr>
          <Td className="text-right font-weight-bold" colSpan={2}>
            Total
          </Td>
          <Td className="text-right font-weight-bold">
            {RupiahConvert(parseFloat(getTotal ?? 0).toString()).getWithComa}
          </Td>
        </Tr>
      </TBody>
    </Table>
  );
};

export const FormProduksiAsetContent = ({ action }) => {
  const [modalFormConfig, setModalFormConfig] = useState({ show: false });
  const { values } = useFormikContext();
  const history = useHistory();

  return (
    <Fragment>
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">
          {action === "create"
            ? "Tambah Data Nilai Perolehan Produksi Aset"
            : action === "update"
            ? "Ubah Data Nilai Perolehan Produksi Aset"
            : "Detail Data Nilai Perolehan Produksi Aset"}
        </small>

        <ButtonBack
          size="sm"
          onClick={() =>
            history.push("/transaksi/nilai-perolehan-produksi-aset")
          }
        />
      </div>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col lg="6">
              <InfoItemHorizontal
                label="Tgl. Permintaan Aset"
                text={
                  values?.tgl_permintaan_aset
                    ? DateConvert(new Date(values?.tgl_permintaan_aset)).detail
                    : "-"
                }
              />
              <InfoItemHorizontal
                label="No. Permintaan Aset"
                text={values?.no_permintaan_aset ?? "-"}
              />
              <InfoItemHorizontal
                label="Tgl. Produksi Aset"
                text={
                  values?.tgl_produksi_aset
                    ? DateConvert(new Date(values?.tgl_produksi_aset)).detail
                    : "-"
                }
              />
              <InfoItemHorizontal
                label="No. Produksi Aset"
                text={values?.no_produksi_aset ?? "-"}
              />
            </Col>
            <Col lg="6">
              <InfoItemHorizontal
                label="Grup Aset"
                text={values?.nama_grup_aset ?? "-"}
              />
              <InfoItemHorizontal
                label="Kategori Aset"
                text={values?.nama_kategori_aset ?? "-"}
              />
              <InfoItemHorizontal
                label="Jenis Aset"
                text={values?.nama_jenis_aset ?? "-"}
              />
              <InfoItemHorizontal
                label="Item Aset Yang Diminta"
                text={values?.nama_aset ?? "-"}
              />
            </Col>
          </Row>
          {action === "detail" && (
            <>
              <hr />
              <Row>
                <Col>
                  <InfoItemVertical
                    label="Tgl. Nilai Perolehan Produksi Aset"
                    text={
                      values?.tgl_nilai_perolehan_aset
                        ? DateConvert(
                            new Date(values?.tgl_nilai_perolehan_aset)
                          ).detail
                        : "-"
                    }
                  />
                  <InfoItemVertical
                    label="COA Debet"
                    text={`${values?.nomor_coa_debet ?? "-"} - ${
                      values?.nama_coa_debet ?? "-"
                    }`}
                  />
                  <InfoItemVertical
                    label="COA Kredit"
                    text={`${values?.nomor_coa_kredit ?? "-"} - ${
                      values?.nama_coa_kredit ?? "-"
                    }`}
                  />
                </Col>
                <Col>
                  <InfoItemVertical
                    label="No. Nilai Perolehan Produksi Aset"
                    text={values?.no_nilai_perolehan_aset ?? "-"}
                  />
                </Col>
                <Col>
                  <InfoItemVertical
                    label="Jumlah"
                    text={
                      RupiahConvert(parseFloat(values?.jumlah ?? 0).toString())
                        .getWithComa
                    }
                  />
                  <InfoItemVertical
                    label="Keterangan Jurnal"
                    text={values?.keterangan_jurnal ?? "-"}
                  />
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>

      <small className="font-weight-bold">Data WIP Produksi Aset</small>

      <TabKomponenProduksiAset action={action} />

      <div className="mt-3">
        <small className="font-weight-bold">Summary WIP</small>
        <Card>
          <Card.Body>
            <SummeryBuaso />
          </Card.Body>
        </Card>
      </div>

      {action !== "detail" && (
        <div className="d-flex justify-content-end mt-3">
          <ButtonCreate
            text="Tambah"
            variant={action === "update" ? "success" : "primary"}
            onClick={() => setModalFormConfig({ show: true })}
          />
        </div>
      )}

      {modalFormConfig?.show && (
        <ModalForm
          setModalConfig={setModalFormConfig}
          modalConfig={modalFormConfig}
          action={action}
        />
      )}
    </Fragment>
  );
};
