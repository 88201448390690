import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useFormikContext } from "formik";
import {
  DatePicker,
  InfoItemHorizontal,
  InfoItemVertical,
  Input,
} from "components";
import { DateConvert, DecimalConvert } from "utilities";

export const InfoSection = ({ data = {}, action }) => {
  const [loading, setLoading] = useState({
    nomor: false,
  });

  return (
    <>
      <Row>
        <Col md>
          <InfoItemHorizontal
            label="Tgl. Produksi"
            text={
              data?.tgl_jobmix
                ? DateConvert(new Date(data?.tgl_jobmix)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Produksi"
            text={data?.no_jobmix ?? "-"}
          />
          <InfoItemHorizontal
            label="Kode Item Produksi"
            text={data?.kode_item ?? "-"}
          />
          <InfoItemHorizontal
            label="Item Produksi"
            text={data?.nama_item ?? "-"}
          />
        </Col>
        <Col md>
          <InfoItemHorizontal
            label="Qty. Produksi"
            text={`${
              data?.qty_jobmix
                ? DecimalConvert(data.qty_jobmix).getWithComa
                : "-"
            } ${data?.kode_satuan ?? ""}`}
          />
          <InfoItemHorizontal
            label="Total Qty. Telah Direalisasi"
            text={`${
              data?.qty_realisasi_produksi
                ? DecimalConvert(data.qty_realisasi_produksi).getWithComa
                : 0
            } ${data?.kode_satuan ?? ""}`}
          />
          <InfoItemHorizontal label="Proyek" text={data?.nama_proyek ?? "-"} />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col lg>
          <Row>
            <Col lg>
              <InfoItemVertical
                label="Tgl. Realisasi Produksi"
                text={
                  data?.tgl_realisasi_produksi
                    ? DateConvert(new Date(data?.tgl_realisasi_produksi)).detail
                    : "-"
                }
              />
            </Col>
            <Col lg>
              <InfoItemVertical
                label="No. Realisasi Produksi"
                text={data?.no_realisasi_produksi ?? "-"}
              />
            </Col>
          </Row>
        </Col>
        <Col lg>
          <Row>
            <InfoItemVertical
              label="Qty. Realisasi Item Produksi"
              text={`${
                data?.qty_realisasi_produksi
                  ? DecimalConvert(data?.qty_realisasi_produksi).getWithComa
                  : "-"
              } ${data?.nama_satuan ?? ""}`}
            />
          </Row>
        </Col>
      </Row>
    </>
  );
};
