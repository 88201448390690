import { ButtonCreate, Thead } from "@bhawanadevteam/react-core";
import {
  DataStatus,
  DatePicker,
  TBody,
  Table,
  Td,
  TdFixed,
  TextArea,
  Th,
  ThFixed,
  Tr,
} from "components";
import React from "react";
import { Modal } from "react-bootstrap";
import { TableListRealisasi } from "./TableListRealisasi";
import { useFormikContext } from "formik";
import { DateConvert } from "utilities";

export const ModalPostingWIP = ({
  setModalConfig,
  modalConfig,
  title,
  tab,
  section,
}) => {
  const {
    values,
    setFieldValue,
    handleChange,
    errors,
    touched,
    handleSubmit,
    isSubmitting,
  } = useFormikContext();

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={() => setModalConfig({ show: false })}
        size="xl"
      >
        <Modal.Header closeButton className="text-capitalize">
          Posting WIP {title}
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            label="Tgl. BDP Bahan"
            selected={values?.tgl_wip ? new Date(values?.tgl_wip) : null}
            onChange={(val) =>
              setFieldValue("tgl_wip", DateConvert(new Date(val)).default)
            }
            error={errors.tgl_wip && touched.tgl_wip && true}
            errorText={errors.tgl_wip && touched.tgl_wip && errors.tgl_wip}
          />

          <TextArea
            label="Keterangan Jurnal"
            name="keterangan_jurnal"
            value={values?.keterangan_jurnal}
            onChange={handleChange}
          />
          <TableListRealisasi
            data={modalConfig?.data}
            title={title}
            action="create"
            tab={tab}
            section={section}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonCreate
            text="Simpan"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};
