import {
  TBody,
  THead,
  Table,
  Th,
  ThFixed,
  Tr,
  Td,
  DataStatus,
} from "components";
import React from "react";
import { DecimalConvert, RupiahConvert } from "utilities";

export const TableBdp = ({ data, withVendor = false, type }) => {
  return (
    <div className="py-3 px-2">
      <b>List Realisasi Item Bahan</b>
      <Table className="m-0">
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Item {type}</Th>
            {withVendor && <Th>vendor</Th>}
            <Th>Satuan</Th>
            <Th>Qty. Realisasi {type}</Th>
            <Th>Harga Satuan</Th>
            <Th>Jumlah</Th>
          </Tr>
        </THead>
        <TBody>
          {data?.length > 0 ? (
            data?.map((val, ind) => (
              <Tr>
                <Td>{ind + 1}</Td>
                <Td>{val.namaItem}</Td>
                {withVendor && <Td>{val.vendor}</Td>}
                <Td>{val.namaSatuan}</Td>
                <Td className="text-right">
                  {DecimalConvert(parseFloat(val.qtyRealisasi)).getWithComa}
                </Td>
                <Td className="text-right">
                  {
                    RupiahConvert(parseFloat(val.hargaSatuan).toString())
                      .getWithComa
                  }
                </Td>
                <Td className="text-right">
                  {
                    RupiahConvert(
                      parseFloat(val.hargaSatuan * val.qtyRealisasi).toString()
                    ).getWithComa
                  }
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan="6">
                <DataStatus text="Data Tidak Ada" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </div>
  );
};
