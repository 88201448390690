import { DataStatus, TBody, Table, Td, Th, Tr, TdFixed } from "components";
import Thead from "components/Table/THead";
import React from "react";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";

export const TableCommulativeCost = ({ tableData }) => {
  return (
    <Table responsive>
      <Thead>
        <Tr>
          <Th rowSpan="2" className="text-nowrap">
            Informasi Sales Order
          </Th>
          <Th className="text-nowrap" rowSpan="2">
            Barang Jadi
          </Th>
          <Th className="text-nowrap" colSpan="2">
            Budget
          </Th>
          <Th className="text-nowrap" colSpan="6">
            Harga Pokok Produksi
          </Th>
          <Th className="text-nowrap" colSpan="2">
            Unit Cost
          </Th>
          <Th className="text-nowrap" colSpan="2">
            Performance Dengan OH Rencana
          </Th>
        </Tr>
        <Tr>
          <Th className="text-nowrap">Total Analisa Barang Jadi</Th>
          <Th className="text-nowrap">Realisasi Analisa Barang Jadi</Th>
          <Th className="text-nowrap">Total Bahan</Th>
          <Th className="text-nowrap">Total Upah</Th>
          <Th className="text-nowrap">Total Alat Mesin</Th>
          <Th className="text-nowrap">Total Subkon</Th>
          <Th className="text-nowrap">Total BOP</Th>
          <Th className="text-nowrap">Total HPP</Th>
          <Th className="text-nowrap">Total BOK</Th>
          <Th className="text-nowrap">Unit Cost</Th>
          <Th className="text-nowrap">Rencana BOK</Th>
          <Th className="text-nowrap">Realisasi BOK</Th>
        </Tr>
      </Thead>
      <TBody>
        {tableData && tableData.length > 0 ? (
          tableData?.map((val, ind) => (
            <Tr key={ind}>
              {val.rowspan !== 0 && (
                <TdFixed rowSpan={val.rowspan}>
                  <div>
                    {val?.tgl_sales_order
                      ? DateConvert(new Date(val?.tgl_sales_order)).defaultDMY
                      : "-"}
                  </div>
                  <div>{val?.no_sales_order ?? "-"}</div>
                </TdFixed>
              )}
              <Td className="text-nowrap">{`${val?.nama_item ?? "-"} ${
                DecimalConvert(parseFloat(val?.qty_sales_order ?? 0))
                  .getWithComa
              } ${val?.kode_satuan ?? "-"}`}</Td>
              <Td textRight>
                {
                  RupiahConvert(parseFloat(val?.analisa_budget ?? 0).toString())
                    .getWithComa
                }
              </Td>
              <Td textRight>
                {
                  RupiahConvert(
                    parseFloat(val?.realisasi_budget ?? 0).toString()
                  ).getWithComa
                }
              </Td>
              <Td textRight>
                {
                  RupiahConvert(parseFloat(val?.hpp_bahan ?? 0).toString())
                    .getWithComa
                }
              </Td>
              <Td textRight>
                {
                  RupiahConvert(parseFloat(val?.hpp_upah ?? 0).toString())
                    .getWithComa
                }
              </Td>
              <Td textRight>
                {
                  RupiahConvert(parseFloat(val?.hpp_alat_mesin ?? 0).toString())
                    .getWithComa
                }
              </Td>
              <Td textRight>
                {
                  RupiahConvert(parseFloat(val?.hpp_subkon ?? 0).toString())
                    .getWithComa
                }
              </Td>
              <Td textRight>
                {
                  RupiahConvert(parseFloat(val?.hpp_bop ?? 0).toString())
                    .getWithComa
                }
              </Td>
              <Td textRight>
                {
                  RupiahConvert(parseFloat(val?.total_hpp ?? 0).toString())
                    .getWithComa
                }
              </Td>
              <Td textRight>
                {
                  RupiahConvert(parseFloat(val?.hpp_bok ?? 0).toString())
                    .getWithComa
                }
              </Td>
              <Td textRight>
                {
                  RupiahConvert(parseFloat(val?.unit_cost ?? 0).toString())
                    .getWithComa
                }
              </Td>
              <Td textRight>
                {
                  RupiahConvert(parseFloat(val?.rencana_bok ?? 0).toString())
                    .getWithComa
                }
              </Td>
              <Td textRight>
                {
                  RupiahConvert(parseFloat(val?.realisasi_bok ?? 0).toString())
                    .getWithComa
                }
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan="15">
              <DataStatus text="Data Tidak Ada" />
            </Td>
          </Tr>
        )}
      </TBody>
    </Table>
  );
};
