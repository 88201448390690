// React
import React, { useState, useEffect } from "react";

// Component
import { Alert } from "components";
import { Tab, Tabs } from "react-bootstrap";
import _ from "lodash";
import BetonSection from "./Comps/BetonSection";
import PrecastSection from "./Comps/PrecastSection";

const HargaSatuanRataRataOverhead = ({ setNavbarTitle }) => {
  const title = "Harga Satuan Rata-Rata Barang Jadi";

  useEffect(() => {
    setNavbarTitle(title);
  }, []);

  return (
    <>
      {/* head section */}

      <Tabs
        defaultActiveKey="beton"
        transition={false}
        // onSelect={(e) => {
        //   setTab(e);
        //   setPagination({
        //     ...pagination,
        //     page: 1,
        //   });
        // }}
      >
        <Tab title="Beton" eventKey="beton">
          <div className="p-3 bg-white border border-top-0">
            <BetonSection />
          </div>
        </Tab>
        <Tab title="Precast" eventKey="precast">
          <div className="p-3 bg-white border border-top-0">
            <PrecastSection />
          </div>
        </Tab>
      </Tabs>
    </>
  );
};

export default HargaSatuanRataRataOverhead;
