import Services from "services";

class TransferOverheadProduksiAsetApi {
  async getList(params) {
    const fetch = await Services.get("/transfer_overhead_produksi_aset/page", {
      params,
    });
    return fetch.data;
  }

  async dropdownItem(params) {
    const fetch = await Services.get("/dropdown/item_overhead_produksi_aset", {
      params,
    });

    return fetch?.data?.data.map((val) => ({
      label: val.nama_item,
      value: val.id_item,
    }));
  }

  export(params) {
    return Services.get("/transfer_overhead_produksi_aset/export", { params });
  }
}

export default new TransferOverheadProduksiAsetApi();
