import { ButtonCreate } from "@bhawanadevteam/react-core";

import { useFormikContext } from "formik";
import React, { useState } from "react";
import { ModalPostingWIP } from "./ModalPostingWIP";
import { TableListRealisasi } from "./TableListRealisasi";
import { TableHistoryWIP } from "./TableHistoryWIP";

export const TableKomponenProduksi = ({ tab, section }) => {
  const [modalPostingConfig, setModalPostingConfig] = useState({
    show: false,
  });
  const { values } = useFormikContext();
  const checkTab = tab === "alatMesin" ? "alat mesin" : tab;

  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <small className="font-weight-bold">List Realisasi</small>
        <ButtonCreate
          text="Posting"
          tooltipText="Posting"
          disabled={
            values?.realisasi?.[tab].length <= 0 ||
            values?.history?.[tab].length >= values?.realisasi?.[tab].length
          }
          onClick={() =>
            setModalPostingConfig({
              show: true,
            })
          }
        />
      </div>

      <TableListRealisasi
        title={checkTab}
        action="detail"
        section={section}
        tab={tab}
      />
      <small className="font-weight-bold">List History WIP</small>
      <TableHistoryWIP
        title={checkTab}
        action="detail"
        section="history"
        tab={tab}
      />
      {modalPostingConfig?.show && (
        <ModalPostingWIP
          setModalConfig={setModalPostingConfig}
          modalConfig={modalPostingConfig}
          title={checkTab}
          tab={tab}
          section={section}
        />
      )}
    </div>
  );
};
