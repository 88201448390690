import { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import {
  ActionButton,
  BackButton,
  DatePicker,
  InfoItemHorizontal,
  InfoItemVertical,
  Input,
  NumberFormat,
  SelectSearch,
  TextArea,
} from "components";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";
import { PostingStockOpnameApi } from "api";
import { useFormikContext } from "formik";

const DetailSection = ({ data }) => {
  return (
    <Row>
      <Col lg="6">
        <InfoItemVertical
          label="Tgl. Posting Stock Opname"
          text={DateConvert(new Date(data.tglPostingStockOpname)).detail}
        />
      </Col>
      <Col lg="6">
        <InfoItemVertical
          label="No. Posting Stock Opname"
          text={data.noPostingStockOpname}
        />
      </Col>
      <Col lg="12">
        <InfoItemVertical label="Item Barang" text={data.namaItem} />
      </Col>
      <Col lg="6">
        <InfoItemVertical
          label="Qty. Adjustment"
          text={DecimalConvert(parseFloat(data.qtyAdjustment))
            .getWithComa.split("")
            .filter((val) => val !== "-")
            .join("")}
        />
      </Col>
      <Col lg="6">
        <InfoItemVertical label="Satuan Pakai" text={data.namaSatuan} />
      </Col>
      <Col lg="12">
        <InfoItemVertical
          label="Jumlah"
          text={RupiahConvert(parseFloat(data.jumlah).toString())
            .getWithComa.split("")
            .filter((val) => val !== "-")
            .join("")}
        />
      </Col>
      <Col lg="12">
        <InfoItemVertical label="COA Debet" text={data.namaAkunDebet} />
      </Col>
      <Col lg="12">
        <InfoItemVertical label="COA Kredit" text={data.namaAkunKredit} />
      </Col>
      <Col lg="12">
        <InfoItemVertical label="Keterangan" text={data.keteranganJurnal} />
      </Col>
    </Row>
  );
};

export const FormSection = ({ dropdown, type }) => {
  const [isNomorLoading, setIsNomorLoading] = useState(false);
  const { values, errors, touched, setValues, handleChange } =
    useFormikContext();

  const getNomorHandler = (date) => {
    setIsNomorLoading(true);

    PostingStockOpnameApi.getNomor({ tanggal: date })
      .then((no) => {
        const noPosting = no.data.data;

        setValues({
          ...values,
          noPostingStockOpname: noPosting,
          tglPostingStockOpname: date,
        });
      })
      .catch(() => {
        setValues({
          ...values,
          noPostingStockOpname: "",
          tglPostingStockOpname: null,
        });
        window.alert("Nomor gagal dimuat");
      })
      .finally(() => {
        setIsNomorLoading(false);
      });
  };

  return type === "detail" ? (
    <DetailSection data={values} />
  ) : (
    <>
      <Row>
        <Col lg="6">
          <DatePicker
            label="Tgl. Posting Stock Opname"
            name="tglPostingStockOpname"
            selected={
              values?.tglPostingStockOpname
                ? new Date(values?.tglPostingStockOpname)
                : null
            }
            onChange={(e) => getNomorHandler(DateConvert(new Date(e)).default)}
            error={
              Boolean(errors.tglPostingStockOpname) &&
              touched.tglPostingStockOpname
            }
            errorText={
              Boolean(errors.tglPostingStockOpname) &&
              touched.tglPostingStockOpname &&
              errors.tglPostingStockOpname
            }
          />
        </Col>
        <Col lg="6">
          <Input
            label="No. Posting Stock Opname"
            disabled
            placeholder="Pilih tanggal terlebih dahulu"
            value={
              isNomorLoading
                ? "Generate nomor..."
                : values?.noPostingStockOpname
            }
          />
        </Col>
        <Col lg="12">
          <Input label="Item Barang" disabled value={values?.namaItem} />
        </Col>
        <Col lg="12">
          <NumberFormat
            disabled
            label="Harga Satuan Rata-Rata Per Bulan Stock Opname"
            value={parseFloat(values.hargaSatuanRataRata ?? 0)}
            onChange={(e) => setValues({ ...values, hargaSatuanRataRata: e })}
          />
        </Col>
        <Col lg="6">
          <Input
            label="Qty. Adjustment"
            disabled
            value={
              DecimalConvert(parseFloat(values?.qtyAdjustment)).getWithComa
            }
          />
        </Col>
        <Col lg="6">
          <Input label="Satuan Pakai" disabled value={values?.namaSatuan} />
        </Col>
        <Col lg="12">
          <NumberFormat
            label="Jumlah"
            disabled
            value={parseFloat(values.hargaAdjustment ?? 0)}
            onChange={(e) => setValues({ ...values, hargaAdjustment: e })}
          />
        </Col>
        <Col lg="12">
          <SelectSearch
            label="COA Debet"
            option={dropdown.debet}
            defaultValue={{
              value: values.nomorAkunDebet,
              label: values.namaAkunDebet ?? "Pilih coa debet",
            }}
            placeholder="Pilih coa debet"
            onChange={(e) =>
              setValues({
                ...values,
                nomorAkunDebet: e.value,
                namaAkunDebet: e.label,
              })
            }
            error={Boolean(errors.nomorAkunDebet) && touched.nomorAkunDebet}
            errorText={
              Boolean(errors.nomorAkunDebet) &&
              touched.nomorAkunDebet &&
              errors.nomorAkunDebet
            }
          />
        </Col>
        <Col lg="12">
          <SelectSearch
            label="COA Kredit"
            option={dropdown.kredit}
            defaultValue={{
              value: values.nomorAkunKredit,
              label: values.namaAkunKredit ?? "Pilih coa kredit",
            }}
            placeholder="Pilih coa kredit"
            onChange={(e) =>
              setValues({
                ...values,
                nomorAkunKredit: e.value,
                namaAkunKredit: e.label,
              })
            }
            error={Boolean(errors.nomorAkunKredit) && touched.nomorAkunKredit}
            errorText={
              Boolean(errors.nomorAkunKredit) &&
              touched.nomorAkunKredit &&
              errors.nomorAkunKredit
            }
          />
        </Col>
        <Col lg="12">
          <TextArea
            label="Keterangan"
            rows="4"
            name="keteranganJurnal"
            value={values?.keteranganJurnal}
            onChange={handleChange}
            error={Boolean(errors.keteranganJurnal) && touched.keteranganJurnal}
            errorText={
              Boolean(errors.keteranganJurnal) &&
              touched.keteranganJurnal &&
              errors.keteranganJurnal
            }
          />
        </Col>
      </Row>
    </>
  );
};
