import { DataStatus, TBody, Table, Td, Th, Tr, TdFixed } from "components";
import Thead from "components/Table/THead";
import React from "react";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";

export const TableCommulativeProgress = ({ tableData }) => {
  return (
    <Table>
      <Thead>
        <Tr>
          <Th rowSpan="2">Informasi Sales Order</Th>
          <Th rowSpan="2">Barang Jadi</Th>
          <Th rowSpan="2">Kontrak</Th>
          <Th colSpan="2">Progress</Th>
          <Th colSpan="2">Approval</Th>
        </Tr>
        <Tr>
          <Th>Qty</Th>
          <Th>Rp</Th>
          <Th>Qty</Th>
          <Th>Rp</Th>
        </Tr>
      </Thead>
      <TBody>
        {tableData && tableData.length > 0 ? (
          tableData?.map((val, ind) => (
            <Tr key={ind}>
              {val.rowspan !== 0 && (
                <TdFixed rowSpan={val.rowspan}>
                  <div>
                    {val?.tgl_sales_order
                      ? DateConvert(new Date(val?.tgl_sales_order)).defaultDMY
                      : "-"}
                  </div>
                  <div>{val?.no_sales_order ?? "-"}</div>
                </TdFixed>
              )}

              <Td className="text-nowrap">{`${val?.nama_item ?? "-"} ${
                DecimalConvert(parseFloat(val?.qty_sales_order ?? 0))
                  .getWithComa
              } ${val?.kode_satuan ?? "-"}`}</Td>
              <Td textRight>
                {
                  RupiahConvert(parseFloat(val?.kontrak ?? 0).toString())
                    .getWithComa
                }
              </Td>
              <Td textRight>
                {
                  DecimalConvert(parseFloat(val?.qty_progress ?? 0).toString())
                    .getWithComa
                }
              </Td>
              <Td textRight>
                {
                  RupiahConvert(String(parseFloat(val?.rp_progress ?? 0)))
                    .getWithComa
                }
              </Td>
              <Td textRight>
                {DecimalConvert(parseFloat(val?.qty_approval ?? 0)).getWithComa}
              </Td>
              <Td textRight>
                {
                  RupiahConvert(String(parseFloat(val?.rp_approval ?? 0)))
                    .getWithComa
                }
              </Td>
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan="7">
              <DataStatus text="Data Tidak Ada" />
            </Td>
          </Tr>
        )}
      </TBody>
    </Table>
  );
};
