import { PostingStockOpnameApi } from "api";
import { ActionButton, DataStatus } from "components";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FormSection } from "./FormSection";
import { InfoSection } from "./InfoSection";
import { Formik } from "formik";
import {
  formInitialValues,
  formSubmitMapper,
  formValidationSchema,
} from "../Utils";
import Axios from "axios";
import { useHistory, useLocation } from "react-router-dom";

export const ModalSection = ({
  modalConfig,
  setModalConfig,
  setAlertConfig,
  alertConfig,
  getInitialData,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [dataStockOpname, setDataStockOpname] = useState({});
  const [dropdownCOA, setDropdownCOA] = useState({ debet: [], kredit: [] });

  const mappingCoa = (data) =>
    data.map((val) => ({
      value: val?.nomor_akun,
      label: `${val?.nomor_akun} || ${val?.nama_akun}`,
    }));
  const getDataStockOpname = () => {
    Axios.all([
      PostingStockOpnameApi.singleStockOpname({
        id_stock_opname_detail: modalConfig?.idStockOpnameDetail,
      }),
      PostingStockOpnameApi.dropdownCOA(),
    ])
      .then(
        Axios.spread((data, coa) => {
          setDataStockOpname(data?.data?.data);
          setDropdownCOA({
            debet: mappingCoa(coa?.data?.data ?? []),
            kredit: mappingCoa(coa?.data?.data ?? []),
          });
        })
      )
      .catch(() => {
        alert("Gagal Memuat Data");
      })
      .finally(() => setIsLoading(false));
  };

  const getDataPostingStockOpname = () => {
    Axios.all([
      PostingStockOpnameApi.single({
        id_posting_stock_opname: modalConfig?.idStockOpnameDetail,
      }),
    ])
      .then(
        Axios.spread((data) => {
          setDataStockOpname(data?.data?.data);
        })
      )
      .catch(() => {
        alert("Gagal Memuat Data");
      })
      .finally(() => setIsLoading(false));
  };

  const formSubmitHandler = (values, { setSubmitting }) => {
    PostingStockOpnameApi.create(formSubmitMapper(values))
      .then(() => {
        setAlertConfig({
          show: true,
          variant: "primary",
          text: "Data berhasil disimpan!",
        });
        setModalConfig({ ...modalConfig, show: false });
      })
      .catch(() => {})
      .finally(() => {
        getInitialData();
        setSubmitting(false);
      });
  };

  useEffect(() => {
    if (modalConfig.type === "add") {
      getDataStockOpname();
    }

    if (modalConfig.type === "detail") {
      getDataPostingStockOpname();
    }

    return () => {
      setIsLoading(true);
    };
  }, []);

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ ...modalConfig, show: false })}
    >
      <Modal.Header closeButton>Posting Stock Opname</Modal.Header>
      {isLoading ? (
        <DataStatus loading text="Memuat Data..." />
      ) : dataStockOpname?.id_stock_opname_detail ? (
        <Formik
          enableReinitialize
          initialValues={formInitialValues(dataStockOpname)}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                  <>
                    <InfoSection />
                    <FormSection
                      dropdown={dropdownCOA}
                      type={modalConfig.type}
                    />
                  </>
                </Modal.Body>
                <Modal.Footer>
                  {modalConfig.type === "add" ? (
                    <>
                      <ActionButton
                        text="Reset"
                        variant="outline-secondary"
                        onClick={() => {
                          formik.resetForm();
                          setModalConfig({ ...modalConfig, show: false });
                        }}
                      />
                      <ActionButton
                        text="Simpan"
                        type="submit"
                        loading={formik?.isSubmitting}
                      />
                    </>
                  ) : (
                    <ActionButton
                      text="Kembali"
                      variant="outline-secondary"
                      onClick={() => {
                        formik.resetForm();
                        setModalConfig({ ...modalConfig, show: false });
                      }}
                    />
                  )}
                </Modal.Footer>
              </form>
            </>
          )}
        </Formik>
      ) : (
        <DataStatus text="Gagal Memuat Data" />
      )}
    </Modal>
  );
};
