import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  ReadButton,
  UpdateButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ApprovalStatusButton,
  FilterButton,
} from "components";
import { DateConvert, PageNumber as TableNumber } from "utilities";
import { RAEApi } from "api";
import { ModalFilter } from "./Section";

const RAE = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  // const state = location?.state;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.unit_cost?.filter?.active,
      tgl_rae_mulai: location?.state?.unit_cost?.filter?.tgl_rae_mulai,
      tgl_rae_selesai: location?.state?.unit_cost?.filter?.tgl_rae_selesai,
      tgl_peluang_mulai: location?.state?.unit_cost?.filter?.tgl_peluang_mulai,
      tgl_peluang_selesai: location?.state?.unit_cost?.filter?.tgl_peluang_selesai,
      customer: location?.state?.unit_cost?.filter?.customer,
      unit_produksi: location?.state?.unit_cost?.filter?.unit_produksi,
      baseline: location?.state?.unit_cost?.filter?.baseline,
      status_approval: location?.state?.unit_cost?.filter?.status_approval,
    },
    pagination: {
      page: location?.state?.unit_cost?.filter?.page ?? "1",
      dataLength: location?.state?.unit_cost?.filter?.dataLength ?? "10",
      totalPage: location?.state?.unit_cost?.filter?.totalPage ?? "1",
      dataCount: location?.state?.unit_cost?.filter?.dataCount ?? "0",
    },
  });
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    RAEApi.getPage({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_rae_mulai: dataFilter?.filter?.tgl_rae_mulai,
      tgl_rae_selesai: dataFilter?.filter?.tgl_rae_selesai,
      tgl_peluang_mulai: dataFilter?.filter?.tgl_peluang_mulai,
      tgl_peluang_selesai: dataFilter?.filter?.tgl_peluang_selesai,
      customer: dataFilter?.filter?.customer,
      unit_produksi: dataFilter?.filter?.unit_produksi,
      baseline: dataFilter?.filter?.baseline,
      status_approval: dataFilter?.filter?.status_approval,
    })
      .then(({ data }) => {
        setData(data.data);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data_count,
            totalPage: data?.total_page,
          },
        });
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const dismissAlert = () => {
    let state = { ...history.location.state };
    delete state.alert;
    history.replace({ ...history.location, state });

    setAlertConfig({
      ...alertConfig,
      show: false,
    });
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      },
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };

  useEffect(() => {
    setNavbarTitle("Unit Cost");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_rae_mulai,
    dataFilter?.filter?.tgl_rae_selesai,
    dataFilter?.filter?.tgl_peluang_mulai,
    dataFilter?.filter?.tgl_peluang_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.unit_produksi,
    dataFilter?.filter?.baseline,
    dataFilter?.filter?.status_approval,
  ]);

  const PageContent = () => {
    const checkStatus = (stat) => {
      const data = stat ? stat.toUpperCase() : "";
      const convert = data.substring(0, 3);

      if (convert === "APP")
        return {
          variant: "outline-success",
          label: "APPROVED",
        };
      if (convert === "VER")
        return {
          variant: "outline-success",
          label: "VERIFIED",
        };
      if (convert === "REV")
        return {
          variant: "outline-warning",
          label: "REVISI",
        };
      if (convert === "REJ")
        return {
          variant: "outline-danger",
          label: "REJECT",
        };

      return {
        variant: "outline-secondary",
        label: "PENDING",
      };
    };
    const BarangJadiCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);
      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: "12px" }}>
            {data.map((brg, index) => {
              if (isReadMoreClick) {
                return (
                  <li key={index} index={index}>
                    {brg.nama_item}
                  </li>
                );
              } else {
                return (
                  index < 2 && (
                    <li key={index} index={index}>
                      {brg.nama_item}
                    </li>
                  )
                );
              }
            })}
          </ul>
          {data.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => setisReadMoreClick((prev) => !prev)}
            >
              {isReadMoreClick ? "Sembunyikan" : "Selengkapnya..."}
            </div>
          )}
        </>
      );
    };

    const DataTable = () => (
      <>
        <span style={{ fontSize: "14px" }}>
          <b>List Data Unit Cost</b>
        </span>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Unit Cost</ThFixed>
              <ThFixed>Informasi Peluang</ThFixed>
              <Th>Customer</Th>
              <Th>Barang Jadi</Th>
              <Th>Unit Produksi</Th>
              <ThFixed>Baseline</ThFixed>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed textCenter>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-start">
                    {val.status_approval === "REV" || val.status_approval === "APP" ? (
                      <>
                        <ReadButton
                          className="my-1"
                          size="sm"
                          onClick={() =>
                            history.push(`/transaksi/unit-cost/detail/${val.id_rae}`, { ...location?.state, unit_cost: dataFilter })
                          }
                        />
                        <UpdateButton
                          className="my-1"
                          size="sm"
                          onClick={() =>
                            history.push(`/transaksi/unit-cost/ubah/${val.id_rae}`, { ...location?.state, unit_cost: dataFilter })
                          }
                        />
                      </>
                    ) : (
                      <ReadButton
                        className="my-1"
                        size="sm"
                        onClick={() =>
                          history.push(`/transaksi/unit-cost/detail/${val.id_rae}`, { ...location?.state, unit_cost: dataFilter })
                        }
                      />
                    )}
                  </div>
                </TdFixed> 
                <TdFixed>
                  <div>{val.tgl_rae ? DateConvert(new Date(val.tgl_rae)).defaultDMY : "-"}</div>
                  <div>{val.no_rae ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div>{val.tgl_peluang ? DateConvert(new Date(val.tgl_peluang)).defaultDMY : "-"}</div>
                  <div>{val.no_peluang}</div>
                </TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>
                  {val.barang_jadi && val.barang_jadi?.length > 0 ? (
                    <BarangJadiCollapse data={val.barang_jadi} />
                  ) : (
                    "-"
                  )}
                </Td>
                <Td>{val.nama_unit_produksi ?? "-"}</Td>
                <TdFixed textCenter>{val.baseline ?? "-"}</TdFixed>
                <TdFixed>
                  <ApprovalStatusButton variant={checkStatus(val.status_approval).variant}>
                    {checkStatus(val.status_approval).label}
                  </ApprovalStatusButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
              dataFilter?.pagination?.dataLength +
              1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                },
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                },
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          {/* <Row className="mb-2">
            <Col md={8}> */}
          <div className="d-flex mb-3">
            <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            <FilterButton active={dataFilter?.filter?.active} onClick={() => setShowModalFilter(true)} />
          </div>
          {/* </Col>
          </Row> */}
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <ActionButton
            text="Tambah Data"
            onClick={() => history.push("/transaksi/unit-cost/list-peluang", { ...location?.state, unit_cost: dataFilter })}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {/* Modal */}
      {showModalFilter && (
        <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}
    </CRUDLayout>
  );
};

export default RAE;
