// React
import React, { useState, useEffect } from "react"

// Components
import { Card, Col, Nav, Row, Tab } from "react-bootstrap"
import {
  CRUDLayout, InputSearch, DataStatus, Alert
} from "../../../components"

// View Components
import { TabItem } from "./components"

const HargaPerkiraanSendiri = ({ setNavbarTitle }) => {
  const title = "Harga Perkiraan Sendiri"

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false)

  // STATE DATA SATUAN
  // const [key, setKey] = useState("")
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });
  const [searchTime, setSearchTime] = useState(null);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false)
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })

  // FUNCTION SEARCH DATA SERVER
  const onInputSearchChange = (e) => {
    const key = e.target.value;
    if(searchTime) clearTimeout(searchTime);
    setSearchTime(
      setTimeout(() => {
        setSearchConfig({
          ...searchConfig,
          key: e.target.value
        })
        setAlertConfig({
          show: key ? true : false,
          variant: "primary",
          text: "Hasil dari pencarian: " + key,
        });
      }, 750)
    )
  }

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title) // SET JUDUL NAVBAR

    return () => {
      setIsLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle])

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={onInputSearchChange}
                // onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : (
        <>
          <b>List Data Harga Perkiraan Sendiri</b>
          <TabItem q={searchConfig.key} filter={undefined} value='pakai' />
        </>
      )}
    </CRUDLayout>
  )
}

export default HargaPerkiraanSendiri
