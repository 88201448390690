// React
import React, { useState, useEffect } from "react";

// Form
import { Formik } from "formik";

// Components
import { DatePicker, FilterModal, SelectSearch } from "components";
import { DateConvert } from "utilities";

// API
import Axios from "axios";
import { BDPProduksiApi } from "api";

// View Functions
// import { mappingDataItem } from "../../functions";

export const ModalFilter = ({ toggle, data, setData, buaso }) => {
  // Variables
  const option = { label: "Semua Data", value: undefined };

  // States
  const [dropdownFilter, setDropdownFilter] = useState([]);
  const [loading, setLoading] = useState(true);

  // USE EFFECT ALL DROPDOWN
  useEffect(() => {
    Axios.all([BDPProduksiApi.dropdownFilterItem()])
      .then(
        Axios.spread((buasoData) => {
          // eslint-disable-next-line
          const dataDropdownItem = buasoData.data.data.map((val) => ({
            label: val.nama_item,
            value: val.id_item_buaso,
          }));
          setDropdownFilter([option, ...dataDropdownItem]);
        })
      )
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line
  }, []);

  // FORM VALUES
  const formInitialValues = {
    tgl_realisasi_produksi_mulai: data.tgl_realisasi_produksi_mulai,
    tgl_realisasi_produksi_selesai: data.tgl_realisasi_produksi_selesai,
    tgl_jobmix_mulai: data.tgl_jobmix_mulai,
    tgl_jobmix_selesai: data.tgl_jobmix_selesai,
    id_item_buaso: data.id_item_buaso,
  };
  const formSubmitHandler = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    setData({
      ...data,
      ...values,
      active: checkActive,
      page: 1,
    });

    toggle();
  };

  // HANDLE CHANGE
  const onResetButtonClick = (setValues) => {
    setLoading(true);

    const myPromise = new Promise((resolve) => {
      setValues({
        tgl_realisasi_produksi_mulai: undefined,
        tgl_realisasi_produksi_selesai: undefined,
        tgl_jobmix_mulai: undefined,
        tgl_jobmix_selesai: undefined,
        id_item_buaso: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => setLoading(false));
  };

  const onTanggalChange = (
    dates,
    values,
    setValues,
    value_awal,
    value_akhir
  ) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      [value_awal]: startDate,
      [value_akhir]: endDate,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={formSubmitHandler}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          isToggle
          toggleModal={toggle}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label={
              <div className="text-capitalize">Tgl. Realisasi Produksi</div>
            }
            placeholderText={`Pilih Tgl. Realisasi Produksi`}
            startDate={
              values.tgl_realisasi_produksi_mulai
                ? new Date(values.tgl_realisasi_produksi_mulai)
                : ""
            }
            endDate={
              values?.tgl_realisasi_produksi_selesai
                ? new Date(values.tgl_realisasi_produksi_selesai)
                : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                `tgl_realisasi_produksi_mulai`,
                `tgl_realisasi_produksi_selesai`
              )
            }
            monthsShown={2}
          />

          <DatePicker
            selectsRange
            label={<div className="text-capitalize">Tgl. Produksi</div>}
            placeholderText={`Pilih Tgl. Produksi`}
            startDate={
              values.tgl_jobmix_mulai ? new Date(values.tgl_jobmix_mulai) : ""
            }
            endDate={
              values.tgl_jobmix_selesai
                ? new Date(values.tgl_jobmix_selesai)
                : ""
            }
            onChange={(dates) =>
              onTanggalChange(
                dates,
                values,
                setValues,
                `tgl_jobmix_mulai`,
                `tgl_jobmix_selesai`
              )
            }
            monthsShown={2}
          />

          <SelectSearch
            key="1"
            label={<div className="text-capitalize">Item {buaso}</div>}
            placeholderText={`Pilih Item ${buaso}`}
            defaultValue={
              dropdownFilter
                ? dropdownFilter.find(
                    (val) => val.value === values.id_item_buaso
                  )
                : ""
            }
            option={dropdownFilter}
            onChange={(val) => setFieldValue("id_item_buaso", val.value)}
            loading={loading}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
