import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  DataStatus,
  InfoItemVertical,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
} from 'components';
import { InfoSectionDetailWorkOrder } from './InfoSectionDetailWorkOrder';
import { TabRincianBiaya } from './TabRincianBiaya';
import { TransferOverheadWorkOrderApi } from 'api';
import { RupiahConvert } from 'utilities';

const subtitleStyle = { fontSize: '14px' };

export const ModalDetailPreform = ({
  show,
  toggle,
  idWorkOrder: id_work_order,
}) => {
  const [data, setData] = useState({});
  const [dataRinician, setDataRincian] = useState([]);
  const [dataTab, setDataTab] = useState({
    bahan: [],
    upah: [],
    alat_mesin: [],
    subkon: [],
    overhead: [],
  });
  const [fetching, setFetching] = useState({
    loading: true,
    success: false,
  });

  useEffect(() => {
    (() => {
      setFetching({
        loading: true,
        success: false,
      });

      TransferOverheadWorkOrderApi.singlePermintaanPreform({ id_work_order })
        .then((res) => {
          console.log(res);
          const {
            rincian_pekerjaan,
            bahan,
            upah,
            alat_mesin,
            subkon,
            overhead,
            ...data
          } = res?.data?.data ?? {};

          setData(data);
          setDataRincian(rincian_pekerjaan);
          setDataTab({
            bahan,
            upah,
            alat_mesin,
            subkon,
            overhead,
          });

          setFetching({
            loading: false,
            success: true,
          });
        })
        .catch(() => {
          setFetching({
            loading: false,
            success: false,
          });
        });
    })();
  }, []);

  return (
    <Modal show={show} onHide={toggle} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <h6 className="m-0">Konfirmasi Status Permintaan </h6>
        </Modal.Title>
      </Modal.Header>
      {fetching.loading || !fetching.success ? (
        <Modal.Body>
          <DataStatus
            loading={fetching.loading}
            text={
              fetching.loading
                ? 'Memuat data...'
                : 'Data gagal dimuat, tidak dapat menampilkan data'
            }
          />
        </Modal.Body>
      ) : (
        <Modal.Body>
          <InfoSectionDetailWorkOrder data={data} />

          <TableRincianPekerjaan data={dataRinician ?? []} />

          <TabRincianBiaya data={dataTab} />

          <InfoItemVertical label="Keterangan" text={data.keterangan} />

          <TableSummaryBiaya data={dataTab} />
        </Modal.Body>
      )}
    </Modal>
  );
};

export const TableRincianPekerjaan = ({ data }) => {
  return (
    <>
      <div className="font-weight-bold" style={subtitleStyle}>
        Rincian Pekerjaan
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>Rincian Pekerjaan</Th>
            <Th>Keterangan</Th>
          </Tr>
        </THead>

        <TBody>
          {data?.length > 0 ? (
            data?.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <Td>{val.nama_pekerjaan ?? '-'}</Td>
                <Td>{val.keterangan ?? '-'}</Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={3}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </Table>
    </>
  );
};

export const TableSummaryBiaya = ({ data }) => {
  const calculateSubtotal = (data = [], buaso) => {
    return data?.reduce(
      (total, item) =>
        total +
        Math.round(
          parseFloat(item?.[`qty_work_order_${buaso}`] ?? 0) *
            parseFloat(item.harga_satuan),
        ),
      0,
    );
  };

  const calculateTotal = (data = []) => {
    return data?.reduce(
      (total, { jumlah }) => total + Math.round(jumlah ?? 0),
      0,
    );
  };

  const rows = [
    { label: 'Bahan', jumlah: calculateSubtotal(data?.bahan, 'bahan') },
    { label: 'Upah', jumlah: calculateSubtotal(data?.upah, 'upah') },
    {
      label: 'Alat',
      jumlah: calculateSubtotal(data?.alat_mesin, 'alat_mesin'),
    },
    { label: 'Subkon', jumlah: calculateSubtotal(data?.subkon, 'subkon') },
    {
      label: 'Overhead',
      jumlah: calculateSubtotal(data?.overhead, 'overhead'),
    },
  ];

  return (
    <>
      <div className="font-weight-bold" style={subtitleStyle}>
        Summary Biaya
      </div>

      <Table>
        <THead>
          <Tr>
            <ThFixed>No.</ThFixed>
            <Th>BUASO</Th>
            <Th width={150}>Jumlah</Th>
          </Tr>
        </THead>

        <TBody>
          {rows.map((val, index) => (
            <Tr>
              <TdFixed textCenter>{index + 1}</TdFixed>
              <Td>{val.label ?? '-'}</Td>
              <Td className="text-right">
                {RupiahConvert(String(val.jumlah ?? 0)).getWithComa}
              </Td>
            </Tr>
          ))}

          <Tr>
            <Td colSpan={2} className="text-right">
              Total
            </Td>
            <Td className="text-right">
              {RupiahConvert(String(calculateTotal(rows))).getWithComa}
            </Td>
          </Tr>
        </TBody>
      </Table>
    </>
  );
};
