// React
import React, { useState, useEffect } from "react";

// Router
import { useLocation, useHistory, useParams } from "react-router-dom";

// Components
import { Card, Nav, Tab } from "react-bootstrap";
import { Alert, BackButton, ActionButton, DataStatus } from "components";
import { DateConvert } from "utilities";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

// API
import Axios from "axios";
import { AnalisaBarangJadiApi } from "api";

// View Components
import {
  TabInfo,
  TabGambar,
  TabFile,
  TabAnalisa,
  SummarySection,
  Body,
  Divider,
  SalinAnalisa,
  TabKonversi,
} from "./components";
import { mappingDataItem, mappingData } from "./functions";

const UbahAnalisaBarangJadi = ({ setNavbarTitle }) => {
  // Hooks
  const location = useLocation();
  const history = useHistory();
  const { id_analisa_barang_jadi } = useParams();

  // Variables
  const routerState = location.state;
  const title = "Analisa Barang Jadi";

  // States
  const [data, setData] = useState({});
  const [dropdown, setDropdown] = useState({
    item_bahan: [],
    jenis: [],
    satuan: [],
    pabrikan: [],
    barang_jadi: [],
  });
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    Axios.all([
      AnalisaBarangJadiApi.single({ id_analisa_barang_jadi }),
      AnalisaBarangJadiApi.dropdown({ tipe: "item_bahan" }),
      AnalisaBarangJadiApi.dropdown({ tipe: "kelompok" }),
      AnalisaBarangJadiApi.dropdown({ tipe: "jenis" }),
      AnalisaBarangJadiApi.dropdown({ tipe: "satuan" }),
      AnalisaBarangJadiApi.dropdown({ tipe: "pabrikan" }),
      AnalisaBarangJadiApi.dropdown({ tipe: "barang_jadi" }),
      AnalisaBarangJadiApi.dropdown({ tipe: "item_aset" }),
      AnalisaBarangJadiApi.dropdown({ tipe: "item_upah" }),
      AnalisaBarangJadiApi.dropdown({ tipe: "item_subkon" }),
      AnalisaBarangJadiApi.dropdown({ tipe: "item_overhead" }),
      AnalisaBarangJadiApi.dropdown({ tipe: "item_bok" }),
    ])
      .then(
        Axios.spread(
          (
            single,
            item_bahan,
            kelompok,
            jenis,
            satuan,
            pabrikan,
            barang_jadi,
            item_aset,
            item_upah,
            item_subkon,
            item_overhead,
            item_bok
          ) => {
            const dataSingle = single.data.data;
            const mapData = dataSingle?.analisa?.map((val) => ({
              ...val,
              nama_buaso: val.flag_kelompok === "BOK" ? "BOK" : val.nama_buaso,
            }));
            setData({
              ...dataSingle,
              analisa: mapData,
              path_gambar: dataSingle.path_gambar.map((val) => val.path_gambar),
            });

            setDropdown({
              kelompok: mappingData(kelompok, "kelompok"),
              jenis: mappingData(jenis, "jenis"),
              satuan: mappingData(satuan, "satuan"),
              pabrikan: mappingData(pabrikan, "pabrikan"),
              barang_jadi: barang_jadi.data.data.map((val) => {
                return {
                  value: val.id_item_atribut,
                  label: val.nama_item,
                };
              }),
              item_bahan: mappingDataItem(item_bahan, "item_buaso"),
              item_aset: mappingDataItem(item_aset, "item_aset"),
              item_upah: mappingDataItem(item_upah, "item_buaso"),
              item_subkon: mappingDataItem(item_subkon, "item_buaso"),
              item_overhead: mappingDataItem(item_overhead, "item_buaso"),
              item_bok: mappingDataItem(item_bok, "item_buaso"),
            });
          }
        )
      )
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="mb-2 text-right">
        <BackButton
          onClick={() =>
            history.push("/master/analisa-barang-jadi", { ...routerState })
          }
        />
      </div>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : isFetchingFailed ? (
        <DataStatus text="Data gagal dimuat, tidak dapat menampilkan data!" />
      ) : (
        <Formik
          initialValues={{
            id_analisa_barang_jadi,
            id_jobmix_design: data.id_jobmix_design ?? "",
            id_item_buaso: data.id_item_buaso,
            id_buaso: data.id_buaso,
            kode_item: data.kode_item,
            nama_item: data.nama_item_base,
            id_satuan: data.id_satuan,
            id_kelompok: data.id_kelompok,
            kode_kelompok: data.kode_kelompok,
            id_jenis: data.id_jenis,
            kode_jenis: data.kode_jenis,
            id_pabrikan: data.id_pabrikan,
            tgl_input: DateConvert(new Date(data.tgl_input)).default,
            id_satuan_jual: data.id_satuan_jual,
            id_satuan_beli: data.id_satuan_beli,
            kode_satuan_beli: data.kode_satuan_beli,
            id_satuan_pakai: data.id_satuan_pakai,
            kode_satuan_pakai: data.kode_satuan_pakai,
            link_referensi: data.link_referensi,
            nilai_konversi: parseFloat(data.nilai_konversi),
            uraian: data.uraian,
            tipe: data.tipe,
            bisa_diproduksi: data.bisa_diproduksi,
            bisa_dijual: data.bisa_dijual,
            nama_varian: data.nama_varian,
            analisa: data.analisa,
            path_gambar: data.path_gambar,
          }}
          validationSchema={Yup.object().shape({
            nama_item: Yup.string()
              .required("Nama Barang Jadi wajib diisi")
              .test({
                name: "nama_item",
                message: "Nama Barang Jadi & Nama Varian telah digunakan",
                test: (_value, all) => {
                  const id_item_buaso = data.id_item_buaso;
                  const nama_item = all?.parent?.nama_item ?? "";
                  const nama_varian = all?.parent?.nama_varian ?? "";

                  return AnalisaBarangJadiApi.cek_nama({
                    nama_item,
                    nama_varian,
                    id_item_buaso,
                  })
                    .then(() => {
                      return true;
                    })
                    .catch(() => {
                      return false;
                    });
                },
              }),
            id_satuan: Yup.string().required("Satuan Pakai wajib diisi"),
            id_jenis: Yup.string().required("Jenis Barang Jadi wajib diisi"),
            id_pabrikan: Yup.string().required("Pabrikan wajib diisi"),
            id_satuan_jual: Yup.string().required("Satuan Jual wajib diisi"),
            id_satuan_beli: Yup.string().required("Satuan Beli wajib diisi"),
            nilai_konversi: Yup.string().required("Nilai Konversi wajib diisi"),
          })}
          onSubmit={(value) => {
            AnalisaBarangJadiApi.update(value)
              .then(() => {
                history.push("/master/analisa-barang-jadi", {
                  ...routerState,
                  alert: {
                    show: true,
                    variant: "primary",
                    text: "Data berhasil diubah!",
                  },
                });
              })
              .catch(() =>
                setAlertConfig({
                  show: true,
                  variant: "danger",
                  text: "Data gagal diubah!",
                })
              );
          }}>
          {(formik) => {
            const totalSummary = (value, nama_buaso) => {
              return formik.values.analisa
                .filter((val) => val.nama_buaso === nama_buaso)
                .reduce((val, { harga, qty }) => {
                  const subtotal = Math.round(
                    parseFloat(qty ?? 0) * parseFloat(harga ?? 0)
                  );
                  return val + subtotal;
                }, 0);
            };

            const summary = [
              {
                buaso: "Bahan",
                total: totalSummary("1", "Bahan"),
              },
              {
                buaso: "Upah",
                total: totalSummary("2", "Upah"),
              },
              {
                buaso: "Alat & Mesin",
                total: totalSummary("3", "Alat dan mesin"),
              },
              {
                buaso: "Subkon",
                total: totalSummary("4", "Subkon"),
              },
              {
                buaso: "Overhead",
                total: totalSummary("5", "Overhead"),
              },
              {
                buaso: "BOK",
                total: totalSummary("5", "BOK"),
              },
            ];
            const total = summary.reduce((prev, { total }) => {
              return prev + total;
            }, 0);

            return (
              <form onSubmit={formik.handleSubmit}>
                <Divider>
                  <b>Detail Data Job Mix Design</b>
                  <Card>
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="tab-1">
                      <Card.Header className="d-flex justify-content-between align-items-center">
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="tab-1">Informasi Umum</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tab-4">
                              Konversi Satuan
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tab-2">Gambar</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="tab-3">File</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Card.Header>
                      <Tab.Content>
                        <Tab.Pane eventKey="tab-1">
                          <Body>
                            <TabInfo dropdown={dropdown} data={data} />
                          </Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-4">
                          <Body>
                            <TabKonversi />
                          </Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-2">
                          <Body>
                            <TabGambar />
                          </Body>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab-3">
                          <Body>
                            <TabFile />
                          </Body>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card>
                </Divider>

                <Divider>
                  <div className="d-flex justify-content-between align-items-end">
                    <b>Data {title}</b>

                    <SalinAnalisa dropdown={dropdown} />
                  </div>
                  <Card>
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="analisa-1">
                      <Card.Header className="d-flex justify-content-between align-items-center">
                        <Nav variant="tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="analisa-1">Bahan</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="analisa-2">Upah</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="analisa-3">
                              Alat & Mesin
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="analisa-4">Subkon</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="analisa-5">Overhead</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="analisa-6">BOK</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Card.Header>
                      <Tab.Content>
                        <Tab.Pane eventKey="analisa-1">
                          <TabAnalisa
                            dropdown={dropdown}
                            id_buaso="1"
                            nama_buaso="Bahan"
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="analisa-2">
                          <TabAnalisa
                            dropdown={dropdown}
                            id_buaso="2"
                            nama_buaso="Upah"
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="analisa-3">
                          <TabAnalisa
                            dropdown={dropdown}
                            id_buaso="3"
                            nama_buaso="Alat dan mesin"
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="analisa-4">
                          <TabAnalisa
                            dropdown={dropdown}
                            id_buaso="4"
                            nama_buaso="Subkon"
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="analisa-5">
                          <TabAnalisa
                            dropdown={dropdown}
                            id_buaso="5"
                            nama_buaso="Overhead"
                          />
                        </Tab.Pane>
                        <Tab.Pane eventKey="analisa-6">
                          <TabAnalisa
                            dropdown={dropdown}
                            id_buaso="5"
                            nama_buaso="BOK"
                          />
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Card>
                </Divider>

                <Divider>
                  <SummarySection summary={summary} total={total} />
                </Divider>

                <div className="d-flex justify-content-end align-items-center mt-3">
                  <ActionButton
                    variant="primary"
                    text="Simpan"
                    type="submit"
                    loading={formik.isSubmitting}
                  />
                </div>
              </form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default UbahAnalisaBarangJadi;
