export const formInitialValues = (data) => ({
  idStockOpnameDetail: data?.id_stock_opname_detail ?? null,
  qtyStockAkhir: data?.qty_stock_akhir ?? 0,
  hargaStockAkhir: data?.harga_stock_akhir ?? 0,
  qtyStockFisik: data?.qty_stock_fisik ?? 0,
  hargaStockFisik: data?.harga_stock_fisik ?? 0,
  qtyAdjustment: data?.qty_adjustment ?? 0,
  hargaAdjustment: String(data?.harga_adjustment ?? 0)
    .split("")
    .map((res) => (res[0] === "-" ? "" : res))
    .join(""),
  keterangan: data?.keterangan ?? "-",
  idItemBarang: data?.id_item_barang ?? null,
  idSatuan: data?.id_satuan ?? null,
  namaItem: data?.nama_item ?? "-",
  namaSatuan: data?.nama_satuan ?? "-",
  kodeSatuan: data?.kode_satuan ?? "-",
  idStockOpnameMaster: data?.id_stock_opname_master ?? "-",
  isPosting: data?.is_posting ?? false,
  tglStockOpnameMaster: data?.tgl_stock_opname_master ?? null,
  noStockOpnameMaster: data?.no_stock_opname_master ?? "-",
  idStockFisik: data?.id_stock_fisik ?? null,
  idGudang: data?.id_gudang ?? null,
  namaGudang: data?.nama_gudang ?? "-",
  idUnitOrganisasi: data?.id_unit_organisasi ?? null,
  namaUnitProduksi: data?.nama_unit_produksi ?? "-",
  tglStockFisikMaster: data?.tgl_stock_fisik_master ?? null,
  noStockFisikMaster: data?.no_stock_fisik_master ?? null,
  tglPostingStockOpname: data?.tgl_posting_stock_opname ?? null,
  nomorAkunDebet: data?.nomor_akun_debet ?? null,
  nomorAkunKredit: data?.nomor_akun_kredit ?? null,
  namaAkunDebet: data?.nama_akun_debet ?? null,
  namaAkunKredit: data?.nama_akun_kredit ?? null,
  keteranganJurnal: data?.keterangan_jurnal ?? null,
  noPostingStockOpname: data?.no_posting_stock_opname ?? null,
  jumlah: data?.jumlah
    ? data?.jumlah
    : parseFloat(data?.harga_satuan ?? 0) *
      parseFloat(data.qty_adjustment ?? 0),
  hargaSatuanRataRata: parseFloat(data?.harga_satuan ?? 0),
});
