// Components
import { DropdownButton, Spinner } from "react-bootstrap";

export const ExportButton = ({ loading, children, disabled }) => (
  <DropdownButton
    title={
      loading ? (
        <Spinner animation="border" variant="white" size="sm" />
      ) : (
        "Export Data"
      )
    }
    variant="warning text-white"
    disabled={disabled}
    className="ml-2"
  >
    {children}
  </DropdownButton>
);
