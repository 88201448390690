import { CRUDLayout, ReadButton, Td, TdFixed, Th, ThFixed } from "components";
import { useState } from "react";
import {
  DateConvert,
  DecimalConvert,
  TableNumber as PageNumber,
} from "utilities";
import { TransferModal } from "../modals";

const TableTransfer = ({ isPre, data, dataFilter, title }) => {
  // States
  const [modal, setModal] = useState(false);
  const [dataModal, setDataModal] = useState({});

  const toggleModal = () => setModal(!modal);

  const HeaderAksi = () => <ThFixed>Aksi</ThFixed>;
  const BodyAksi = ({ val }) => (
    <TdFixed>
      <ReadButton
        size="sm"
        onClick={() => {
          setDataModal(val);
          toggleModal();
        }}
      />
    </TdFixed>
  );

  return (
    <>
      <CRUDLayout.Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            {isPre && <HeaderAksi />}
            <ThFixed>Informasi Transfer Overhead Produksi</ThFixed>
            <ThFixed>Informasi Permintaan Produksi</ThFixed>
            <Th>Item Overhead</Th>
            <ThFixed>Qty. Transfer</ThFixed>
            <Th>Keterangan Transfer</Th>
            {!isPre && <HeaderAksi />}
          </tr>
        </thead>
        <tbody>
          {data.map((val, index) => (
            <tr key={index}>
              {isPre ? (
                <TdFixed>
                  {PageNumber(dataFilter.page, dataFilter.per_page, index)}
                </TdFixed>
              ) : (
                <TdFixed>{index + 1}</TdFixed>
              )}
              {isPre && <BodyAksi val={val} />}
              <Td>
                <div>
                  {val.tgl_transfer_produksi_overhead
                    ? DateConvert(new Date(val.tgl_transfer_produksi_overhead))
                        .defaultDMY
                    : "-"}
                </div>
                <div>{val.no_transfer_produksi_overhead ?? "-"}</div>
              </Td>
              <Td>
                <div>
                  {val.tgl_permintaan_produksi_overhead
                    ? DateConvert(
                        new Date(val.tgl_permintaan_produksi_overhead)
                      ).defaultDMY
                    : "-"}
                </div>
                <div>{val.no_permintaan_produksi_overhead ?? "-"}</div>
              </Td>
              <Td>{val.nama_item ?? "-"}</Td>
              <Td className="text-right">{`${
                DecimalConvert(val.qty_transfer ?? 0).getWithComa
              } ${val.kode_satuan ?? ""}`}</Td>
              <Td>{val.keterangan_transfer ?? "-"}</Td>
              {!isPre && <BodyAksi val={val} />}
            </tr>
          ))}
        </tbody>
      </CRUDLayout.Table>

      {modal && (
        <TransferModal toggle={toggleModal} data={dataModal} title={title} />
      )}
    </>
  );
};

export default TableTransfer;
