import React, { useEffect, useState, useContext } from "react";
import { Modal, Spinner, ButtonGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  IoTrashOutline,
  IoPencilOutline,
  IoOptionsOutline,
  IoAddOutline,
  IoCloseOutline,
  IoCheckmark,
} from "react-icons/io5";
import {
  Alert,
  SelectSearch,
  Input,
  ActionButton,
  Table,
  Td,
  InputQtyDecimal,
} from "components";
import AnalisaBarangJadiRAE from "../AnalisaBarangJadi";
import { RupiahConvert, DecimalConvert } from "utilities";
import { RAEContext } from "../RAEContext";

const TableBarangJadi = ({
  dataSelectBarangJadi,
  dataBarangJadi,
  setDataBarangJadi,
}) => {
  const {
    setIndexAnalisa,
    dataAnalisaBarangJadi,
    setDataAnalisaBarangJadi,
    type: contextType,
  } = useContext(RAEContext);
  const [isEdit, setIsEdit] = useState(false);
  const [processedData, setProcessedData] = useState({});
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const grandTotal = dataBarangJadi.reduce((acc, { harga_satuan, qty }) => {
    const subtotal = parseFloat(harga_satuan ?? 0) * parseFloat(qty ?? 0);
    return parseFloat(acc + subtotal);
  }, 0);

  const FormSection = ({ type }) => {
    // const decimalConvert = (value) => {
    //   const newValue = value.toString().replace(/[^0-9\.]/g, "");
    //   const convert = newValue.substring(0, 12);

    //   return convert;
    // };

    const formInitialValues = {
      kode_item: type === "update" ? processedData.kode_item : "",
      nama_item: type === "update" ? processedData.nama_item : "",
      id_item_atribut: type === "update" ? processedData.id_item_atribut : "",
      qty: type === "update" ? processedData.qty : 0,
      satuan: type === "update" ? processedData.satuan : "",
      harga_satuan: type === "update" ? processedData.harga_satuan : 0,
      unique:
        type === "update"
          ? processedData.unique
          : Math.random().toString(36).substring(2, 9),
    };
    const formValidationSchema = Yup.object().shape({
      id_item_atribut: Yup.string().required(),
      qty: Yup.string().required(),
    });
    const formSubmitHandler = (values, { setSubmitting }) => {
      let newData = [];

      if (type === "update") {
        const update = dataBarangJadi.map((val, index) =>
          index === processedData.index ? values : val
        );
        newData = update;
      } else {
        newData = [...dataBarangJadi, values];
      }

      setTimeout(() => {
        setAlertConfig({
          show: true,
          variant: "primary",
          text: "Data berhasil ditambah!",
        });

        type === "update"
          ? setDataBarangJadi(newData)
          : setDataBarangJadi(newData);
        setIsEdit(false);
        setSubmitting(false);
        setProcessedData({});
      }, 300);
    };
    const formik = useFormik({
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    const {
      values,
      errors,
      touched,
      setValues,
      setFieldValue,
      handleSubmit,
      isSubmitting,
    } = formik;

    return (
      <tr>
        <Td colSpan={2}>
          <SelectSearch
            noMargin
            placeholder="Pilih barang jadi"
            option={dataSelectBarangJadi}
            defaultValue={
              values.id_item_atribut
                ? dataSelectBarangJadi?.find(
                    (val) => val.value === values.id_item_atribut
                  )
                : ""
            }
            onChange={(val) => {
              setValues({
                ...values,
                id_item_atribut: val.id_item_atribut,
                kode_item: val.kode_item,
                nama_item: val.nama_item,
                satuan: val.nama_satuan,
                harga_satuan: val.harga ?? 0,
              });
            }}
            error={Boolean(errors.id_item_atribut && touched.id_item_atribut)}
          />
        </Td>
        <Td width={80}>
          {/* <Input
            noMargin
            placeholder="Qty"
            value={values.qty}
            onChange={(e) => {
              const value = decimalConvert(e.target.value);
              setFieldValue("qty", value);
            }}
            error={Boolean(errors.qty && touched.qty)}
          /> */}
          <InputQtyDecimal
            value={values.qty}
            onChange={(e) => setFieldValue("qty", e)}
          />
        </Td>
        <Td>{values.satuan ?? "-"}</Td>
        <Td textRight>
          {
            RupiahConvert(String(parseFloat(values.harga_satuan ?? 0)))
              .getWithComa
          }
        </Td>
        <Td textRight>
          {
            RupiahConvert(
              String(
                parseFloat(values.qty ? values.qty : 0) *
                  parseFloat(values.harga_satuan ?? 0)
              )
            ).getWithComa
          }
        </Td>
        <Td>
          {type === "update" ? (
            <ButtonGroup style={{ width: "100%" }}>
              <ActionButton
                size="sm"
                variant="outline-danger"
                onClick={() => {
                  setIsEdit(false);
                  setProcessedData({});
                }}
                disable={isSubmitting}
              >
                <IoCloseOutline />
              </ActionButton>
              <ActionButton
                size="sm"
                variant="outline-success"
                onClick={handleSubmit}
                disable={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <IoCheckmark />
                )}
              </ActionButton>
            </ButtonGroup>
          ) : (
            <ActionButton
              size="sm"
              className="col"
              onClick={handleSubmit}
              disable={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <IoAddOutline />
              )}
            </ActionButton>
          )}
        </Td>
      </tr>
    );
  };

  const TableSection = () => {
    return (
      <>
        <div className="py-2 p-1">
          <b>List Barang Jadi</b>
        </div>

        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => setAlertConfig({ ...alertConfig, show: false })}
        />

        <table
          className="table table-sm table-bordered"
          style={{ fontSize: "14px" }}
        >
          <thead className="text-center bg-light">
            <tr>
              <th style={{ width: "100px" }}>Kode Barang Jadi</th>
              <th className="align-middle" style={{ width: "350px" }}>
                Barang Jadi
              </th>
              <th className="align-middle">Qty</th>
              <th className="align-middle">Satuan</th>
              <th className="align-middle">Harga Satuan</th>
              <th className="align-middle">Sub Total</th>
              {contextType !== "DETAIL" && (
                <th className="align-middle" style={{ width: "30px" }}>
                  Aksi
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {contextType !== "DETAIL" && <FormSection type="create" />}
            {dataBarangJadi?.map((val, index) => {
              const checkQty = val.qty ? parseFloat(val.qty) : 0;
              const checkSatuan = val.harga_satuan
                ? parseFloat(val.harga_satuan)
                : 0;
              const subTotal = parseFloat(checkQty * checkSatuan);

              return isEdit && index === processedData.index ? (
                <FormSection type="update" />
              ) : (
                <tr key={index}>
                  <td>{val.kode_item}</td>
                  <td>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setIndexAnalisa(val.unique);
                        setProcessedData({
                          ...val,
                          index: index,
                        });
                        setModalConfig({
                          show: true,
                          type: "analisa",
                          title: (
                            <span className="text-primary">
                              Analisa Barang Jadi RAE
                            </span>
                          ),
                        });
                      }}
                    >
                      {val.nama_item}
                    </a>
                  </td>
                  <td className="text-right">
                    {DecimalConvert(val.qty).getWithComa}
                  </td>
                  <td>{val.satuan}</td>
                  <td className="text-right">
                    {
                      RupiahConvert(
                        parseFloat(val.harga_satuan ?? 0).toString()
                      ).getWithComa
                    }
                  </td>
                  <td className="text-right">
                    {RupiahConvert(subTotal?.toString()).getWithComa}
                  </td>
                  {contextType !== "DETAIL" && (
                    <td>
                      <div className="btn-group p-0">
                        <ActionButton
                          size="sm"
                          text={<IoOptionsOutline />}
                          onClick={() => {
                            setIndexAnalisa(val.unique);
                            setProcessedData({
                              ...val,
                              index: index,
                            });
                            setModalConfig({
                              show: true,
                              type: "analisa",
                              title: (
                                <span className="text-primary">
                                  Analisa Barang Jadi RAE
                                </span>
                              ),
                            });
                          }}
                        />
                        <ActionButton
                          size="sm"
                          variant="success"
                          text={<IoPencilOutline />}
                          onClick={() => {
                            setIsEdit(true);
                            setProcessedData({
                              ...val,
                              index: index,
                            });
                          }}
                        />
                        <ActionButton
                          size="sm"
                          variant="danger"
                          text={<IoTrashOutline />}
                          onClick={() => {
                            setProcessedData({
                              ...val,
                              index: index,
                            });
                            setModalConfig({
                              show: true,
                              type: "delete",
                              title: (
                                <span className="text-danger">Hapus Data</span>
                              ),
                            });
                          }}
                        />
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {/* Total */}
            <tr className="bg-light">
              <td colSpan={5} className="text-right py-2 align-middle">
                <b>Grand Total :</b>
              </td>
              <td className="text-right align-middle">
                <b>{RupiahConvert(String(grandTotal)).getWithComa}</b>
              </td>
              {contextType !== "DETAIL" && <td></td>}
            </tr>
          </tfoot>
        </table>
      </>
    );
  };

  const ModalSection = ({
    processedData,
    modalConfig,
    setModalConfig,
    dataBarangJadi,
    setDataBarangJadi,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiRAE
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
          />
          {/* {TYPE !== "DETAIL" ? (
            <AnalisaBarangJadiRAE
              processedData={processedData}
              dataBarangJadi={dataBarangJadi}
              setDataBarangJadi={setDataBarangJadi}
              alertConfig={alertConfig}
              modalConfig={modalConfig}
              setAlertConfig={setAlertConfig}
              setModalConfig={setModalConfig}
            />
          ) : (
            <AnalisaBarangJadiRAEDetail
              processedData={processedData}
              dataBarangJadi={dataBarangJadi}
              setDataBarangJadi={setDataBarangJadi}
              alertConfig={alertConfig}
              modalConfig={modalConfig}
              setAlertConfig={setAlertConfig}
              setModalConfig={setModalConfig}
            />
          )} */}
        </Modal.Body>
      );
    };

    const DeleteModal = () => {
      const [isDeleting, setIsDeleting] = useState(false);

      const deleteDataHandler = () => {
        setIsDeleting(true);

        const finalDataBarangJadi = dataBarangJadi?.filter(
          (val) => val.unique !== processedData.unique
        );
        const finalDataAnalisaBarangJadi = dataAnalisaBarangJadi?.filter(
          (val) => val.unique !== processedData.unique
        );

        setTimeout(() => {
          setDataBarangJadi(finalDataBarangJadi);
          setDataAnalisaBarangJadi(finalDataAnalisaBarangJadi);
          setModalConfig({ ...modalConfig, show: false });
          setAlertConfig({
            show: true,
            variant: "primary",
            text: "Data berhasil dihapus!",
          });
        }, 300);
      };

      return (
        <>
          <Modal.Body className="text-center">
            <h5>
              <span>Hapus data dengan nama item bahan: </span>
              <br />
              <b>{processedData.nama_item}</b>
            </h5>
            <small className="text-danger">
              Data yang dihapus tidak dapat dikembalikan!
            </small>
            <div className="d-flex justify-content-center mt-3">
              <ActionButton
                variant="outline-secondary"
                className="m-1"
                text="Batal"
                onClick={() => setModalConfig({ ...modalConfig, show: false })}
              />
              <ActionButton
                variant="danger"
                className="m-1"
                text="Hapus Data"
                loading={isDeleting}
                onClick={deleteDataHandler}
              />
            </div>
          </Modal.Body>
        </>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === "delete" ? "md" : "xl"}
        onHide={() => setModalConfig({ ...modalConfig, show: false })}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        {modalConfig.type === "delete" ? (
          <DeleteModal />
        ) : (
          <AnalisaBarangJadiModal
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            setAlertConfig={setAlertConfig}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
          />
        )}
      </Modal>
    );
  };

  return (
    <>
      <TableSection />
      {modalConfig.show && (
        <ModalSection
          processedData={processedData}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
        />
      )}
    </>
  );
};

export default TableBarangJadi;
