// React
import React from "react";

// Form
import { useFormikContext } from "formik";

// Components
import { DatePicker, FilterModal, SelectMonth, SelectSearch } from "components";
import { Select } from "components2";

import { dateConvert } from "@bhawanadevteam/react-core";
import { range } from "lodash";
import LaporanRekapitulasiHasilProduksiApi from "../__LaporanRekapitulasiHasilProduksiApi__";
import { useQuery } from "react-query";

export const ModalFilter = ({ toggle }) => {
  const { values, handleSubmit, setValues, setFieldValue } = useFormikContext();

  const yearNow = dateConvert().getYear(new Date());
  const yearOptions = range(2000, parseInt(yearNow + 1))
    .map((val) => ({
      label: val,
      value: val,
    }))
    .reverse();

  const { data: dropdownJenis, isFetching: loadingDorpdownJenis } = useQuery(
    ["dorpdownJenis", "dropdown"],
    () => LaporanRekapitulasiHasilProduksiApi.getDropdownJenis()
  );

  const { data: dropdownUnitProduksi, isFetching: loadingUnitProduksi } =
    useQuery(["dropdownUnitProduksi", "dropdown"], () =>
      LaporanRekapitulasiHasilProduksiApi.getDrodpownUnitProduksi()
    );

  // HANDLE RESEt
  const onResetButtonClick = (setValues) => {
    const myPromise = new Promise((resolve) => {
      setValues({
        id_jenis: undefined,
        id_unit_produksi: undefined,
        bulan: undefined,
        tahun: undefined,
      });

      setTimeout(() => resolve(), 300);
    });

    myPromise.then(() => {});
  };

  return (
    <FilterModal
      isToggle
      toggleModal={toggle}
      onResetButtonClick={() => onResetButtonClick(setValues)}
      onFilterButtonClick={handleSubmit}
    >
      <SelectMonth
        label="Bulan"
        optionDefault
        defaultValue={values?.bulan}
        onChange={(val) => setFieldValue("bulan", val?.target?.value)}
      />

      <Select
        label="Tahun"
        noMargin
        options={yearOptions}
        defaultValue={yearOptions.find(
          (val) => parseInt(val.value) === parseInt(values.tahun)
        )}
        onChange={(val) => setFieldValue("tahun", val.value)}
      />

      <SelectSearch
        label="Jenis Barang Jadi"
        option={dropdownJenis}
        loading={loadingDorpdownJenis}
        placeholder="Pilih jenis barang jadi"
        defaultValue={
          values?.id_jenis
            ? dropdownJenis?.find((val) => val?.value === values?.id_jenis)
            : undefined
        }
        onChange={(val) => setFieldValue("id_jenis", val?.value)}
      />

      <SelectSearch
        label="Unit Produksi"
        option={dropdownUnitProduksi}
        loading={loadingUnitProduksi}
        placeholder="Pilih unit produksi"
        defaultValue={
          values?.id_unit_produksi
            ? dropdownJenis?.find(
                (val) => val?.value === values?.id_unit_produksi
              )
            : undefined
        }
        onChange={(val) => setFieldValue("id_unit_produksi", val?.value)}
      />
    </FilterModal>
  );
};
