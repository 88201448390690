import { Row, Col } from 'react-bootstrap';
import { InfoItemHorizontal } from 'components';
import { DateConvert, DecimalConvert, RupiahConvert } from 'utilities';

export const ModalHeadSection = ({ buaso, data }) => {
  const isUpahSubkon = buaso === 'upah' || buaso === 'subkon';

  const buasoTitle = {
    bahan: 'Bahan',
    upah: 'Upah',
    alat_mesin: 'Alat & Mesin',
    subkon: 'Subkon',
    overhead: 'Overhead',
  };

  return (
    <>
      <Row className="mb-1">
        <Col>
          <InfoItemHorizontal
            label="Tgl. Realisasi Work Order"
            text={
              data?.tgl_realisasi_work_order
                ? DateConvert(new Date(data?.tgl_realisasi_work_order)).detail
                : '-'
            }
          />
          <InfoItemHorizontal
            label="No. Realisasi Work Order"
            text={data?.no_realisasi_work_order}
          />
          {isUpahSubkon && (
            <InfoItemHorizontal label="Vendor" text={data?.nama_vendor} />
          )}
          <InfoItemHorizontal
            label={`Item ${buasoTitle[buaso]}`}
            text={data?.nama_item ?? data?.nama_item_aset}
          />
          <InfoItemHorizontal label="Satuan" text={data?.nama_satuan} />
          <InfoItemHorizontal
            label="Qty. Realisasi"
            text={
              DecimalConvert(data?.[`qty_realisasi_${buaso}`] ?? 0).getWithComa
            }
          />
          <InfoItemHorizontal
            label="Harga Satuan"
            text={
              RupiahConvert(
                String(
                  data?.harga_satuan ?? data?.[`harga_satuan_${buaso}`] ?? 0,
                ),
              ).getWithComa
            }
          />
        </Col>
      </Row>
      <hr />
    </>
  );
};
