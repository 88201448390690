import Services from "services";

class BDPAlatMesinApi {
  get(params) {
    return Services.get("/bdp/page", { params });
  }

  getSingle(params) {
    return Services.get("/bdp/detail", { params });
  }

  getSingleHistory(params) {
    return Services.get("/bdp_alat_mesin/history", { params });
  }

  getCOA() {
    return Services.get("/bdp/dropdown");
  }

  getNomor(params) {
    return Services.get("/bdp/no_baru", { params });
  }

  save(data) {
    return Services.post("/bdp", data);
  }
}

export default new BDPAlatMesinApi();
