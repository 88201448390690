import { ButtonCreate } from "@bhawanadevteam/react-core";
import {
  DatePicker,
  InfoItemHorizontal,
  Input,
  NumberFormat,
  SelectSearch,
  TextArea,
} from "components";
import { useFormikContext } from "formik";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { DateConvert } from "utilities";
import NilaiPerolehanProduksiAsetApi from "../__NilaiPerolehanProduksiAsetApi__";

export const ModalForm = ({ setModalConfig, modalConfig, action }) => {
  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormikContext();
  console.log(values);

  const { isFetching: loadingKode } = useQuery(
    ["nilaiPerolehan", "kode", values?.tgl_nilai_perolehan_aset],
    () =>
      NilaiPerolehanProduksiAsetApi.generateNumber({
        tanggal: values?.tgl_nilai_perolehan_aset,
      }).then((val) =>
        setValues({ ...values, no_nilai_perolehan_aset: val?.data?.data })
      ),
    {
      enabled: !!values?.tgl_nilai_perolehan_aset && action === "create",
    }
  );

  const { data: dropdownCOAKredit, isFetching: loadingDropdownCOAKredit } =
    useQuery(["dropdownCOAKredit", "dropdown"], () =>
      NilaiPerolehanProduksiAsetApi.getDropdownCOA({ normal_pos: "K" })
    );

  const { data: dropdownCOADebet, isFetching: loadingDropdownCOADebet } =
    useQuery(["dropdownCOADebet", "dropdown"], () =>
      NilaiPerolehanProduksiAsetApi.getDropdownCOA({ normal_pos: "D" })
    );

  return (
    <div>
      <Modal
        show={modalConfig?.show}
        onHide={() => setModalConfig({ show: false })}
        scrollable
      >
        <Modal.Header closeButton>
          Tambah Nilai Perolehan Produksi Aset
        </Modal.Header>
        <Modal.Body>
          <InfoItemHorizontal
            label="Tgl. Permintaan Aset"
            text={
              values?.tgl_permintaan_aset
                ? DateConvert(new Date(values?.tgl_permintaan_aset)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Permintaan Aset"
            text={values?.no_permintaan_aset ?? "-"}
          />
          <InfoItemHorizontal
            label="Tgl. Produksi Aset"
            text={
              values?.tgl_produksi_aset
                ? DateConvert(new Date(values?.tgl_produksi_aset)).detail
                : "-"
            }
          />
          <InfoItemHorizontal
            label="No. Produksi Aset"
            text={values?.no_produksi_aset ?? "-"}
          />
          <InfoItemHorizontal
            label="Grup Aset"
            text={values?.nama_grup_aset ?? "-"}
          />
          <InfoItemHorizontal
            label="Kategori Aset"
            text={values?.nama_kategori_aset ?? "-"}
          />
          <InfoItemHorizontal
            label="Jenis Aset"
            text={values?.nama_jenis_aset ?? "-"}
          />
          <InfoItemHorizontal
            label="Item Aset Yang Terproduksi"
            text={values?.nama_aset ?? "-"}
          />
          <hr />
          <Row>
            <Col lg="6" md="6">
              <DatePicker
                label="Tgl. Nilai Perolehan Produksi Aset"
                selected={
                  values?.tgl_nilai_perolehan_aset
                    ? new Date(values?.tgl_nilai_perolehan_aset)
                    : null
                }
                onChange={(val) =>
                  setValues({
                    ...values,
                    tgl_nilai_perolehan_aset: DateConvert(new Date(val))
                      .default,
                  })
                }
                error={
                  errors.tgl_nilai_perolehan_aset &&
                  touched.tgl_nilai_perolehan_aset &&
                  true
                }
                errorText={
                  errors.tgl_nilai_perolehan_aset &&
                  touched.tgl_nilai_perolehan_aset &&
                  errors.tgl_nilai_perolehan_aset
                }
              />
            </Col>
            <Col lg="6" md="6">
              <Input
                label="No. Nilai Perolehan Produksi Aset"
                disabled
                placeholder="Pilih tanggal terlebih dahulu!!!"
                loading={loadingKode}
                value={values?.no_nilai_perolehan_aset}
              />
            </Col>
          </Row>
          <NumberFormat
            label="Jumlah"
            thousandSeparator="."
            disabled
            value={parseFloat(values?.jumlah ?? 0)}
          />
          <SelectSearch
            label="COA Debet"
            option={dropdownCOADebet}
            loading={loadingDropdownCOADebet}
            placeholder="Pilih COA Debet"
            defaultValue={
              values?.nomor_coa_debet
                ? {
                    value: values?.nomor_coa_debet,
                    label: values?.nama_coa_debet,
                  }
                : undefined
            }
            onChange={(val) =>
              setValues({
                ...values,
                nomor_coa_debet: val.value,
                nama_coa_debet: val.label,
              })
            }
            error={errors.nomor_coa_debet && touched.nomor_coa_debet && true}
            errorText={
              errors.nomor_coa_debet &&
              touched.nomor_coa_debet &&
              errors.nomor_coa_debet
            }
          />

          <SelectSearch
            label="COA Kredit"
            option={dropdownCOAKredit}
            loading={loadingDropdownCOAKredit}
            placeholder="Pilih COA Kredit"
            defaultValue={
              values?.nomor_coa_kredit
                ? {
                    value: values?.nomor_coa_kredit,
                    label: values?.nama_coa_kredit,
                  }
                : undefined
            }
            onChange={(val) =>
              setValues({
                ...values,
                nomor_coa_kredit: val.value,
                nama_coa_kredit: val.label,
              })
            }
            error={errors.nomor_coa_kredit && touched.nomor_coa_kredit && true}
            errorText={
              errors.nomor_coa_kredit &&
              touched.nomor_coa_kredit &&
              errors.nomor_coa_kredit
            }
          />
          <TextArea
            label="Keterangan"
            rows="4"
            name="keterangan_jurnal"
            value={values.keterangan_jurnal}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonCreate
            text="Tambah"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </Modal>
    </div>
  );
};
