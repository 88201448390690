import { BDPProduksiApi } from "api";
import {
  ActionButton,
  DatePicker,
  SelectSearch,
  TBody,
  Table,
  Td,
  TdFixed,
  TextArea,
  Th,
  ThFixed,
  Tr,
} from "components";
import Thead from "components/Table/THead";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { DateConvert, DecimalConvert, RupiahConvert } from "utilities";
import { generateNameHandler } from "../Utils";
import { Formik } from "formik";

const ModalPostingBDP = ({
  setModalConfig,
  modalConfig,
  alertSuccessTrigger,
  alertErrorTrigger,
  refreshHandler,
}) => {
  const [dataCOA, setDataCOA] = useState([]);
  const [loadingCOA, setLoadingCOA] = useState(true);

  const generateName = generateNameHandler(modalConfig?.type);

  const getDataCOA = () => {
    setLoadingCOA(true);

    BDPProduksiApi.getCOA()
      .then((res) => {
        const data = res?.data?.data?.map((val) => ({
          value: val?.nomor_akun,
          label: `${val.nomor_akun} - ${val.nama_akun}`,
          tipe: val?.normal_pos,
        }));

        setDataCOA(data ?? []);
      })
      .catch(() => {
        setDataCOA([]);
      })
      .finally(() => setLoadingCOA(false));
  };

  useEffect(() => {
    getDataCOA();
  }, []);

  const formInitialValues = {
    tgl_bdp: "",
    keterangan_jurnal: "",
    bdp:
      modalConfig?.data.map((val) => ({
        ...val,
        nomor_akun_debet: "1010604",
        nama_akun_debet: "Barang Dalam Proses",
        nomor_akun_kredit: val.nomor_akun,
        nama_akun_kredit: val.nama_akun,
      })) ?? [],
  };

  const formSubmitHandler = (value) => {
    const finalValues = {
      ["tgl_bdp_" + modalConfig.type]: value.tgl_bdp,
      keterangan_jurnal: value.keterangan_jurnal,
      ["bdp_" + modalConfig.type]: value?.bdp?.map((val) => ({
        ["id_realisasi_produksi_" + modalConfig.type]: val.id,
        ["harga_satuan_" + modalConfig.type]: val.harga_satuan,
        nomor_akun_debet: val.nomor_akun_debet,
        nomor_akun_kredit: val.nomor_akun_kredit,
      })),
    };

    const saveData = () =>
      modalConfig.type === "bahan"
        ? BDPProduksiApi.saveBahan(finalValues)
        : modalConfig.type === "upah"
        ? BDPProduksiApi.saveUpah(finalValues)
        : modalConfig.type === "alat_mesin"
        ? BDPProduksiApi.saveAlatMesin(finalValues)
        : modalConfig.type === "subkon"
        ? BDPProduksiApi.saveSubkon(finalValues)
        : modalConfig.type === "overhead"
        ? BDPProduksiApi.saveOverhead(finalValues)
        : new Promise((_res, rej) => rej("bukan type buaso"));

    saveData()
      .then(() => {
        alertSuccessTrigger();
        refreshHandler();
      })
      .catch(() => {
        alertErrorTrigger();
      })
      .finally(() => {
        setModalConfig({ show: false, data: [] });
      });
  };
  return (
    <div>
      <Modal
        size="xl"
        onHide={() => setModalConfig({ show: false, data: [], type: "" })}
        show={modalConfig?.show}
      >
        <Modal.Header closeButton>Posting BDP {generateName}</Modal.Header>
        <Formik initialValues={formInitialValues} onSubmit={formSubmitHandler}>
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setValues,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <DatePicker
                  label={"Tgl. BDP " + generateName}
                  selected={
                    values?.tgl_bdp ? new Date(values?.tgl_bdp) : undefined
                  }
                  onChange={(date) => {
                    const parseDate = DateConvert(new Date(date)).default;
                    setFieldValue("tgl_bdp", parseDate);
                  }}
                  error={!!(errors?.tgl_bdp && touched?.tgl_bdp)}
                  errorText={
                    !!(errors?.tgl_bdp && touched?.tgl_bdp) && errors?.tgl_bdp
                  }
                />
                <TextArea
                  rows={4}
                  label="Keterangan Jurnal"
                  placeholder="Masukan keterangan jurnal"
                  name="keterangan_jurnal"
                  value={values?.keterangan_jurnal}
                  onChange={handleChange}
                />

                <hr />

                <div>
                  <b>List Item Realisasi {generateName}</b>
                </div>
                <Table>
                  <Thead>
                    <Tr>
                      <ThFixed>No.</ThFixed>
                      <ThFixed>Informasi Realisasi Produksi</ThFixed>
                      <Th>Item Bahan</Th>
                      <Th>Qty</Th>
                      <Th>Harga Satuan</Th>
                      <Th>Jumlah</Th>
                      <Th width={250}>COA Debet</Th>
                      <Th width={250}>COA Kredit</Th>
                    </Tr>
                  </Thead>
                  <TBody>
                    {values?.bdp?.map((val, ind) => (
                      <Tr key={ind}>
                        <TdFixed>{ind + 1}</TdFixed>
                        <TdFixed>
                          <div>
                            {
                              DateConvert(new Date(val?.tgl_realisasi))
                                .defaultDMY
                            }
                          </div>
                          <div>{val.no_realisasi ?? "-"}</div>
                        </TdFixed>
                        <Td>{val?.nama_item ?? "-"}</Td>
                        <Td className="text-right">
                          {
                            DecimalConvert(parseFloat(val?.qty_realisasi ?? 0))
                              .getWithComa
                          }
                        </Td>
                        <Td className="text-right">
                          {
                            RupiahConvert(
                              parseFloat(val.harga_satuan ?? 0).toString()
                            ).getWithComa
                          }
                        </Td>
                        <Td className="text-right">
                          {val?.harga_satuan && val?.qty_realisasi
                            ? RupiahConvert(
                                parseFloat(
                                  val?.harga_satuan * val?.qty_realisasi
                                ).toString()
                              ).getWithComa
                            : "-"}
                        </Td>
                        <Td>
                          <SelectSearch
                            loading={loadingCOA}
                            option={dataCOA}
                            placeholder="Pilih COA"
                            defaultValue={{
                              value: val?.nomor_akun_debet,
                              label: val?.nama_akun_debet,
                            }}
                            onChange={(item) => {
                              setValues({
                                ...values,
                                bdp: values?.bdp?.map((res, i) =>
                                  ind === i
                                    ? { ...res, nomor_akun_debet: item.value }
                                    : res
                                ),
                              });
                            }}
                          />
                        </Td>
                        <Td>
                          <SelectSearch
                            loading={loadingCOA}
                            option={dataCOA}
                            placeholder="Pilih COA"
                            defaultValue={{
                              value: val?.nomor_akun_kredit,
                              label: val?.nama_akun_kredit,
                            }}
                            onChange={(item) => {
                              setValues({
                                ...values,
                                bdp: values?.bdp?.map((res, i) =>
                                  ind === i
                                    ? { ...res, nomor_akun_kredit: item.value }
                                    : res
                                ),
                              });
                            }}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </TBody>
                </Table>
                <div className="d-flex justify-content-end">
                  <ActionButton size="sm" text="Simpan" type="submit" />
                </div>
              </Modal.Body>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default ModalPostingBDP;
