// React
import React, { useState, useEffect } from "react";

// Router
import { useHistory, useLocation } from "react-router-dom";

//Form
import { Formik } from "formik";
// Components
import { ButtonGroup, Modal, Button, Card, Nav, Tab } from "react-bootstrap";
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ReadButton,
  Table,
  Th,
  Td,
  InputSearch,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  BackButton,
  ActionButton,
  FilterButton,
} from "components";
import { PageNumber as TableNumber, DateConvert } from "utilities";

// API
import { AnalisaBarangJadiApi } from "api"; //JobMixDesignApi

// Icons
import { IoAddOutline } from "react-icons/io5";
import { ModalFilter } from "./components";
import {
  TabGambar,
  TabFile,
  TabAnalisa,
  SummarySection,
  Body,
  Divider,
  TabInfoDetail,
  TabKonversiDetail,
  ApprovalSection,
} from "./components";

const ListJobMixDesign = ({ setNavbarTitle }) => {
  // Hooks
  const history = useHistory();
  const location = useLocation();

  // Variables
  const filter = location?.state?.pagination;
  const title = "Analisa Barang Jadi";

  // States
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [processedData, setProcessedData] = useState();
  const [modalFilter, setModalFilter] = useState(false);
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.jobmix_design?.filter?.active,
      jenis_barang: location?.state?.jobmix_design?.filter?.jenis_barang,
    },
    pagination: {
      page: location?.state?.jobmix_design?.filter?.page ?? "1",
      dataLength: location?.state?.jobmix_design?.filter?.dataLength ?? "10",
      totalPage: location?.state?.jobmix_design?.filter?.totalPage ?? "1",
      dataCount: location?.state?.jobmix_design?.filter?.dataCount ?? "0",
    },
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    AnalisaBarangJadiApi.page_jobmix({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      jenis_barang: dataFilter?.filter?.jenis_barang,
    })
      .then((res) => {
        setData(res?.data?.data ?? []);
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data?.data_count,
            totalPage: res?.data?.total_page,
          },
        });
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };
  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };
  const dismissAlert = () => {
    let state = { ...history.location.state };
    delete state.alert;
    history.replace({ ...history.location, state });

    setAlertConfig({
      ...alertConfig,
      show: false,
    });
  };

  const onInputSearchChange = (e) => {
    const key = e.target.value;

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    });
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      },
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    });
  };
  useEffect(() => {
    setNavbarTitle(title);
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [setNavbarTitle]);
  useEffect(() => {
    getInitialData();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.jenis_barang,
  ]);

  const ModalDetail = () => {
    const [dataJobMix, setDataJobMix] = useState([]);

    const [detailLoading, setDetailLoading] = useState(false);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);

    const getDetailJobMix = () => {
      setDetailLoading(true);

      AnalisaBarangJadiApi.singleJobMix({ id_jobmix_design: processedData })
        .then((single) => {
          const dataSingle = single.data.data;
          setDataJobMix({
            ...dataSingle,
            nilai_konversi: parseFloat(dataSingle.nilai_konversi),
            path_gambar: dataSingle.path_gambar.map((val) => val.path_gambar),
          });
        })
        .catch(() => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Data gagal dimuat!",
          });
        })
        .finally(() => setDetailLoading(false));
    };
    useEffect(() => {
      getDetailJobMix();
    }, []);

    return (
      <Modal
        show={modalConfig.show}
        size="lg"
        onHide={() => setModalConfig({ ...modalConfig, show: false })}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>Detail Job Mix Design</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {detailLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat, tidak dapat mengubah data!" />
          ) : (
            <Formik
              enableReinitialize
              initialValues={{
                ...dataJobMix,
                nama_item: dataJobMix.nama_item_base,
              }}
            >
              {(formik) => {
                const totalSummary = () => {
                  return formik?.values?.analisa?.reduce(
                    (val, { harga, qty }) => {
                      const subtotal = Math.round(
                        parseFloat(qty ?? 0) * parseFloat(harga ?? 0)
                      );
                      return val + subtotal;
                    },
                    0
                  );
                };
                const summary = [
                  { buaso: "Bahan", total: totalSummary() },
                  { buaso: "Upah", total: 0 },
                  { buaso: "Alat & Mesin", total: 0 },
                  { buaso: "Subkon", total: 0 },
                  { buaso: "Overhead", total: 0 },
                ];
                const total = summary?.reduce((prev, { total }) => {
                  return prev + total;
                }, 0);

                return (
                  <form onSubmit={formik.handleSubmit}>
                    <Divider>
                      {/* <b>Detail Data Job Mix Design</b> */}
                      <Card>
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="tab-1"
                        >
                          <Card.Header className="d-flex justify-content-between align-items-center">
                            <Nav variant="tabs">
                              <Nav.Item>
                                <Nav.Link eventKey="tab-1">
                                  Informasi Umum
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="tab-4">
                                  Konversi Satuan
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="tab-2">Gambar</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="tab-3">File</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Card.Header>
                          <Tab.Content>
                            <Tab.Pane eventKey="tab-1">
                              <Body>
                                <TabInfoDetail />
                              </Body>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab-4">
                              <Body>
                                <TabKonversiDetail />
                              </Body>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab-2">
                              <Body>
                                <TabGambar readOnly />
                              </Body>
                            </Tab.Pane>
                            <Tab.Pane eventKey="tab-3">
                              <Body>
                                <TabFile readOnly />
                              </Body>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Card>
                    </Divider>

                    <Divider>
                      <div className="d-flex justify-content-between align-items-end">
                        <b>Data Analisa Barang Jadi</b>
                      </div>
                      <Card>
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="analisa-1"
                        >
                          <Card.Header className="d-flex justify-content-between align-items-center">
                            <Nav variant="tabs">
                              <Nav.Item>
                                <Nav.Link eventKey="analisa-1">Bahan</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Card.Header>
                          <Tab.Content>
                            <Tab.Pane eventKey="analisa-1">
                              <TabAnalisa
                                dropdown={{}}
                                id_buaso="1"
                                nama_buaso="Bahan"
                                readOnly
                              />
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </Card>
                    </Divider>

                    <Divider>
                      <SummarySection summary={summary} total={total} />
                    </Divider>

                    <Divider>
                      <ApprovalSection data={dataJobMix} />
                    </Divider>
                  </form>
                );
              }}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Kode Barang</ThFixed>
            <Th style={{ minWidth: "250px" }}>Nama Barang</Th>
            <Th style={{ width: "150px" }}>Jenis Barang</Th>
            <ThFixed>Update Terakhir</ThFixed>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(
                  dataFilter?.pagination?.page,
                  dataFilter?.pagination?.dataLength,
                  index
                )}
              </TdFixed>
              <TdFixed>{val.kode_item ?? "-"}</TdFixed>
              <Td>
                {val.nama_item ?? "-"} [{val.nama_varian ?? "-"}]
              </Td>
              <Td>{val.nama_jenis ?? "-"}</Td>
              <TdFixed>
                {val.tgl_approval
                  ? DateConvert(new Date(val.tgl_approval)).defaultDMY
                  : "-"}
              </TdFixed>
              <TdFixed className="align-middle">
                <ButtonGroup>
                  <ReadButton
                    size="sm"
                    onClick={() => {
                      setModalConfig({ ...modalConfig, show: true });
                      setProcessedData(val.id_jobmix_design);
                    }}
                    // onClick={() =>
                    //   history.push(`/master/job-mix-design/detail/${val.id_jobmix_design}`, {
                    //     pagination: {
                    //       ...filter,
                    //       jobmix: dataFilter,
                    //     },
                    //   })
                    // }
                  />
                  <ActionButton
                    size="sm"
                    variant="primary"
                    iconOnly
                    block
                    tooltip
                    tooltipText="Tambah Data"
                    onClick={() =>
                      history.push(
                        `/master/analisa-barang-jadi/tambah/${val.id_jobmix_design}`,
                        { ...location?.state, jobmix_design: dataFilter }
                      )
                    }
                  >
                    <IoAddOutline size="14" />
                  </ActionButton>
                </ButtonGroup>
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <b>{`List Data Job Mix Design Yang Siap Dibuatkan ${title}`}</b>
        <DataTable />
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength -
            dataFilter?.pagination?.dataLength +
            1
          }
          dataPage={
            dataFilter?.pagination?.dataCount <
            dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page *
                dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              },
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              value={searchConfig.key}
              onChange={onInputSearchChange}
            />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <BackButton
            size="md"
            className="mb-1"
            onClick={() =>
              history.push("/master/analisa-barang-jadi", {
                ...location?.state,
                jobmix_design: dataFilter,
              })
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <div style={{ minHeight: "500px" }}>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => dismissAlert()}
        />

        {isPageLoading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : (
          <PageContent />
        )}
        {modalFilter && (
          <ModalFilter
            show={modalFilter}
            setShow={setModalFilter}
            data={dataFilter}
            setData={setDataFilter}
            isJobmix
          />
        )}

        <ModalDetail
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </div>
    </CRUDLayout>
  );
};

export default ListJobMixDesign;
