import { useState } from 'react';
import { Form, Modal, Row, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ActionButton,
  Alert,
  InfoItemVertical,
  InfoItemHorizontal,
  DatePicker,
  TextArea,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  DataStatus,
} from 'components';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';
import { TransferOverheadWorkOrderApi } from 'api';
import { DateConvert, DecimalConvert } from 'utilities';

export const ModalFormTransfer = ({
  idWorkOrder = null,
  dataPermintaan = [],
  data,
  toggle,
  isCreate,
  title,
  toggleSuccess,
  getLastQtyTransfer,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  return (
    <Modal show={true} onHide={toggle} size={isCreate ? 'lg' : 'md'}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h6 className="m-0">
            {isCreate ? 'Tambah' : 'Detail'} {title}
          </h6>
        </Modal.Title>
      </Modal.Header>

      <Formik
        enableReinitialize
        initialValues={{
          ...data,
          id_work_order: idWorkOrder,
          tgl_transfer_work_order_overhead: isCreate
            ? undefined
            : data?.tgl_transfer_work_order_overhead,
          no_transfer_work_order_overhead: isCreate
            ? 'Pilih tanggal terlebih dahulu'
            : data?.no_transfer_work_order_overhead,
          qty_transfer_work_order_overhead: isCreate
            ? '0'
            : data?.qty_transfer_work_order_overhead,
          keterangan_transfer: isCreate ? '' : data?.keterangan_transfer,
          qty_telah_ditransfer:
            data?.qty_transfer_work_order_overhead ??
            data?.qty_telah_ditransfer_work_order_overhead ??
            0,
        }}
        validationSchema={yup.object().shape({
          tgl_transfer_work_order_overhead: yup
            .date()
            .typeError('Masukkan Tanggal')
            .required('Masukkan Tanggal'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          if (isCreate) {
            TransferOverheadWorkOrderApi.save(values)
              .then(() => {
                if (getLastQtyTransfer?.length <= 1) {
                  history.replace(
                    '/transaksi/transfer-overhead-work-order',
                    location.state,
                  );
                } else {
                  toggleSuccess();
                  toggle();
                }
              })
              .catch(() => {
                setAlertConfig({
                  show: true,
                  text: 'Tambah data gagal',
                  variant: 'danger',
                });
              })
              .finally(() => setSubmitting(false));
          }
        }}
      >
        {({ values, handleSubmit, isSubmitting, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Alert
                show={alertConfig.show}
                variant={alertConfig.variant}
                text={alertConfig.text}
                showCloseButton={true}
                onClose={() => {
                  setAlertConfig({
                    ...alertConfig,
                    show: false,
                  });
                }}
              />

              {!isCreate && (
                <>
                  <InfoItemHorizontal
                    label="Tgl. Permintaan Work Order"
                    text={
                      values?.tgl_permintaan_work_order_overhead
                        ? DateConvert(
                            new Date(values.tgl_permintaan_work_order_overhead),
                          ).detail
                        : '-'
                    }
                  />

                  <InfoItemHorizontal
                    label="No. Permintaan Work Order"
                    text={values.no_permintaan_work_order_overhead ?? '-'}
                  />

                  <InfoItemHorizontal
                    label="Item Overhead"
                    text={values.nama_item ?? '-'}
                  />

                  <InfoItemHorizontal
                    label="Satuan"
                    text={values.nama_satuan ?? '-'}
                  />

                  <InfoItemHorizontal
                    label="Qty. Permintaan Work Order"
                    text={
                      DecimalConvert(
                        values.qty_permintaan_work_order_overhead ?? 0,
                      ).getWithComa
                    }
                  />

                  <InfoItemHorizontal
                    label="Qty. Telah Ditransfer"
                    text={
                      DecimalConvert(
                        data?.qty_transfer_work_order_overhead ?? 0,
                      ).getWithComa
                    }
                  />

                  <hr />
                </>
              )}

              <FormSection
                dataPermintaan={dataPermintaan}
                isCreate={isCreate}
              />
            </Modal.Body>

            {isCreate && (
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  text="Simpan"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export const FormSection = ({ dataPermintaan, isCreate }) => {
  const { values, setFieldValue, errors, touched, handleChange } =
    useFormikContext();

  const getNomor = (tgl) => {
    TransferOverheadWorkOrderApi.no_baru(tgl).then((res) => {
      setFieldValue('no_transfer_work_order_overhead', res.data.data ?? '');
    });
  };

  if (!isCreate) {
    return (
      <>
        <Row>
          <Col md={6} sm={6}>
            <InfoItemVertical
              className="text-capitalize"
              label="Tgl. Transfer Overhead Work Order"
              text={
                values?.tgl_transfer_work_order_overhead
                  ? DateConvert(
                      new Date(values?.tgl_transfer_work_order_overhead),
                    ).detail
                  : '-'
              }
            />
          </Col>

          <Col md={6} sm={6}>
            <InfoItemVertical
              className="text-capitalize"
              label="No. Transfer Overhead Work Order"
              text={values?.no_transfer_work_order_overhead ?? '-'}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <InfoItemVertical
              label="Qty. Transfer"
              text={`${
                DecimalConvert(values.qty_transfer_work_order_overhead ?? 0)
                  .getWithComa
              } ${values.nama_satuan ?? ''}`}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <InfoItemVertical
              label="Keterangan"
              text={values.keterangan_transfer ?? '-'}
            />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <Row>
      <Col md={12}>
        <DatePicker
          label="Tgl. Transfer Overhead Work Order"
          dateFormat="dd/MM/yyyy"
          selected={
            values.tgl_transfer_work_order_overhead
              ? new Date(values.tgl_transfer_work_order_overhead)
              : ''
          }
          placeholderText="Pilih Tgl. Transfer Overhead Work Order "
          onChange={(date) => {
            const tanggal = DateConvert(new Date(date)).default;

            setFieldValue('tgl_transfer_work_order_overhead', tanggal);
            if (isCreate) getNomor(tanggal);
          }}
          error={
            Boolean(errors.tgl_transfer_work_order_overhead) &&
            touched.tgl_transfer_work_order_overhead
          }
          errorText={
            Boolean(errors.tgl_transfer_work_order_overhead) &&
            touched.tgl_transfer_work_order_overhead &&
            errors.tgl_transfer_work_order_overhead
          }
        />
      </Col>

      <Col md={12}>
        <TextArea
          label="Keterangan Transfer"
          name="keterangan_transfer"
          rows={4}
          placeholder="Keterangan Transfer"
          value={values.keterangan_transfer}
          onChange={handleChange}
          error={
            Boolean(errors.keterangan_transfer) && touched.keterangan_transfer
          }
          errorText={
            Boolean(errors.keterangan_transfer) &&
            touched.keterangan_transfer &&
            errors.keterangan_transfer
          }
        />
      </Col>

      <Col>
        <div className="mt-2 font-weight-bold" style={{ fontSize: '14px' }}>
          List Data Komponen Overhead
        </div>

        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Permintaan Work Order</ThFixed>
              <Th>Item Overhead</Th>
              <ThFixed>Qty. Permintaan </ThFixed>
            </Tr>
          </THead>

          <TBody>
            {dataPermintaan?.length > 0 ? (
              dataPermintaan?.map((val, index) => {
                const qty_permintaan_work_order = parseFloat(
                  val.qty_permintaan_work_order_overhead ?? 0,
                );

                return (
                  <Tr key={index}>
                    <TdFixed textCenter>{index + 1}</TdFixed>
                    <Td>
                      <div>
                        {val.tgl_permintaan_work_order_overhead
                          ? DateConvert(
                              new Date(val.tgl_permintaan_work_order_overhead),
                            ).defaultDMY
                          : '-'}
                      </div>
                      <div>{val.no_permintaan_work_order_overhead ?? '-'}</div>
                    </Td>

                    <Td>{val.nama_item ?? '-'}</Td>

                    <Td className="text-right">
                      {DecimalConvert(qty_permintaan_work_order).getWithComa}{' '}
                      {val.nama_satuan ?? '-'}
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td colSpan={8}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            )}
          </TBody>
        </Table>
      </Col>
    </Row>
  );
};
