import React from "react";
import { Card, Nav, Tab, Tabs } from "react-bootstrap";
import {
  TableCommulativeCost,
  TableCommulativeProgress,
  TableInformasiUmum,
} from "../Comp";

export const TabSection = ({ data }) => {
  const groupedData = data.reduce((acc, curr) => {
    if (acc[curr.no_sales_order]) {
      acc[curr.no_sales_order].count++;
      acc[curr.no_sales_order].data.push(curr);
    } else {
      acc[curr.no_sales_order] = { count: 1, data: [curr] };
    }
    return acc;
  }, {});

  const tableData = Object.values(groupedData).flatMap(({ count, data }) =>
    data.map((item, index) => ({
      ...item,
      rowspan: index === 0 ? count : 0,
    }))
  );

  return (
    <Card className="p-0 m-0">
      <Tab.Container defaultActiveKey="informasiUmum">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="informasiUmum">Informasi Umum</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="cummulativeProgress">
                Cummulative Progress
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="cummulativeCost">Cummulative Cost</Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Tab.Content>
          <Tab.Pane eventKey="informasiUmum" className="p-2">
            <TableInformasiUmum tableData={tableData} />
          </Tab.Pane>
          <Tab.Pane eventKey="cummulativeProgress" className="p-2">
            <TableCommulativeProgress tableData={tableData} />
          </Tab.Pane>
          <Tab.Pane eventKey="cummulativeCost" className="p-2">
            <TableCommulativeCost tableData={tableData} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Card>
  );
};
