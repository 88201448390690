import Services from "services";

const Mock = {
  get: (url, params) =>
    new Promise((res, rej) =>
      setTimeout(() => {
        switch (url) {
          case "/posting_petty_cash/page":
            res({
              data: {
                data: [
                  {
                    nama_item: "coba keperluan realisasi",
                    qty: "1",
                    nama_satuan: "Liter",
                    harga_satuan: "10000",
                    keterangan: "biaya bensin",
                    files: [
                      {
                        link: "https://dummyimage.com/600x400/000/fff&text=item1",
                      },
                      {
                        link: "https://dummyimage.com/600x400/000/fff&text=item2",
                      },
                    ],
                  },
                  {
                    nama_item: "coba keperluan realisasi2",
                    qty: "2",
                    nama_satuan: "Liter",
                    harga_satuan: "10000",
                    keterangan: "biaya bensin2",
                    files: [
                      {
                        link: "https://dummyimage.com/600x400/000/fff&text=test",
                      },
                      {
                        link: "https://dummyimage.com/600x400/000/fff&text=test2",
                      },
                    ],
                  },
                ],
                total_page: 1,
              },
            });
            break;

          case "/v1/example/single":
            res({
              data: {
                tanggal: "2020-01-01",
                keterangan: "",
                karyawan: Array.from({ length: 2 }).map((_, i) => ({
                  id: i + 1,
                  nama: `User ${i + 1}`,
                })),
                detail: Array.from({ length: 50 }).map((_, i) => ({
                  barang: `Barang ${i}`,
                  qty: i + 10,
                  harga: i + 1000,
                })),
              },
            });
            break;

          case "/posting_petty_cash/d_proyek":
            res({
              data: Array.from({ length: 50 }).map((_, i) => ({
                id: i,
                nama: `User ${i}`,
              })),
            });
            break;

          case "/posting_petty_cash/no_baru":
            res({
              data: { data: "2022/02/HRDU/PC/000001" },
            });
            break;

          default:
            res({});
        }
      }, 500)
    ),
  post: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res("berhasil"), 500)),
  patch: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res("berhasil"), 500)),
  put: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res("berhasil"), 500)),
  delete: (url, params) =>
    new Promise((res, rej) => setTimeout(() => res("berhasil"), 500)),
};

class PostingPettyCashApi {
  async getList(params) {
    const fetch = await Services.get("/posting_petty_cash/page", { params });
    return fetch.data;
  }

  async getSingle(params) {
    const fetch = await Services.get("posting_petty_cash/single", { params });
    return fetch.data;
  }

  async getListRealisasi(params) {
    const fetch = await Services.get("/posting_petty_cash/realisasi_list", {
      params,
    });
    return fetch.data;
  }

  async getSingleRealisasi(params) {
    const fetch = await Services.get("/posting_petty_cash/single_realisasi", {
      params,
    });
    return fetch.data;
  }

  async getDropdownCOA() {
    const fetch = await Services.get("/posting_petty_cash/dropdown");
    return fetch?.data?.data?.map((val) => ({
      label: `${val.id_coa} - ${val.nama_akun}`,
      value: val.id_coa,
      nama_akun: val.nama_akun,
    }));
  }

  generateNumber(params) {
    return Services.get("/posting_petty_cash/no_baru", { params });
  }

  create(data) {
    return Services.post("/posting_petty_cash", data);
  }

  revise(data) {
    return Services.post("/posting_petty_cash/revisi", data);
  }

  export(params) {
    return Services.get("/posting_petty_cash/export/", { params });
  }
}

export default new PostingPettyCashApi();
