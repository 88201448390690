import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { InfoItemHorizontal, InfoItemVertical } from "components";
import { DateConvert } from "utilities";

const InformasiUmum = ({ data }) => {
  const STATUS = {
    "on progress": ["ON PROGRESS", "text-warning font-weight-bold"],
    close: ["CLOSED", "text-success font-weight-bold"],
    pending: ["PENDING", "text-secondary font-weight-bold"],
  };

  return (
    <Card className="mb-4">
      <Card.Body>
        <Row>
          <Col lg>
            <table>
              <tbody>
                <InfoItemHorizontal
                  label="Tgl. Sales Order"
                  text={
                    data.tgl_sales_order
                      ? DateConvert(new Date(data?.tgl_sales_order)).detail
                      : "-"
                  }
                />
                <InfoItemHorizontal
                  label="No. Sales Order"
                  text={data?.no_sales_order ?? "-"}
                />
                <InfoItemHorizontal
                  label="Barang Jadi"
                  text={data?.nama_item ?? "-"}
                />
                <InfoItemHorizontal
                  label="Customer"
                  text={data?.nama_customer ?? "-"}
                />
              </tbody>
            </table>
          </Col>

          <Col lg>
            <table>
              <tbody>
                <InfoItemHorizontal
                  label="Qty. Sales Order"
                  text={
                    data.qty_sales_order
                      ? `${parseFloat(data.qty_sales_order ?? 0)} ${
                          data.kode_satuan
                        }`
                      : "-"
                  }
                />
                <InfoItemHorizontal
                  label="Qty. Sedang Diproduksi"
                  text={
                    data.qty_sedang_diproduksi
                      ? `${parseFloat(data.qty_sedang_diproduksi ?? 0)} ${
                          data.kode_satuan
                        }`
                      : "-"
                  }
                />
                <InfoItemHorizontal
                  label="Qty. Telah Direalisasi"
                  text={
                    data.qty_telah_direalisasi
                      ? `${parseFloat(data.qty_telah_direalisasi ?? 0)} ${
                          data.kode_satuan
                        }`
                      : "-"
                  }
                />
              </tbody>
            </table>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>
            <Row>
              <Col>
                <InfoItemVertical
                  label="Tgl. Produksi"
                  text={
                    data?.tgl_jobmix
                      ? DateConvert(new Date(data.tgl_jobmix)).detail
                      : ""
                  }
                />
              </Col>
              <Col>
                <InfoItemVertical
                  label="No. Produksi"
                  text={data?.no_jobmix ?? "-"}
                />
              </Col>
            </Row>
          </Col>

          <Col md={{ span: 4, offset: 2 }}>
            <Row>
              <Col>
                <InfoItemVertical
                  label="Qty. Produksi"
                  text={
                    data.qty_jobmix
                      ? `${parseFloat(data.qty_jobmix ?? 0)} ${
                          data.kode_satuan
                        }`
                      : "-"
                  }
                />
              </Col>
              <Col>
                <InfoItemVertical
                  label="Status Progress"
                  text={
                    <h6
                      className={STATUS[data?.status_progress ?? "pending"][1]}
                    >
                      {STATUS[data?.status_progress ?? "pending"][0]}
                    </h6>
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InformasiUmum;
