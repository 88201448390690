/**
 * Page Detail Produksi (Detail Preform)
 */

import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Card, Nav, Tab } from "react-bootstrap";
import { BackButton, DataStatus } from "components";
import { HPPApi } from "api";
import {
  InformasiUmum,
  TabItem,
  RangkumanBiaya,
} from "./components/DetailProduksi";

const DetailProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id: id_jobmix } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataInfo, setDataInfo] = useState({});
  const [komponenUtama, setKomponenUtama] = useState({
    bahan: [],
    upah: [],
    alat_mesin: [],
    subcont: [],
    overhead: [],
  });
  const [komponenTambahan, setKomponenTambahan] = useState({
    bahan: [],
    upah: [],
    alat_mesin: [],
    subcont: [],
    overhead: [],
  });

  // Filter data sbg Komponen Utama / Komponen Tambahan, lalu map.
  const filterMapData = (flag, data = []) => {
    return data
      .filter((item) => item.flag === flag)
      .map((item) => ({
        nama_item: item.nama_item,
        qty_produksi: item.qty_produksi,
        nama_satuan: item.nama_satuan,
        kode_satuan: item.kode_satuan,
        harga_satuan:
          item.harga_satuan_bahan ??
          item.harga_satuan_upah ??
          item.biaya_penyusutan_per_jam ??
          item.harga_satuan_subcont ??
          item.harga_satuan_overhead,
      }));
  };

  const getInitialData = () => {
    setIsPageLoading(true);

    HPPApi.detail_produksi({ id_jobmix })
      .then((res) => {
        const data = res.data.data ?? {};

        setDataInfo({
          tgl_sales_order: data.tgl_sales_order,
          no_sales_order: data.no_sales_order,
          nama_item: data.nama_item,
          nama_customer: data.nama_customer,
          qty_sales_order: data.qty_item,
          qty_sedang_diproduksi: data.qty_sedang_diproduksi,
          qty_telah_direalisasi: data.qty_produksi,
          nama_gudang: data.nama_gudang,
          tgl_jobmix: data.tgl_jobmix,
          no_jobmix: data.no_jobmix,
          qty_jobmix: data.qty_jobmix,
          kode_satuan: data.kode_satuan,
          status_progress: data.status_progress,
          nama_unit_produksi: data.nama_unit_produksi,
        });

        setKomponenUtama({
          bahan: filterMapData("UT", data.analisa_bahan),
          upah: filterMapData("UT", data.analisa_upah),
          alat_mesin: filterMapData("UT", data.analisa_alat_mesin),
          subcont: filterMapData("UT", data.analisa_subcont),
          overhead: filterMapData("UT", data.analisa_overhead),
        });

        setKomponenTambahan({
          bahan: filterMapData("TB", data.analisa_bahan),
          upah: filterMapData("TB", data.analisa_upah),
          alat_mesin: filterMapData("TB", data.analisa_alat_mesin),
          subcont: filterMapData("TB", data.analisa_subcont),
          overhead: filterMapData("TB", data.analisa_overhead),
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle("Harga Pokok Produksi");
    getInitialData();

    return () => {};
  }, []);

  if (isPageLoading) {
    return <DataStatus loading={isPageLoading} text="Memuat data . . ." />;
  }

  return (
    <>
      <div className="text-right">
        <BackButton onClick={() => history.goBack()} />
      </div>

      {/*  Info Section */}
      <div className="font-weight-bold mb-1">Informasi Umum</div>
      <InformasiUmum data={dataInfo} />

      {/* Tabs Komponen Utama */}
      <div className="mt-3">
        <div className="font-weight-bold mb-1">Komponen Utama</div>
        <Card>
          <Tab.Container defaultActiveKey="bahan">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="bahan">Bahan</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="upah">Upah</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="alat_mesin">Alat & Mesin</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="subkon">Subkon</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="overhead">Overhead</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>

            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="bahan">
                  <TabItem data={komponenUtama.bahan} />
                </Tab.Pane>
                <Tab.Pane eventKey="upah">
                  <TabItem data={komponenUtama.upah} />
                </Tab.Pane>
                <Tab.Pane eventKey="alat_mesin">
                  <TabItem data={komponenUtama.alat_mesin} />
                </Tab.Pane>
                <Tab.Pane eventKey="subkon">
                  <TabItem data={komponenUtama.subcont} />
                </Tab.Pane>
                <Tab.Pane eventKey="overhead">
                  <TabItem data={komponenUtama.overhead} />
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>
      </div>

      {/* Tabs Komponen Tambahan */}
      <div className="mt-3">
        <div className="font-weight-bold mb-1">Komponen Tambahan</div>
        <Card>
          <Tab.Container defaultActiveKey="bahan">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="bahan">Bahan</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="upah">Upah</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="alat_mesin">Alat & Mesin</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="subkon">Subkon</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="overhead">Overhead</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>

            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="bahan">
                  <TabItem data={komponenTambahan.bahan} />
                </Tab.Pane>
                <Tab.Pane eventKey="upah">
                  <TabItem data={komponenTambahan.upah} />
                </Tab.Pane>
                <Tab.Pane eventKey="alat_mesin">
                  <TabItem data={komponenTambahan.alat_mesin} />
                </Tab.Pane>
                <Tab.Pane eventKey="subkon">
                  <TabItem data={komponenTambahan.subcont} />
                </Tab.Pane>
                <Tab.Pane eventKey="overhead">
                  <TabItem data={komponenTambahan.overhead} />
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Tab.Container>
        </Card>
      </div>

      {/* Rangkuman Biaya */}
      <div className="mt-3">
        <div className="font-weight-bold mb-1">Rangkuman Biaya</div>
        <RangkumanBiaya
          komponenTambahan={komponenTambahan}
          komponenUtama={komponenUtama}
        />
      </div>
    </>
  );
};

export default DetailProduksi;
