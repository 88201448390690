/* Table Front Page Posting Realisasi Work Order*/

import {
  CRUDLayout,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  Td,
  TdFixed,
  Pagination,
  ReadButton,
  DataStatus,
  InputSearch,
  FilterButton,
  Alert,
} from "components";
import { useContext, useState } from "react";
import {
  PageNumber,
  DateConvert,
  RupiahConvert,
  DecimalConvert,
} from "utilities";
import { calcTotalSparepart, getTotal } from "../utils";
import { PostingRealisasiContext } from "../context";
import { debounce } from "lodash";
import { ModalFilterPostingRealisasiWorkOrder } from "./ModalFilterPostingRealisasiWorkOrder";
import { withDropdownProvider } from "../context";
import { ExportButton } from "./ExportButton";
import { Dropdown } from "react-bootstrap";
import FileSaver from "file-saver";
import { PostingRealisasiWorkOrderApi } from "api";

export const TablePostingRealisasi = withDropdownProvider(
  ({
    buaso,
    pagination,
    setPagination,
    initialPagination,
    data,
    isPageLoading,
    searchConfig,
    setSearchConfig,
    modalFilter,
    setModalFilter,
    dataFilter,
    setDataFilter,
  }) => {
    const { setModalFormPosting } = useContext(PostingRealisasiContext);
    const [loadingExport, setLoadingExport] = useState(false);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: "primary",
      text: "",
    });
    const [searchTime, setSearchTime] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const isUpahSubkon = buaso === "upah" || buaso === "subkon";
    const buasoTitle = {
      bahan: "Bahan",
      upah: "Upah",
      alat_mesin: "Alat & Mesin",
      subkon: "Subkon",
      overhead: "Overhead",
    };

    const toggleModalFilter = () => setModalFilter(!modalFilter);

    const searchHandler = (e) => {
      const key = e.target.value;
      setSearchValue(key);
      if (searchTime) clearTimeout(searchTime);
      setSearchTime(
        setTimeout(() => {
          setSearchConfig((prev) => ({
            ...prev,
            [buaso]: { ...prev?.[buaso], key },
          }));
          setAlertConfig({
            show: true,
            text: `Hasil Pencarian : ${key}`,
            variant: "primary",
          });
          setPagination(initialPagination);
        }, 750)
      );
    };

    const exportData = (tipe) => {
      setLoadingExport(true);

      PostingRealisasiWorkOrderApi.export({
        tipe,
        ...dataFilter?.[buaso],
        buaso,
      })
        .then((res) => {
          const data = res.data.data;

          FileSaver.saveAs(data, data);
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: "danger",
            text: "Export Data gagal",
          });
        })
        .finally(() => setLoadingExport(false));
    };

    if (isPageLoading) {
      return <DataStatus loading text="Memuat data..." />;
    }

    return (
      <div className="m-2">
        <div className="d-flex">
          <InputSearch onChange={searchHandler} value={searchValue} />
          <FilterButton
            active={dataFilter?.[buaso]?.active}
            onClick={toggleModalFilter}
          />
          <ExportButton loading={loadingExport}>
            <Dropdown.Item onClick={() => exportData("excel")}>
              Excel (.xlsx)
            </Dropdown.Item>
          </ExportButton>
        </div>

        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
        />

        <div className="mt-3 font-weight-bold">
          List Data Posting Realisasi Work Order
        </div>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No.</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th width={150}>
                Informasi Posting Realisasi {buasoTitle[buaso]} Work Order
              </Th>
              <Th width={150}>Informasi Realisasi Work Order</Th>
              <Th>Item {buasoTitle[buaso]}</Th>
              {isUpahSubkon && <Th>Vendor</Th>}
              <Th width={100}>Qty.</Th>
              <Th width={100}>Harga Satuan</Th>
              <Th width={100}>Jumlah</Th>
              <Th width={100}>Akun COA</Th>
              <Th width={100}>Keterangan Jurnal</Th>
            </Tr>
          </THead>
          <TBody>
            {!!data?.length ? (
              data?.map((val, index) => {
                const totalSparepart =
                  calcTotalSparepart(val?.sparepart, buaso) ?? 0;

                const hargaSatuan =
                  val?.harga_satuan ?? val?.[`harga_satuan_${buaso}`] ?? 0;

                return (
                  <Tr key={index}>
                    <TdFixed>
                      {PageNumber(pagination.page, pagination.per_page, index)}
                    </TdFixed>

                    <TdFixed>
                      <ReadButton
                        size="sm"
                        onClick={() => {
                          setModalFormPosting({
                            buaso,
                            data: val,
                            show: true,
                            isCreate: false,
                          });
                        }}
                      />
                    </TdFixed>

                    <Td>
                      <div>
                        {val?.[`tgl_posting_realisasi_work_order_${buaso}`]
                          ? DateConvert(
                              new Date(
                                val?.[
                                  `tgl_posting_realisasi_work_order_${buaso}`
                                ]
                              )
                            ).defaultDMY
                          : "-"}
                      </div>
                      <div>
                        {val?.[`no_posting_realisasi_work_order_${buaso}`] ??
                          "-"}
                      </div>
                    </Td>

                    <Td>
                      <div>
                        {val?.tgl_realisasi_work_order
                          ? DateConvert(new Date(val?.tgl_realisasi_work_order))
                              .defaultDMY
                          : "-"}
                      </div>
                      <div>{val?.no_realisasi_work_order ?? "-"}</div>
                    </Td>

                    <Td>{val.nama_item ?? val.nama_item_aset ?? "-"}</Td>

                    {isUpahSubkon && <Td>{val.nama_vendor ?? "-"}</Td>}

                    <Td>
                      {DecimalConvert(val[`qty_realisasi_${buaso}`])
                        .getWithComa ?? "-"}{" "}
                      {val?.nama_satuan ?? ""}
                    </Td>

                    <Td className="text-right">
                      {
                        RupiahConvert(parseFloat(hargaSatuan).toString())
                          .getWithComa
                      }
                    </Td>

                    <Td className="text-right">
                      {
                        RupiahConvert(
                          String(
                            parseFloat(totalSparepart ?? 0) +
                              getTotal({
                                qty: val[`qty_realisasi_${buaso}`],
                                harga: hargaSatuan,
                              })
                          )
                        ).getWithComa
                      }
                    </Td>

                    <Td className="text-nowrap">
                      <span className="d-block">{`D: ${val.nomor_akun_debet} - ${val.nama_akun_debet}`}</span>
                      <span className="d-block">{`K: ${val.nomor_akun_kredit} - ${val.nama_akun_kredit}`}</span>
                    </Td>

                    <Td>{val.keterangan_jurnal ?? "-"}</Td>
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td colSpan={isUpahSubkon ? 10 : 9}>
                  <DataStatus text="Tidak ada data" />
                </Td>
              </Tr>
            )}
          </TBody>
        </CRUDLayout.Table>

        {!searchConfig.status && !isPageLoading && !!data?.length && (
          <Pagination
            dataLength={pagination.per_page}
            dataPage={
              pagination.data_count <= 10
                ? data?.length
                : data?.map((_res, index) => {
                    if (index === data.length - 1) {
                      return PageNumber(
                        pagination.page,
                        pagination.per_page,
                        index
                      );
                    } else {
                      return null;
                    }
                  })
            }
            dataNumber={data?.map((_res, index) => {
              if (index === 0) {
                return PageNumber(pagination.page, pagination.per_page, index);
              } else {
                return null;
              }
            })}
            dataCount={pagination.data_count}
            onDataLengthChange={(e) => {
              setPagination((prev) => ({
                ...prev,
                per_page: e.target.value,
                page: 1,
              }));
            }}
            currentPage={pagination.page}
            totalPage={pagination.total_page}
            onPaginationChange={({ selected }) =>
              setPagination((prev) => ({
                ...prev,
                page: selected + 1,
              }))
            }
          />
        )}

        {modalFilter && (
          <ModalFilterPostingRealisasiWorkOrder
            toggle={toggleModalFilter}
            data={dataFilter}
            setData={setDataFilter}
            buasoTitle={buasoTitle}
            buaso={buaso}
          />
        )}
      </div>
    );
  }
);
